import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Typography } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import ExplorerInfoCompaniesItem from '../ExplorerInfoCompaniesItem'

const propTypes = {
  syncsData: PropTypes.array.isRequired,
  nextCompanies: PropTypes.array.isRequired,
  previousCompanies: PropTypes.array.isRequired
}

const Wrapper = styled.div`
  margin-top: 40px;
`
const HeaderHolder = styled.div`
  display: flex;
  align-items: center;
`
const Header = styled(Typography)`
  font-size: 28px;
  margin-bottom: 10px;
`
const IconDown = styled(ArrowDropDown)`
  color: red;
`
const IconUp = styled(ArrowDropUp)`
  color: green;
`
const Animation = styled(motion.div)``

const ExplorerInfoCompanies = ({ previousCompanies, syncsData, nextCompanies }) => {
  return (
    <Wrapper>
      {nextCompanies && nextCompanies.length > 0 && (
        <Animation
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, type: 'spring' }}
        >
          <HeaderHolder>
            <Header> Компании выше по рейтингу</Header>
            <IconUp fontSize='large' />
          </HeaderHolder>
          {nextCompanies.map(nextCompany =>
            <ExplorerInfoCompaniesItem
              company={nextCompany}
              syncsData={syncsData}
              key={nextCompany?.id}
            />
          )}
        </Animation>
      )}
      {previousCompanies && previousCompanies.length > 0 && (
        <Animation
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, type: 'spring' }}
        >
          <HeaderHolder>
            <Header> Компании ниже по рейтингу</Header>
            <IconDown fontSize='large' />
          </HeaderHolder>
          {previousCompanies.map(prevCompany =>
            <ExplorerInfoCompaniesItem
              company={prevCompany}
              syncsData={syncsData}
              key={prevCompany?.id}
            />
          )}
        </Animation>
      )}
    </Wrapper>
  )
}

ExplorerInfoCompanies.propTypes = propTypes

export default React.memo(ExplorerInfoCompanies)
