import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IconButton, TextField, Tooltip } from '@material-ui/core'
import { Functions } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { useDebounce } from 'use-debounce'
import useSWR from 'swr'
import { motion } from 'framer-motion'
import { withRouter } from 'react-router'

import axios, { useAuthSWR } from '../../helpers/axios'
import ExplorerMainInfo from '../../components/Explorer/explorer/ExplorerMainInfo'
import Preloader from '../../components/Preloader'
import SearchResult from '../../components/SearchResult'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import ExplorerInfoCompanies from '../../components/Explorer/explorer/EplorerInfoCompanies'
import XRating from '../../components/Explorer/explorer/XRating'
import Popup from '../../components/Popup'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`
const MainInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const SearchWrapper = styled.div`
  width: 50%;
  margin: 2rem auto;
`
const PreloaderWrapper = styled.div`
  position: relative;
  margin-top: 100px;
`
const Animation = styled(motion.div)``

async function getSearchResults (query, searchTicker) {
  if (!query) return []
  try {
    const { data: company } =
      isNaN(Number(query))
        ? await axios.get(`/api/screener/companies/search?query=${query}&populate=industry country`)
        : await axios.get(`api/companies?rating=${query}&populate=industry country`)
    if (searchTicker.length) {
      const { data } = await axios.get(`api/companies?ticker=${searchTicker}&populate=industry country`)
      return data.length ? data : null
    } else {
      return company.length ? company : null
    }
  } catch (e) {
    return []
  }
}

async function recalculateXFormula (id, formula, currentCompany = null) {
  const { data } = await axios.post('/api/screener/companies/custom-calculation', {
    companyId: id,
    xRatingFormula: formula,
    ...(currentCompany && {
      companyFields: Object.fromEntries(Object.entries(currentCompany).filter(([key]) => companyFields.includes(key)))
    })
  })
  return data
}

async function getAndSetCompany (companiesList) {
  if (Array.isArray(companiesList)) {
    try {
      const anotherCompanies = await Promise.allSettled(companiesList.map(company => getAndSetCompany(company)))
      return anotherCompanies.filter(Boolean).map(company => company?.value)
    } catch (e) {
      return ''
    }
  } else {
    try {
      const { data: currentCompanyData } = await axios.get(`api/screener/companies?_id=${companiesList?.id}`)
      return currentCompanyData[0]
    } catch (e) {
      return ''
    }
  }
}

async function getAndSetAnotherCompany (type, currentCompanyTicker) {
  try {
    const { data: company } = await axios.get(`/api/companies/cms/rating-neighbors?ticker=${currentCompanyTicker}&position=${type}&populate=industry country`)
    const currentCompanyData = await getAndSetCompany(company)
    return currentCompanyData
  } catch (e) {
    return ''
  }
}

const companyFields = [
  'marketCap',
  'forwardPE',
  'priceToBookRatio',
  'priceToSalesRatio',
  'priceToFreeCashFlowRatio',
  'dividendYield',
  'enterpriseValueEbitda',
  'debtToEquityRatio',
  'fiveYearTotalRevenue',
  'estimateEarningsCurrentYear'
]

const Explorer = ({ history }) => {
  const [currentCompany, setCurrentCompany] = useState()
  const [xFormula, setXFormula] = useState()
  const [searchValue, setSearchValue] = useState(new URLSearchParams(window.location.search).get('query') || '')
  const [searchTicker, setSearchTicker] = useState('')
  const [xFormulaModal, setXFormulaModal] = useState(false)
  const [debouncedSearch] = useDebounce(searchValue, 500)

  const { data: suggestions } = useSWR(
    debouncedSearch !== undefined && searchValue === debouncedSearch
      ? `/companies/get?search=${searchTicker}-${debouncedSearch}`
      : null, () => getSearchResults(debouncedSearch, searchTicker), { revalidateOnFocus: false, revalidateOnReconnect: false })

  const [debouncedCurrentTicker] = useDebounce(searchTicker, 500)

  const { data: previousCompanies = [] } = useSWR(debouncedCurrentTicker ? '/previousCompanies' : null, () => getAndSetAnotherCompany('below', searchTicker))
  const { data: nextCompanies = [] } = useSWR(debouncedCurrentTicker ? '/nextCompanies' : null, () => getAndSetAnotherCompany('above', searchTicker))
  const { data: syncsData = [] } = useAuthSWR(debouncedCurrentTicker ? `/api/stock-sync/cms/company?ticker=${searchTicker}&sort=-syncStartDate` : null)

  async function getAndSetCurrentCompany (company) {
    setSearchTicker(company?.ticker)
    const currentCompanyData = await getAndSetCompany(company)
    setCurrentCompany(currentCompanyData)
  }

  useEffect(() => {
    history.replace(
      {
        pathname: '/explorer/explorer',
        search: `?query=${searchValue}`
      }
    )
    if (searchValue.length <= 4) setSearchTicker('')
  }, [searchValue])

  useEffect(() => {
    axios.get('/api/screener').then(response => setXFormula(response.data[0].xRatingFormula))
  }, [])

  return (
    <>
      <BreadcrumbsComponent />
      <PageHeader>
        <PageTitle> Explorer </PageTitle>
      </PageHeader>
      <MainInput>
        <SearchWrapper>
          <Autocomplete
            id='search-explorer'
            freeSolo
            inputValue={searchValue}
            onChange={(event, value, reason) => {
              reason !== 'clear' ? getAndSetCurrentCompany(value) : setCurrentCompany(null)
            }}
            onInputChange={(event, newInputValue, reason) => {
              reason !== 'clear' ? setSearchValue(newInputValue) : setSearchValue('')
            }}
            filterOptions={x => x}
            options={suggestions || []}
            clearText='Очистить'
            getOptionLabel={(option) => option?.name}
            renderOption={(option) => <SearchResult key={option.name} img={option.logoUrl} name={option.name} ticker={option.ticker} />}
            renderInput={(params) =>
              <TextField
                {...params}
                placeholder='Введите наименование/тикер компании или ее рейтинг'
                type='search'
                variant='outlined'
                fullWidth
              />}
          />
        </SearchWrapper>
        {currentCompany && xFormula && searchValue === debouncedSearch && (
          <Animation
            initial={{ opacity: 0, scale: 0.3 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, type: 'spring' }}
          >
            <Tooltip title='X-рейтинг компании'>
              <IconButton
                disabled={searchValue !== debouncedSearch}
                style={{ color: '#3F51B5' }}
                size='small'
                onClick={() => setXFormulaModal(true)}
              >
                <Functions fontSize='large' />
              </IconButton>
            </Tooltip>
          </Animation>
        )}
        <Popup title='X-рейтинг' openPopup={xFormulaModal} setOpenPopup={setXFormulaModal} isFullScreen>
          <XRating
            xFormula={xFormula}
            companyId={currentCompany?.id}
            setXFormula={setXFormula}
            recalculateXFormula={recalculateXFormula}
          />
        </Popup>
      </MainInput>
      {!suggestions && <PreloaderWrapper> <Preloader /> </PreloaderWrapper>}
      {suggestions && currentCompany && syncsData && (
        <>
          <Animation
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, type: 'spring' }}
          >
            <ExplorerMainInfo
              currentCompany={currentCompany}
              onCurrentCompanyChange={setCurrentCompany}
              syncsData={syncsData}
              xFormula={xFormula}
              recalculateXFormula={recalculateXFormula}
            />
          </Animation>
          <ExplorerInfoCompanies
            previousCompanies={previousCompanies}
            syncsData={syncsData}
            nextCompanies={nextCompanies}
            recalculateXFormula={recalculateXFormula}
          />
        </>
      )}
    </>
  )
}

export default withRouter(Explorer)
