import React from 'react'
import styled from 'styled-components'
import { Button, Typography } from '@material-ui/core'

const SortWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`
const SortItem = styled(Button)`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px 18px 10px 15px;
  color: #757575;
  text-transform: none;
  border-radius: 20px;
`
const SorterText = styled(Typography)`
  margin-right: 5px;
`
const Icon = styled.img` 
  height: 12px;  
`

const SortButton = ({ sorter, sorterHandler }) => (
  <SortWrap>
    <SortItem variant='outlined' onClick={() => sorterHandler()}>
      <SorterText> По количеству компаний </SorterText>
      <Icon src={`/icons/${sorter !== ''
        ? sorter
        : 'filterArrowsIcon'}.svg`}
      />
    </SortItem>
  </SortWrap>
)

export default SortButton
