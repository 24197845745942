import axios from 'axios'
import useSWR from 'swr'

const { REACT_APP_API_ENDPOINT } = process.env

const authAxios = axios.create({
  baseURL: REACT_APP_API_ENDPOINT
})

authAxios.interceptors.request.use(config => {
  const token = window.localStorage.getItem('token')

  if (token) {
    const newConfig = Object.assign({}, config)
    newConfig.headers.Authorization = `Bearer ${token}`
    return newConfig
  } else {
    return config
  }
})

authAxios.interceptors.response.use((response) => response, async (error) => {
  const errorStatus = error?.response?.status ?? null

  if (errorStatus === 401) {
    try {
      const refreshToken = window.localStorage.getItem('refreshToken')
      const { data } = await axios.post(`${REACT_APP_API_ENDPOINT}/api/auth/refresh`, {
        refreshToken
      })

      const { token, refreshToken: receivedRefreshToken } = data

      window.localStorage.setItem('token', token)
      window.localStorage.setItem('refreshToken', receivedRefreshToken)
      return null
    } catch (e) {
      console.error(e)
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('refreshToken')
      window.location.reload()
    }
  } else {
    return Promise.reject(error)
  }
})

export default authAxios

const fetcher = (url, options = {}) =>
  authAxios
    .get(url, options)
    .then(res => res.data)

export const useAuthSWR = (url, config) => useSWR(url, fetcher, config)
