import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router'
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, Collapse, Box, Avatar, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import {
  EventNote,
  ShowChart,
  Storage,
  Functions,
  AddToQueue,
  Sort,
  AssignmentInd,
  Grain,
  ExitToApp,
  FormatIndentIncrease,
  FormatAlignJustify,
  Translate,
  CreateOutlined,
  WbIncandescent,
  Subject,
  PlaylistAdd,
  Business,
  Group,
  GroupAdd,
  SupervisorAccount,
  Explore,
  BarChart,
  Equalizer,
  Sync,
  MonetizationOn,
  Add,
  ClearAll
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import NavigationLink from './Link'
import useUserRole from '../../hooks/useUserRole'

const Icons = {
  EventNote: <EventNote />,
  FormatIndentIncrease: <FormatIndentIncrease />,
  FormatAlignJustify: <FormatAlignJustify />,
  ShowChart: <ShowChart />,
  Storage: <Storage />,
  Functions: <Functions />,
  AddToQueue: <AddToQueue />,
  Sort: <Sort />,
  AssignmentInd: <AssignmentInd />,
  Grain: <Grain />,
  Translate: <Translate />,
  CreateOutlined: <CreateOutlined />,
  WbIncandescent: <WbIncandescent />,
  Subject: <Subject />,
  PlaylistAdd: <PlaylistAdd />,
  Business: <Business />,
  Group: <Group />,
  GroupAdd: <GroupAdd />,
  SupervisorAccount: <SupervisorAccount />,
  Explore: <Explore />,
  BarChart: <BarChart />,
  Equalizer: <Equalizer />,
  Sync: <Sync />,
  MonetizationOn: <MonetizationOn />,
  Add: <Add />,
  ClearAll: <ClearAll />
}

const TypographyBody2Styled = withStyles({
  body2: {
    width: '400px',
    textAlign: 'right'
  }
})((props) => (
  <Typography
    variant='body2'
    noWrap
    {...props}
  />
))

const TypographyCaptionStyled = withStyles({
  caption: {
    width: '400px',
    textAlign: 'right',
    color: '#bdbdbd'
  }
})((props) => (
  <Typography
    variant='caption'
    noWrap
    {...props}
  />
))

const MenuStyled = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))

const MenuItemStyled = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

const StyledList = styled(List)`
  padding-left: 20px;
`

const AvatarStyled = styled(Avatar)`
  width: 30px;
  height: 30px;
`

const ActiveMenuItemContainer = styled.div`
  ${props => props.activeBackground && 'background-color: #CACACAFF'};
  ${props => props.isContainer && 'background-color: #e4e4e4'};
  border-radius: 5px;
`

const ActiveMenuItemBackground = ({ isActive, isContainer, children }) => {
  return (
    <ActiveMenuItemContainer isContainer={isContainer && isActive} activeBackground={isActive}>
      {children}
    </ActiveMenuItemContainer>
  )
}

const Navigation = () => {
  const [menuIsOpen, setIsOpen] = useState()
  const [anchorEl, setAnchorEl] = useState(false)
  const [openedRoutes, setOpenedRoutes] = useState({})
  const { routesPermission, userRole } = useUserRole()
  const { pathname } = useLocation()

  const toggleMenu = () => {
    setIsOpen(!menuIsOpen)
  }

  const toggleProfileMenu = (event) => {
    if (anchorEl) {
      setAnchorEl(false)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const createRoutes = (route) => {
    const { routes, visibility } = route
    const { name } = route

    const isActiveMenuItem = pathname.includes(route?.href || route?.subject)

    if (routes && visibility) {
      const handleOpenSubMenu = () => {
        const newOpenedRoutes = { ...openedRoutes }
        const isSubMenuOpened = openedRoutes[name]

        newOpenedRoutes[name] = !isSubMenuOpened
        setOpenedRoutes(newOpenedRoutes)
      }

      const isSubMenuOpened = openedRoutes[name]
      return (
        <React.Fragment key={`collapse${name}`}>
          <ActiveMenuItemBackground isContainer isActive={isActiveMenuItem}>
            <NavigationLink
              key={name}
              name={name}
              icon={Icons[route.icon]}
              open={isSubMenuOpened}
              onClick={handleOpenSubMenu}
            />
            <Collapse in={isSubMenuOpened}>
              <StyledList component='div' disablePadding>
                {routes.map((nestedRoute) => createRoutes(nestedRoute))}
              </StyledList>
            </Collapse>
          </ActiveMenuItemBackground>
        </React.Fragment>
      )
    }

    if (visibility && !routes) {
      return (
        <ActiveMenuItemBackground isActive={isActiveMenuItem}>
          <NavigationLink
            key={name}
            href={route.href}
            name={route.name}
            icon={Icons[route.icon]}
            onClick={toggleMenu}
          />
        </ActiveMenuItemBackground>
      )
    }
  }

  const LogOut = async () => {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('refreshToken')
    window.location.reload()
  }

  return (
    <AppBar
      position='static'
      style={{ marginBottom: '20px' }}
    >
      <Toolbar variant='dense'>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='menu'
          style={{ marginRight: '10px' }}
          onClick={toggleMenu}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant='h6' color='inherit' style={{ flex: '1 1 100%' }}>
          Инвестиционный сервис
        </Typography>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          alignItems='center'
          flex='0 0 400px'
          style={{ cursor: 'pointer' }}
          onClick={toggleProfileMenu}
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-end'
            marginRight='10px'
          >
            <TypographyBody2Styled>
              {userRole?.fullName}
            </TypographyBody2Styled>
            <TypographyCaptionStyled>
              {userRole?.description}
            </TypographyCaptionStyled>
          </Box>
          <AvatarStyled>P</AvatarStyled>
          <MenuStyled
            anchorEl={anchorEl}
            open={!!anchorEl}
          >
            <MenuItemStyled onClick={LogOut}>
              <ListItemIcon>
                <ExitToApp fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Выйти' />
            </MenuItemStyled>
          </MenuStyled>
        </Box>
        <Drawer open={menuIsOpen} onClose={toggleMenu}>
          <List component='nav' style={{ width: '300px', padding: '20px' }}>
            {routesPermission.length && routesPermission.map(route => createRoutes(route))}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  )
}

export default Navigation
