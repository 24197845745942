import React, { useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'

import canAccess from '../../helpers/canAccess'
import PageTitle from '../../components/PageTitle'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import { Box, Button, Link, Table, TableBody, TableContainer, TableHead } from '@material-ui/core'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import axios from '../../helpers/axios'
import { TariffsListElement } from '../../components/Tariffs/TariffsListElement'
import { TariffsTableHead } from '../../components/Tariffs/TariffsTableHead'
import ConfirmDialog from '../../components/ConfirmDialog'
import useMessage from '../../hooks/useMessage'

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
`

const Tariffs = () => {
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false })
  const history = useHistory()
  const showMessage = useMessage()

  const canCreate = canAccess('tariff', 'create')
  const canUpdate = canAccess('tariff', 'update')
  const canDelete = canAccess('tariff', 'delete')

  const { data: tariffsList = [], mutate } = useSWR('/api/tariffs', () => axios.get('/api/tariffs').then(list => list.data))

  function onEdit (id) {
    history.push(`/tariffs/${id}`)
  }

  async function deleteTariff (id) {
    try {
      await axios.delete(`/api/tariffs/${id}`)
      const newTariffsList = tariffsList.filter(tariff => tariff?.id !== id)
      mutate(newTariffsList)
      setConfirmDialog({ isOpen: false })
      showMessage('Тариф успешно удален', 'success')
    } catch (e) {
      console.error(e)
      showMessage(`Ошибка: ${e.response.data?.message}`)
    }
  }

  return (
    <>
      <BreadcrumbsComponent />
      <PageHeader>
        <Box display='flex' justifyContent='space-between'>
          <PageTitle>Тарифы</PageTitle>
          {canCreate && (
            <Box>
              <Link component={RouterLink} to='tariffs/add'>
                <Button color='primary' variant='contained'>
                  Создать новый
                </Button>
              </Link>
            </Box>
          )}
        </Box>
        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TariffsTableHead />
            </TableHead>
            <TableBody>
              {tariffsList.map(tariff =>
                <TariffsListElement
                  tariffData={tariff}
                  canEdit={canUpdate}
                  canDelete={canDelete}
                  onEdit={() => onEdit(tariff?.id)}
                  onDelete={() => deleteTariff(tariff?.id)}
                  setConfirmDialog={setConfirmDialog}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PageHeader>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  )
}

export default Tariffs
