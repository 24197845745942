import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { CloudBlue } from '../../../constants/colors'
import Input from '../Input'

const propTypes = {
  caption: PropTypes.shape({
    value: PropTypes.string,
    fontWeight: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    fontSize: PropTypes.string,
    lineHeight: PropTypes.string,
    color: PropTypes.string
  }),
  text: PropTypes.shape({
    value: PropTypes.string,
    fontWeight: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    fontSize: PropTypes.string,
    lineHeight: PropTypes.string,
    color: PropTypes.string
  }),
  margin: PropTypes.string,
  width: PropTypes.string
}

const defaultProps = {
  caption: {
    value: null,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    color: CloudBlue
  },
  text: {
    value: null,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: CloudBlue
  },
  margin: '5px',
  width: '123px'
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
`

const Top = styled.div`
  margin-bottom: ${props => props.margin};
`

const CaptionValue = ({ caption, text, width, margin }) => (
  <Comp width={width}>
    <Top margin={margin}>
      <Input
        width={width}
        fontWeight={caption.fontWeight}
        fontSize={caption.fontSize}
        lineHeight={caption.lineHeight}
        color={caption.color}
        defaultValue={caption.value}
      />
    </Top>
    <Input
      width={width}
      fontWeight={text.fontWeight}
      fontSize={text.fontSize}
      lineHeight={text.lineHeight}
      color={text.color}
      defaultValue={text.value}
      placeholder='Текст'
    />
  </Comp>
)

CaptionValue.propTypes = propTypes
CaptionValue.defaultProps = defaultProps

export default CaptionValue
