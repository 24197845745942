import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import useSWR from 'swr'
import { useDebounce } from 'use-debounce'
import { Box, Button, CircularProgress, Link, makeStyles, TextField } from '@material-ui/core'
import { Alert, Autocomplete, Pagination } from '@material-ui/lab'

import axios from '../../helpers/axios'
import canAccess from '../../helpers/canAccess'
import useMessage from '../../hooks/useMessage'

import PageTitle from '../../components/PageTitle'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import Preloader from '../../components/Preloader'
import UsersTable from '../../components/Users/UsersTable'
import ConfirmDialog from '../../components/ConfirmDialog'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`
const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`

const useStyles = makeStyles(theme => ({
  preloader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-20px 0 0 -20px'
  },
  buttonWrapper: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  limitedWidthLg: {
    width: '590px'
  }
}))

async function getSearchResults (query, setIsFetching) {
  if (!query) return []
  setIsFetching(true)

  try {
    const { data: users = [] } = await axios.get(`/api/users/cms/search?query=${query}`)
    setIsFetching(false)
    return users.length >= 1 ? users : null
  } catch (e) {
    setIsFetching(false)
    return []
  }
}

function resultPage (page) {
  if (page === 1) {
    return ''
  } else {
    return ((page + '0') - 10)
  }
}

const Users = () => {
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch] = useDebounce(searchValue, 500)
  const [isFetching, setIsFetching] = useState(false)
  const [cellForSort, setCellForSort] = useState({ name: '', direction: 'lowToHigh' })

  const [users, setUsers] = useState([])
  const [usersCount, setUsersCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const paginationCount = Math.ceil(usersCount / 10)

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false })

  const showMessage = useMessage()
  const history = useHistory()
  const classes = useStyles()
  const createUser = canAccess('user', 'registerCmsUser')

  const { data: suggestions } = useSWR(() =>
    debouncedSearch !== undefined && searchValue === debouncedSearch
      ? ['/users', debouncedSearch]
      : null, () => getSearchResults(debouncedSearch, setIsFetching))

  useEffect(async () => {
    try {
      const { data: users = [] } = await axios.get('/api/users/cms?limit=10')
      const { data: count = 0 } = await axios.get('/api/users/cms')
      setUsers(users)
      setUsersCount(count.length)
    } catch (error) {
      showMessage('Ошибка загрузки пользователей')
    }
  }, [debouncedSearch.length === 0])

  useEffect(() => {
    if (suggestions?.length) {
      setUsers(suggestions)
      setUsersCount(suggestions.length)
    } else {
      setUsers(users)
    }
  }, [suggestions?.length])

  async function onEdit (id) {
    history.push(`/users/${id}`)
  }

  async function onDelete (id) {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })
    try {
      showMessage('Пользователь успешно удален', 'success')
    } catch (e) {
      console.error(e)
      showMessage('Ошибка удаления пользователя')
    }
  }

  async function onSortByCell (cellName, direction) {
    const { data } = await axios.get(
      `/api/users/cms?skip=${resultPage(currentPage)}&sort=${direction === 'lowToHigh' ? '' : '-'}${cellName}&limit=10`)
    setCellForSort({ name: cellName, direction: direction })
    setUsers(data)
  }

  async function changePage (page) {
    function cellNameIfSortActive () {
      if (cellForSort.name.length) {
        return `&sort=${cellForSort.direction === 'lowToHigh' ? '' : '-'}${cellForSort.name}`
      } else {
        return ''
      }
    }

    try {
      const { data: newUsersList } = await axios.get(`/api/users/cms?${page !== 1 ? `skip=${resultPage(page)}` : ''}${cellNameIfSortActive()}&limit=10`)
      setUsers(newUsersList)
      setCurrentPage(page)
    } catch (e) {
      console.error(e)
      showMessage('Ошибка отправки данных')
    }
  }

  return (
    <>
      <BreadcrumbsComponent />
      <PageHeader>
        <PageTitle>Все пользователи</PageTitle>
        <Box style={{ position: 'relative' }}>
          {isFetching ? <CircularProgress size={30} className={classes.preloader} /> : null}
          <Autocomplete
            id='combo-box'
            freeSolo
            inputValue={searchValue}
            onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
            options={suggestions || []}
            getOptionLabel={(option) => option?.displayName.toString()}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label='Поиск пользователя' variant='outlined' />}
          />
        </Box>
      </PageHeader>
      <Box>
        {users === undefined ? <Box position='relative' paddingTop='40px'><Preloader /></Box> : null}
        {createUser && (
          <Box className={classes.buttonWrapper}>
            <Link component={RouterLink} to='users/add'>
              <Button color='primary' variant='contained'>
                Создать
              </Button>
            </Link>
          </Box>
        )}
        {suggestions === null
          ? <Alert severity='warning' className={classes.limitedWidthLg}>По заданным критериям ничего не найдено</Alert>
          : (
            <>
              <Box marginTop='10px'>
                <UsersTable
                  users={users}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  setConfirmDialog={setConfirmDialog}
                  onSort={(cellName, direction) => onSortByCell(cellName, direction)}
                />
                {createUser && (
                  <Box className={classes.buttonWrapper}>
                    <Link component={RouterLink} to='users/add'>
                      <Button color='primary' variant='contained'>
                        Создать
                      </Button>
                    </Link>
                  </Box>
                )}
                {usersCount > 10
                  ? <PaginationWrap>
                      <Pagination
                        count={paginationCount}
                        page={currentPage}
                        onChange={(e, value) => changePage(value)}
                      />
                    </PaginationWrap>
                  : ''}
              </Box>
            </>)}
      </Box>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  )
}

export default Users
