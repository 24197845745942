import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Fade, Modal, Typography } from '@material-ui/core'
import ReactDOM from 'react-dom'

import axios from '../../helpers/axios'
import { CompanyListElement } from './ListElement'
import { ListOfCompanies } from './ListOfCompanies'
import { EditCompanyBlock } from './EditCompanyBlock'
import { AddCountryButton } from './AddCountryButtonBlock'
import useMessage from '../../hooks/useMessage'

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 6,
  minWidth: 1000,
  minHeight: 180,
  overflowY: 'scroll',
  maxHeight: 650
}

const CompanyRow = ({ companies, onHandleClick, onEdit, onDelete }) => {
  const RenderAddButton = () =>
    <AddCountryButton customText='Создать компанию' onHandleClick={() => onHandleClick({})} margin='20px 0 0 0' onCenter />

  if (companies.length) {
    return (
      <div>
        {companies.map(company =>
          <CompanyListElement
            providerExchangeCode={company?.providerExchangeCode}
            exchangeCountry={company?.exchangeCountry}
            ticker={company?.ticker}
            countries={company?.countries}
            onEdit={() => onEdit(company)}
            onDelete={() => onDelete(company)}
          />)}
        <RenderAddButton />
      </div>
    )
  }

  return (
    <>
      <Typography style={{ textAlign: 'center', margin: '10px 0 0 0' }}>Список пуст</Typography>
      <RenderAddButton />
    </>
  )
}

export const ModalForCompanyException = ({ isOpen, closeModal }) => {
  const [currentCompany, setCurrentCompany] = useState(false)
  const [allExchangeCompanies, setAllExchangeCompanies] = useState([])

  const showMessage = useMessage()

  useEffect(() => {
    axios.get('/api/company-exception?populate=exchangeCountry,countries').then(res => setAllExchangeCompanies(res.data))
  }, [])

  async function deleteCompany (companyForDelete) {
    try {
      await axios.delete(`/api/company-exception/${companyForDelete.id}`)
      setAllExchangeCompanies(allExchangeCompanies.filter(company => company.id !== companyForDelete.id))
      showMessage('Компания успешно удалена', 'success')
    } catch (e) {
      console.error(e)
      showMessage(`Ошибка: ${e.response.data?.message}`)
    }
  }
  async function updateCompany (newCompanyData, isNewCompany) {
    const createNewCompanyObject = {
      ...newCompanyData,
      countries: newCompanyData.countries.map(item => item?.id ?? item?._id),
      exchangeCountry: newCompanyData.exchangeCountry?.id
    }
    try {
      if (isNewCompany) {
        await axios.post('/api/company-exception', createNewCompanyObject)
      } else {
        await axios.put(`/api/company-exception/${currentCompany?.id}`, createNewCompanyObject)
      }
      const deletedPrevious = allExchangeCompanies.filter(company => company.id !== newCompanyData.id)
      const updatedList = [...deletedPrevious, newCompanyData]
      ReactDOM.unstable_batchedUpdates(() => {
        setAllExchangeCompanies(updatedList)
        setCurrentCompany(false)
        showMessage(isNewCompany ? 'Компания успешно создана' : 'Компания успешно обновлена', 'success')
      })
    } catch (e) {
      console.error(e)
      showMessage(`Ошибка: ${e.response.data?.message}`)
    }
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={isOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <Box sx={styles}>
          {currentCompany
            ? <EditCompanyBlock
                onSave={(company, isNewCompany) => updateCompany(company, isNewCompany)}
                onCancel={() => setCurrentCompany(false)}
                id={currentCompany?.id}
                providerExchangeCode={currentCompany?.providerExchangeCode}
                exchangeCountry={currentCompany?.exchangeCountry}
                ticker={currentCompany?.ticker}
                countries={currentCompany?.countries}
              />
            : <ListOfCompanies>
              <CompanyRow
                companies={allExchangeCompanies}
                onHandleClick={() => setCurrentCompany({})}
                onEdit={company => setCurrentCompany(company)}
                onDelete={company => deleteCompany(company)}
              />
              </ListOfCompanies>}
        </Box>
      </Fade>
    </Modal>
  )
}
