import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Authorized from './routes/authorized'
import NotAuthorized from './routes/not-authorized'
import Preloader from './components/Preloader'
import 'fontsource-roboto'

import './assets/style/normalize.css'
import './assets/style/reset.css'
import './assets/style/fonts.css'
import './assets/style/forecast.css'
import useUserRole from './hooks/useUserRole'

function App () {
  const { data, userRole, routesPermission, error, token } = useUserRole()

  return (
    <BrowserRouter>
      {token && routesPermission.length === 0 ? <Preloader /> : null}
      {(!token && !data && !error) || (token && error) || (token && data && userRole === null && routesPermission.length === 0 && !error) ? <NotAuthorized /> : null}
      {routesPermission.length ? <Authorized /> : null}
    </BrowserRouter>
  )
}

export default App
