import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Input from '@material-ui/core/Input'
import { Button, CircularProgress } from '@material-ui/core'

import { CloudBlue } from '../../constants/colors'
import { useFormik } from 'formik'
import axios from '../../helpers/axios'
import useMessage from '../../hooks/useMessage'

const MainWrapper = styled.section`
  display: flex;
  align-items: center;
`
const FormWrap = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`
const InputsBlockWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 10px 15px 10px;
  width: 80%;
  border-bottom: 1px solid lightgray;
  margin-bottom: 20px;
  min-height: 50px;
`
const InputsSection = styled.div`
  width: 50%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const IndustryName = styled.span`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
`
const SaveButtonWrap = styled(motion.div)`
  min-width: 150px;
  display: ${props => props.isShow ? 'block' : 'none'};
  color: ${CloudBlue};
  align-self: flex-start;
`

export const IndustriesListItem = (props) => {
  const [isButtonShow, setIsButtonShow] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const showMessage = useMessage()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...props,
      nameTranslation: props.nameTranslation || ''
    },
    validate (values) {
      const errors = {}

      if (!values.nameTranslation) {
        errors.nameTranslation = 'Обязательное поле'
      }
      return errors
    },

    async onSubmit (values, { setSubmitting }) {
      setSubmitting(true)
      try {
        setIsFetching(true)
        const { data } = await axios.put(`/api/industries/${props.id}`, values)
        if (data) {
          setTimeout(() => {
            setIsFetching(false)
            showMessage('Перевод отрасли успешно обновлен', 'success')
          }, 500)
        }
      } catch (error) {
        console.error(error)
        showMessage('Ошибка обновления отрасли')
      }
    }
  })

  return (
    <MainWrapper
      onMouseOver={() => setIsButtonShow(true)}
      onMouseLeave={() => setIsButtonShow(false)}
    >
      <FormWrap onSubmit={formik.handleSubmit}>
        <InputsBlockWrapper>
          <InputsSection>
            <IndustryName>{props.name}</IndustryName>
          </InputsSection>
          <InputsSection>
            <Input
              value={formik.values.nameTranslation}
              onChange={e => formik.setFieldValue('nameTranslation', e.target.value)}
              placeholder='Введите перевод названия отрасли'
            />
          </InputsSection>
        </InputsBlockWrapper>
        <SaveButtonWrap
          initial='hidden'
          animate={isButtonShow ? 'visible' : 'hidden'}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -40 }
          }}
          transition={{ ease: 'easeIn', duration: 0.2 }}
          isShow={isButtonShow}
        >
          <Button type='submit' fullWidth variant='contained' color='primary'>
            {isFetching ? <CircularProgress size='25px' color='inherit' /> : 'Сохранить'}
          </Button>
        </SaveButtonWrap>
      </FormWrap>
    </MainWrapper>
  )
}
