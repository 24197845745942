import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Login from '../pages/login'

const NotAuthorized = () => (
  <>
    <Route path='/' component={Login} />
    <Redirect from='*' to='/' />
  </>
)

export default NotAuthorized
