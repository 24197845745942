import React from 'react'
import { Grid, Input, Typography } from '@material-ui/core'
import styled from 'styled-components'
import produce from 'immer'
import NumberFormat from 'react-number-format'

const TableWrap = styled(Grid)`
   width: 100%;
   padding: 5px;
`
const TableItem = styled(Grid)`
  padding: 10px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`
const ItalicSpan = styled.span`
  font-style: italic;
`

const companyFields = [
  'marketCap',
  'forwardPE',
  'priceToBookRatio',
  'priceToSalesRatio',
  'priceToFreeCashFlowRatio',
  'dividendYield',
  'enterpriseValueEbitda',
  'debtToEquityRatio',
  'fiveYearTotalRevenue',
  'estimateEarningsCurrentYear'
]

const ExplorerCompanyDataTable = ({ company, onCompanyChange }) => {

  function filterCompanyFields (value, key) {
    if ((typeof value == 'string' || typeof value == 'number')) {
      return value
    } else if (value instanceof Object) {
      switch (key) {
        case 'technicals':
          return ''
        case 'country':
          return value?.name
        case 'industry':
          return `${value?.name} / ${value?.nameTranslation}`
        case 'analystRatings':
          return (
            <div>
              <div><ItalicSpan>Buy: </ItalicSpan>{value?.buy}</div>
              <div><ItalicSpan>Sell: </ItalicSpan>{value?.sell}</div>
              <div><ItalicSpan>Hold: </ItalicSpan>{value?.hold}</div>
              <div><ItalicSpan>Strong by: </ItalicSpan>{value?.strongBy}</div>
              <div><ItalicSpan>Target price: </ItalicSpan>{value?.targetPrice}</div>
            </div>
          )
        default:
          return ''
      }
    }
  }

  return (
    <>
      <TableWrap container>
        <TableItem item xs={4}>
          <Typography>Параметр</Typography>
        </TableItem>
        <TableItem item xs={4}>
          <Typography>Значение компании</Typography>
        </TableItem>
        <TableItem item xs={4}>
          <Typography>Значение отрасли</Typography>
        </TableItem>
        {company && Object.entries(company)
          .map(([key, value]) =>
            <React.Fragment key={key}>
              <TableItem item xs={4}>
                <Typography> {key} </Typography>
              </TableItem>
              <TableItem item xs={4}>
                {companyFields.includes(key) && onCompanyChange
                  ? <NumberFormat
                      type='text'
                      customInput={Input}
                      value={value}
                      onChange={e => onCompanyChange(produce(company, draft => {
                        draft[key] = e.target.value
                      }))}
                    />
                  : <Typography>{filterCompanyFields(value, key)}</Typography>}
              </TableItem>
              <TableItem item xs={4}>
                {companyFields.includes(key) && (company.industry?.[key]?.name || company.industry?.[key])
                  ? <Typography>{company.industry?.[key]?.name || company.industry?.[key]}</Typography>
                  : null}
              </TableItem>
            </React.Fragment>
          )}
      </TableWrap>
    </>
  )
}

export default React.memo(ExplorerCompanyDataTable)
