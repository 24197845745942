import React, { useState } from 'react'
import { TableContainer } from '@material-ui/core'

import { useAuthSWR } from '../../../../helpers/axios'
import { JSONViewModal } from '../JSONViewModal'
import { SyncDataTableRow } from './SyncDataTableRow'
import { SyncDataTableContainer } from './SyncDataTableContainer'

const ExplorerSyncDataTable = ({ syncsData, ticker }) => {
  const [openState, setOpenState] = useState({ open: false, syncId: '' })
  const [modalDialog, setModalDialog] = useState(false)

  const { data } = useAuthSWR(openState.open ? `/api/stock-sync/cms/logs/single/${openState.syncId}?ticker=${ticker}&fields=rawCompanyData,extendedCompanyData` : null)

  function openDataPreview (id) {
    setOpenState({ open: openState.syncId === id ? !openState.open : true, syncId: id })
    setModalDialog(true)
  }

  return (
    <>
      <TableContainer>
        <SyncDataTableContainer>
          {syncsData.map(item => {
            return (
              <React.Fragment key={item?.id}>
                <SyncDataTableRow data={item} onHandleClick={() => openDataPreview(item?.id)} hoverable />
                <JSONViewModal
                  isOpen={modalDialog}
                  closeModal={() => setModalDialog(false)}
                  content={data}
                  headerData={item}
                />
              </React.Fragment>
            )
          })}
        </SyncDataTableContainer>
      </TableContainer>
    </>
  )
}

export default React.memo(ExplorerSyncDataTable)
