import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, LinearProgress, makeStyles, withStyles, Typography, FormControlLabel, Switch, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { format } from 'date-fns'

import axios from '../../../../helpers/axios'

const propTypes = {
  currentSync: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const useStyles = makeStyles(theme => ({
  progressWrapper: {
    width: '90%',
    margin: '0 auto'
  },
  statusWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    margin: '20px 0',
    flexDirection: 'column'
  },
  statusContent: {
    padding: '8px 12px',
    fontSize: '16px',
    borderRadius: '15px'
  }
}))

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#35A0F4'
  }
}))(LinearProgress)

function formatDate (date) {
  return format(new Date(date), 'dd.MM.yyyy HH:mm')
}

function normalizeProgress (value, maxValue) {
  const MAX = maxValue
  const MIN = 0
  const normalizedProgress = (value - MIN) * 100 / (MAX - MIN)
  return normalizedProgress
}

const ProgressBar = ({ currentSync, isLoading }) => {
  const [syncType, setSyncType] = useState(window.localStorage?.getItem('syncType') || 'skip')
  const [sendingRequest, setSendingRequest] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (window.localStorage) {
      window.localStorage.setItem('syncType', syncType)
    }
  }, [syncType])

  const currentProgress = normalizeProgress(currentSync?.syncedCompaniesCount, currentSync?.companiesToSyncCount)

  return (
    <Box>
      <Box className={classes.statusWrapper}>
        <Alert severity='info' className={classes.statusContent}>
          {
            isLoading
              ? 'Синхронизация...'
              : `Следующая синхронизация: ${currentSync?.nextPlanningSyncDate && formatDate(currentSync?.nextPlanningSyncDate)}`
          }
        </Alert>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              disabled={!currentSync || currentSync.id || sendingRequest}
              checked={syncType === 'full'} onChange={e => {
                if (e.target.checked) {
                  setSyncType('full')
                } else {
                  setSyncType('skip')
                }
              }} name='syncType'
            />
          }
          label='Полная синхронизация (без кэша)'
        />
        <Button
          color='primary'
          variant='outlined'
          disabled={!currentSync || sendingRequest}
          onClick={async () => {
            setSendingRequest(true)
            try {
              if (currentSync && currentSync.id) {
                await axios.put('/api/stock-sync/cms/stop-sync')
              } else {
                await axios.post('/api/stock-sync/cms/start-sync', { syncType })
              }
            } catch (error) {
              console.error(error)
              window.alert(error.response?.data?.message || error.message)
            }
            setSendingRequest(false)
          }}
        >
          {currentSync && currentSync.id ? 'Остановить синхронизацию' : 'Синхронизировать'}
        </Button>
      </Box>
      {isLoading
        ? (
          <Box className={classes.progressWrapper}>
            <Box display='flex' alignItems='center'>
              <Box width='100%' mr={1}>
                <BorderLinearProgress variant='determinate' value={currentProgress} />
              </Box>
              <Box minWidth={35}>
                <Typography color='textSecondary'>
                  {!isNaN(currentProgress) ? `${Math.round(currentProgress)}%` : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
          )
        : null}
    </Box>
  )
}

ProgressBar.propTypes = propTypes

export default React.memo(ProgressBar)
