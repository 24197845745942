import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography, Box } from '@material-ui/core'

const propTypes = {
  children: PropTypes.string.isRequired
}

const TypographyStyled = styled(Typography)`
  font-size: 40px;
`

const PageTitle = ({ children }) => {
  return (
    <Box marginBottom='20px' padding='0 24px'>
      <TypographyStyled variant='h1'>
        {children}
      </TypographyStyled>
    </Box>
  )
}

PageTitle.propTypes = propTypes

export default PageTitle
