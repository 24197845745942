import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5),
    minWidth: '35%'
  },
  dialogTitle: {
    paddingRight: '0px'
  }
}))

export default function Popup (props) {
  const { title, children, openPopup, setOpenPopup, isFullScreen } = props
  const classes = useStyles()

  return (
    <Dialog
      onClose={() => setOpenPopup(false)}
      open={openPopup}
      fullWidth={!!isFullScreen}
      maxWidth={isFullScreen ? 'lg' : 'md'}
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: 'flex' }}>
          <Typography variant='h6' component='div' style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Button
            color='primary'
            onClick={() => setOpenPopup(false)}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
    </Dialog>
  )
}
