import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Box, Button, Collapse, Fade, MenuItem, TextField } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

// other
import { alphabetHypensSpaces } from '../../helpers/regexp'
import axios from '../../helpers/axios'
import useMessage from '../../hooks/useMessage'

const propTypes = {
  onAddRole: PropTypes.func.isRequired
}

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  button: {
    width: '157px',
    justifyContent: 'space-between',
    marginRight: '10px'
  },
  checkbox: {
    padding: '5px'
  },
  collapse: {
    width: '100%'
  }
}))

const AddRole = ({ onAddRole }) => {
  const classes = useStyles()
  const showMessage = useMessage()

  const formik = useFormik({
    initialValues: {
      adding: false,
      name: '',
      description: '',
      type: ''
    },
    validate (values) {
      const { name, description, type } = values
      const errors = {}

      if (!name || !alphabetHypensSpaces.test(name)) {
        errors.name = true
      }

      if (description.length === 0) {
        errors.description = true
      }

      if (!type) {
        errors.type = true
      }

      return errors
    },
    async onSubmit (values) {
      const { name, description, type } = values

      try {
        const { data } = await axios.post('/api/roles', {
          name,
          description,
          type
        })
        if (data) {
          onAddRole(data)
          showMessage('Роль добавлена', 'success', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          formik.setFieldValue('adding', false)
        }
      } catch (e) {
        console.error(e)
        showMessage('Ошибка добавления роли', 'error', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
      }
    }
  })

  const {
    values,
    handleSubmit,
    setFieldValue,
    setValues,
    setErrors,
    handleBlur,
    handleChange,
    errors: {
      name: nameErr,
      description: descriptionErr,
      type: typeErr
    },
    touched: {
      name: nameTouched,
      description: descriptionTouched,
      type: typeTouched
    }
  } = formik

  const {
    name,
    description,
    type,
    adding
  } = values

  const onSetAdding = () => {
    /* Если добавление открыто, закрываем */
    if (adding) {
      setFieldValue('adding', false)
      /* Если добавление закрыто, открываем, сбрасывая все данные */
    } else {
      setValues({
        adding: true,
        name: '',
        description: '',
        type: ''
      }, false)
      setErrors({
        name: false,
        description: false,
        type: false
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        marginBottom='10px'
        padding='0 16px 10px 16px'
      >
        <Box
          display='flex'
          flexDirection='row'
        >
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.button}
            onClick={onSetAdding}
            endIcon={<ExpandMore className={adding ? classes.expandOpen : classes.expand} />}
          >
            {adding ? 'Отмена' : 'Добавить роль'}
          </Button>
          <Fade in={adding}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              type='submit'
            >
              Сохранить
            </Button>
          </Fade>
        </Box>
        <Collapse in={adding} timeout='auto' className={classes.collapse} unmountOnExit>
          <Box
            display='flex'
            flexDirection='column'
          >
            <TextField
              name='name'
              margin='dense'
              value={name}
              error={nameErr && nameTouched}
              label={nameErr && nameTouched ? 'Только буквы, пробелы и тире' : 'Название'}
              onBlur={handleBlur}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Название' }}
            />
            <TextField
              name='description'
              margin='dense'
              value={description}
              error={descriptionErr && descriptionTouched}
              label={descriptionErr && descriptionTouched ? 'Описание не должно быть пустым' : 'Описание'}
              onBlur={handleBlur}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Описание' }}
            />
            <TextField
              name='type'
              label='Тип роли'
              select
              margin='dense'
              value={type}
              error={typeErr && typeTouched}
              onBlur={handleBlur}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'Тип роли' }}
            >
              <MenuItem value='customer'>Клиент</MenuItem>
              <MenuItem value='editor'>Редактор</MenuItem>
              <MenuItem value='admin'>Администратор</MenuItem>
            </TextField>
          </Box>
        </Collapse>
      </Box>
    </form>
  )
}

AddRole.propTypes = propTypes

export default AddRole
