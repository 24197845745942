import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from '../../helpers/axios'
import { Box, Typography, makeStyles, Grid, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import ActiveFiltersItem from '../../components/Filters/ActiveFiltersItem'
import ActiveFilterPresentation from '../../components/Filters/ActiveFilterPresentation'
import useMessage from '../../hooks/useMessage'

const propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.shape({
        alternativeNames: PropTypes.arrayOf(PropTypes.string),
        createdAt: PropTypes.string,
        name: PropTypes.string,
        translatedName: PropTypes.string,
        updatedAt: PropTypes.string,
        __v: PropTypes.number,
        _id: PropTypes.string
      }),
      createdAt: PropTypes.string,
      displayName: PropTypes.string,
      exchangeCodes: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
      updatedAt: PropTypes.string
    })
  ).isRequired
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: '1px solid rgb(63, 81, 181)',
    padding: '15px',
    borderRadius: '4px'
  },
  title: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(1)
  },
  buttonWrapper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginRight: theme.spacing(2)
  },
  warning: {
    width: '350px'
  }
}))

const ActiveFilters = ({ filters }) => {
  const classes = useStyles()
  const showMessage = useMessage()
  const [activeFilters, setActiveFilters] = useState({})
  const [editing, setEditing] = useState(false)

  function handleEditing () {
    setEditing(!editing)
  }

  async function sendFilters (activeFilters) {
    function sendHandler (filters) {
      const result = []
      if (filters) {
        Object.entries(filters).forEach(item => {
          if (item[1]) result.push(item[0])
        })
      }
      return result
    }

    try {
      const newFilters = sendHandler(activeFilters)
      const { data } = await axios.put('/api/screener', {
        filters: newFilters
      })
      if (data) {
        handleEditing()
        showMessage('Фильтры успешно обновлены', 'success')
      }
    } catch (e) {
      console.error(e)
      showMessage('Ошибка обновления фильтров')
    }
  }

  async function filtersTransform (allFilters, activeFilters) {
    const result = {}
    allFilters.forEach(item => {
      result[item.id] = false
      activeFilters.forEach(item2 => {
        if (item.id === item2.id) {
          result[item.id] = true
        }
      })
    })
    return result
  }

  useEffect(async () => {
    try {
      const { data } = await axios.get('/api/screener/settings')
      if (data && data.filters && data.filters.length && filters) {
        setActiveFilters(await filtersTransform(filters, data.filters))
      }
    } catch (e) {
      console.error(e)
      showMessage('Ошибка получения активных фильтров')
    }
  }, [])

  return (
    <div style={{ marginBottom: '20px' }}>
      <Box className={classes.wrapper}>
        <Typography className={classes.title}> Активные фильтры: </Typography>
        <Grid container>
          {filters && filters.length && Object.keys(activeFilters).length && filters.map(filter =>
            <Grid item xs={2} key={filter.id}>
              {editing
                ? (
                  <ActiveFiltersItem
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    key={filter.id}
                    id={filter.id}
                    title={filter.displayName}
                  />
                  )
                : (
                  <ActiveFilterPresentation
                    activeFilters={activeFilters}
                    title={filter.displayName}
                    id={filter.id}
                  />
                  )}
            </Grid>
          )}
          {!Object.keys(activeFilters).length && (
            <Alert className={classes.warning} severity='warning'>Список фильтров пуст</Alert>
          )}
        </Grid>
        <Box className={classes.buttonWrapper}>
          {editing
            ? (
              <Box display='flex'>
                <Button variant='contained' onClick={handleEditing} className={classes.button}> Отмена </Button>
                <Button color='primary' variant='contained' onClick={() => sendFilters(activeFilters)}> Сохранить изменения </Button>
              </Box>
              )
            : <Button color='primary' variant='contained' onClick={handleEditing}> Изменить </Button>}
        </Box>
      </Box>
    </div>
  )
}

ActiveFilters.propTypes = propTypes

export default ActiveFilters
