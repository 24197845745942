import { useEffect, useState } from 'react'
import useMessage from './useMessage'
import useSWR from 'swr'
import axios from '../helpers/axios'
import { produce } from 'immer'
import originRoutes from '../assets/static/routes'

export const fetcher = async (key, token) => {
  try {
    const response = await axios.get('/api/users/me', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const data = response?.data || null

    return data
  } catch (error) {
    throw error
  }
}

function createRoutes (routeObj, permissions, setRoutes) {
  const newRoutes = produce(routeObj, draft => {
    draft.forEach(route => {
      permissions.forEach(permission => {
        if (route.subject === permission.subject) {
          route.visibility = true
          if (route.routes) {
            route.routes.forEach(subroute => {
              if (subroute.exception === permission.subject && subroute.action === permission.action) subroute.visibility = true
              if (subroute.action === permission.action) subroute.visibility = true
            })
          }
        }
      })
    })
  })
  setRoutes(newRoutes)
}

function createRoutesForAdmin (routeObj, setRoutes) {
  const newRoutes = produce(routeObj, draft => {
    draft.forEach(route => {
      route.visibility = true
      if (route.routes) {
        route.routes.forEach(subroute => {
          subroute.visibility = true
        })
      }
    })
  })
  setRoutes(newRoutes)
}

const useUserRole = () => {
  const [userRole, setUserRole] = useState()
  const [routesPermission, setRoutesPermission] = useState([])
  const [routes] = useState(originRoutes)
  const showMessage = useMessage()
  const token = window.localStorage.getItem('token')

  const { data, error } = useSWR(token ? ['/api/user/me', token] : null, fetcher)

  useEffect(async () => {
    if (data) {
      const { fullName, role: userRole } = data

      if (userRole) {
        userRole.fullName = fullName
      } else {
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('refreshToken')
        showMessage('Ошибка загрузки данных пользователя')
      }

      setUserRole(userRole)
    }
  }, [data])

  useEffect(() => {
    if (userRole) {
      const { permissions, name } = userRole

      name === 'admin'
        ? createRoutesForAdmin(routes, setRoutesPermission)
        : createRoutes(routes, permissions, setRoutesPermission)
    }
  }, [userRole])

  return { data, userRole, error, token, routesPermission }
}

export default useUserRole
