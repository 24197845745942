import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import { Box, Button, Link, Table, TableBody, TableContainer, TableHead } from '@material-ui/core'
import PageTitle from '../../components/PageTitle'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import canAccess from '../../helpers/canAccess'
import useSWR from 'swr'
import axios from '../../helpers/axios'
import { EducationsTableHead } from '../../components/education/EducationsTableHead'
import { EducationsListElement } from '../../components/education/EducationsListElement'
import useMessage from '../../hooks/useMessage'
import ConfirmDialog from '../../components/ConfirmDialog'

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
`

export default function Educations () {
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false })
  const [educationsList, setEducationsList] = useState([])
  const canCreate = canAccess('tariff', 'create')
  const canUpdate = canAccess('tariff', 'update')
  const canDelete = canAccess('tariff', 'delete')
  const history = useHistory()
  const showMessage = useMessage()
  const [params, setParams] = useState({ limit: 20, skip: 0 })

  const { data: educationsCount = { count: 1000 } } = useSWR('/api/educations/cms/count', () => axios.get('/api/educations/cms/count').then(list => list.data))

  const deleteEducation = async (id) => {
    try {
      await axios.delete(`/api/educations/cms/${id}`)
      const newEducationsList = educationsList.filter(education => education.id !== id)
      setEducationsList(newEducationsList)
      setConfirmDialog({ isOpen: false })
      showMessage('Статья успешно удалена', 'success')
    } catch (e) {
      console.log(e)
      showMessage(`Ошибка: ${e.response.data?.message}`)
    }
  }

  const loadMoreEducations = async () => {
    if (params.skip < educationsCount.count) {
      try {
        const { data } = await axios.get(`/api/educations/cms?limit=${params.limit}&skip=${params.skip}`)
        const newList = [...educationsList, ...data]
        setParams({ ...params, skip: params.skip + params.limit })
        setEducationsList(newList)
      } catch (e) {
        console.log(e)
        showMessage(`Невозможно подгрузить больше. Ошибка сервера. Код: ${e.response.status}`)
      }
    } else {
      setParams({ ...params, skip: 100000 })
    }
  }

  useEffect(() => {
    const getList = async () => {
      try {
        const { data } = await axios.get(`/api/educations/cms?limit=${params.limit}&skip=${params.skip}`)
        setEducationsList(data)
        setParams({ ...params, skip: params.skip + params.limit })
      } catch (e) {
        console.log(e)
        showMessage(`Не удалось загрузить стать. Код ошибки: ${e.response.status}`)
      }
    }
    getList()
  }, [])

  function onEdit (id) {
    history.push(`/education/${id}`)
  }

  return (
    <>
      <BreadcrumbsComponent/>
      <PageHeader>
        <Box display="flex" justifyContent="space-between">
          <PageTitle>Статьи</PageTitle>
          {canCreate && (
            <Box>
              <Link component={RouterLink} to="education/add">
                <Button color="primary" variant="contained">
                  Создать новый
                </Button>
              </Link>
            </Box>
          )}
        </Box>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <EducationsTableHead/>
            </TableHead>
            <TableBody>
              {educationsList.map(education =>
                <EducationsListElement
                  tariffData={education}
                  canEdit={canUpdate}
                  canDelete={canDelete}
                  onEdit={() => onEdit(education?.id)}
                  onDelete={() => deleteEducation(education?.id)}
                  setConfirmDialog={setConfirmDialog}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PageHeader>
      <Button
        color="primary"
        variant="contained"
        size="large"
        disabled={params.skip >= educationsCount.count}
        style={{ marginTop: 30 }}
        onClick={loadMoreEducations}
      >
        Получить больше статей
      </Button>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  )
}
