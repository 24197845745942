import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

const propTypes = {
  width: PropTypes.string.isRequired,
  fontWeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  fontSize: PropTypes.string.isRequired,
  lineHeight: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
}

const defaultProps = {
  defaultValue: ''
}

const InputStyled = styled.input`
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  color: ${props => props.color};
  width: ${props => props.isVisible ? props.width : 0};
  height: ${props => props.isVisible ? props.lineHeight : 0};
  background: none;
  letter-spacing: 0.2px;
  text-align: center;
  border: none;
  padding: 0;
`

const Text = styled.div`
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  color: ${props => props.color};
  width: ${props => props.isVisible ? props.width : 0};
  height: ${props => props.isVisible ? props.lineHeight : 0};
  opacity: ${props => props.isValue ? 1 : 0.5};
  letter-spacing: 0.2px;
  text-align: center;
  overflow: hidden;
`

const Input = ({ width, fontWeight, fontSize, lineHeight, color, defaultValue }) => {
  const [value, setValue] = useState()
  const [inputActive, setInputActive] = useState(false)
  const componentRef = React.createRef()
  let valueToDisplay = defaultValue || 'Текст'

  if (value) valueToDisplay = value

  const onFocus = () => {
    setInputActive(true)
    componentRef.current.focus()
  }

  const onBlur = () => {
    setInputActive(false)
  }

  const onChange = (e) => {
    const { target: { value } } = e

    setValue(value)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
    >
      <InputStyled
        ref={componentRef}
        isVisible={inputActive}
        width={width}
        fontWeight={fontWeight}
        fontSize={fontSize}
        lineHeight={lineHeight}
        color={color}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
      />
      <Text
        isVisible={!inputActive}
        isValue={value || defaultValue}
        width={width}
        fontWeight={fontWeight}
        fontSize={fontSize}
        lineHeight={lineHeight}
        color={color}
        onClick={onFocus}
      >
        {valueToDisplay}
      </Text>
    </Box>
  )
}

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default Input
