import React, { useEffect, useState } from 'react'
import Input from '@material-ui/core/Input'
import { Button, Chip, TextField } from '@material-ui/core'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import useSWR from 'swr'
import { useDebounce } from 'use-debounce'

import axios from '../../helpers/axios'

const FormWrap = styled.form`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const InputsBlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  margin-bottom: 20px;
  min-height: 50px;
`
const InputsSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`
const InputsSectionHeader = styled.h3`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  color: gray;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 20px;
  }
`

async function getSearchResults (query) {
  if (!query) return []
  try {
    const { data: companies } = await axios.get(`/api/country?name=${query}`)
    return companies.length ? companies : []
  } catch (e) {
    console.error(e)
    return []
  }
}

export const EditCompanyBlock = ({ onCancel, onSave, id, providerExchangeCode, exchangeCountry, ticker, countries }) => {
  const isNewCompany = !id

  const [searchValue1, setSearchValue1] = useState('')
  const [searchValue2, setSearchValue2] = useState('')

  const [debouncedSearch1] = useDebounce(searchValue1, 500)
  const [debouncedSearch2] = useDebounce(searchValue2, 500)

  function isDoSearch (searchValue, debounceSearch) {
    return debounceSearch !== undefined && searchValue === debounceSearch
  }

  useEffect(() => {
    exchangeCountry?.translatedName && setSearchValue1(exchangeCountry?.translatedName)
  }, [])

  const { data: suggestions1 = [] } = useSWR(() => isDoSearch(searchValue1, debouncedSearch1) ? '/api/countries' : null, () => getSearchResults(debouncedSearch1))
  const { data: suggestions2 = [] } = useSWR(() => isDoSearch(searchValue2, debouncedSearch2) ? '/api/countries' : null, () => getSearchResults(debouncedSearch2))

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id,
      providerExchangeCode,
      exchangeCountry,
      ticker,
      countries: countries || []
    },

    onSubmit (values, { setSubmitting }) {
      setSubmitting(true)
      try {
        onSave(values, isNewCompany)
      } catch (error) {
        console.error(error)
      }
    }
  })

  return (
    <FormWrap onSubmit={formik.handleSubmit}>
      <InputsBlockWrapper>
        <InputsSection>
          <InputsSectionHeader>Тикер:</InputsSectionHeader>
          <Input
            style={{ marginBottom: '30px' }}
            value={formik.values.ticker}
            onChange={e => formik.setFieldValue('ticker', e.target.value.toUpperCase())}
            placeholder='Введите тикер компании'
          />
          <InputsSectionHeader>Код биржи:</InputsSectionHeader>
          <Input
            style={{ marginBottom: '30px' }}
            value={formik.values.providerExchangeCode}
            onChange={e => formik.setFieldValue('providerExchangeCode', e.target.value)}
            placeholder='Введите код биржи'
          />
          <InputsSectionHeader>Страна биржи:</InputsSectionHeader>
          <Autocomplete
            freeSolo
            inputValue={searchValue1}
            filterOptions={x => x}
            onInputChange={(e, newInputValue) => setSearchValue1(newInputValue)}
            onChange={(e, value) => formik.setFieldValue('exchangeCountry', value)}
            options={suggestions1}
            getOptionLabel={option => option?.translatedName || '-'}
            renderInput={(params) => <TextField {...params} placeholder='Поиск страны' variant='standard' />}
          />
        </InputsSection>
        <InputsSection>
          <InputsSectionHeader>Список стран:</InputsSectionHeader>
          <Autocomplete
            multiple
            limitTags={5}
            id='multiple-limit-tags'
            inputValue={searchValue2}
            filterOptions={x => x}
            onInputChange={(e, newInputValue) => setSearchValue2(newInputValue)}
            onChange={(e, value) => formik.setFieldValue('countries', value)}
            options={suggestions2}
            getOptionLabel={option => option?.translatedName || '-'}
            defaultValue={countries}
            renderInput={(params) => (
              <TextField {...params} label='' placeholder='Поиск страны' />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option?.translatedName || option}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </InputsSection>
      </InputsBlockWrapper>
      <ButtonsContainer>
        <Button
          variant='contained'
          color='error'
          onClick={onCancel}
        >
          Отмена
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
        >
          {isNewCompany ? 'Создать' : 'Сохранить'}
        </Button>
      </ButtonsContainer>
    </FormWrap>
  )
}
