import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

const propTypes = {
  syncErrorsCount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired
}

const Error = ({ syncErrorsCount, status }) => {
  const syncStatus = status === 'success' ? 'Успешно завершена' : 'Не завершена'

  return (
    <Box>
      <Box>
        <Typography> Количество ошибок: {syncErrorsCount} </Typography>
      </Box>
      <Box>
        <Typography>
          Статус: <span style={{ color: syncStatus === 'Успешно завершена' ? 'green' : 'red' }}> {syncStatus} </span>
        </Typography>
      </Box>
    </Box>

  )
}

Error.propTypes = propTypes

export default Error
