import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, AppBar, Tabs, Tab } from '@material-ui/core'
import TabAnimation from '../TabAnimation'

function TabPanel (props) {
  const { children, value, index } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
    >
      {value === index && (
        <Box mt={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const TabsComponent = ({ PageTabsList }) => {
  const [countTab, setCountTab] = useState(0)

  function handleChange (event, newValue) {
    setCountTab(newValue)
  }

  return (
    <Box margin='50px 0'>
      <AppBar position='static' color='default'>
        <Tabs
          value={countTab}
          onChange={handleChange}
          aria-label='tabs'
          variant='fullWidth'
          indicatorColor='primary'
          textColor='primary'
        >
          {PageTabsList.map(tab => <Tab key={tab.id} label={tab.name} id={tab.id} aria-controls={tab.id} />)}
        </Tabs>
      </AppBar>
      {PageTabsList.map((tab, index) =>
        <TabPanel
          key={tab.id}
          value={countTab}
          index={index}
        >
          <TabAnimation key={tab.id}>
            {PageTabsList[countTab].component}
          </TabAnimation>
        </TabPanel>)}
    </Box>
  )
}

TabsComponent.propTypes = {
  tabsList: PropTypes.array.isRequired
}

export default React.memo(TabsComponent)
