import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, Link as RouterLink } from 'react-router-dom'

import routes from '../../assets/static/routes'
import { Breadcrumbs, Link, Typography } from '@material-ui/core'

const propTypes = {
  forceMatched: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    href: PropTypes.string
  }))
}

const BreadcrumbsComponent = ({ forceMatched }) => {
  const currentUrl = useLocation().pathname

  const findRoutes = () => {
    const uri = (currentUrl[0] === '/' ? currentUrl.substring(1) : currentUrl).split(/[?#]/)[0].split()
    let levelRoute = routes

    const subFindRoutes = () => {
      let result = false
      levelRoute.forEach(item => {
        if (item.routes) {
          if (item.routes.find(el => el.href === currentUrl) !== undefined) {
            result = item.routes.find(el => el.href === currentUrl)
          }
        }
      })
      return result
    }

    return uri.map((item) => {
      levelRoute = levelRoute.find(item => item.href === currentUrl) || subFindRoutes() || { name: 404, href: '' }
      return { href: levelRoute.href, name: levelRoute.name }
    })
  }

  const matchedRoutes = forceMatched || findRoutes()
  if (matchedRoutes[0].href !== '/') matchedRoutes.unshift({ href: '/', name: 'Главная' })

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {matchedRoutes.length
        ? matchedRoutes.map(({ name, href }, index) => {
          return (index === matchedRoutes.length - 1
              ? <Typography key={name} color='textPrimary'> {name} </Typography>
              : <Link color='inherit' component={RouterLink} to={href} key={href}> {name} </Link>
            )
          })
        : null}
    </Breadcrumbs>
  )
}

Breadcrumbs.propTypes = propTypes

export default React.memo(BreadcrumbsComponent)
