import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Divider,
  Typography
} from '@material-ui/core'

// blocks
import AddRole from '../blocks/AddRole'
import RoleCard from '../blocks/RoleCard'

// components
import PageTitle from '../components/PageTitle'
import ParagraphTitle from '../components/ParagraphTitle'
import Preloader from '../components/Preloader'
import BreadcrumbsComponent from '../components/Breadcrumbs'
import ConfirmDialog from '../components/ConfirmDialog'

// hooks
import axios from '../helpers/axios'
import useMessage from '../hooks/useMessage'
import canAccess from '../helpers/canAccess'

const getRoles = async () => {
  try {
    const { data } = await axios.get('/api/roles')

    return data
  } catch (e) {
    console.error(e)
    return []
  }
}

const AlertStyled = styled(Alert)`
  width: 590px;
  margin-bottom: 20px;
`

const Roles = () => {
  const createRole = canAccess('role', 'create')
  const updateRole = canAccess('role', 'update')
  const deleteRole = canAccess('role', 'delete')

  const [roles, setRoles] = useState()
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false })
  const showMessage = useMessage()

  /* Добавляем роль */
  const onAddRole = (newRole) => {
    const newRoles = JSON.parse(JSON.stringify(roles))

    newRoles.push(newRole)

    setRoles(newRoles)
  }

  /* Удаляем роль */
  const onDeleteRole = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })
    try {
      const { data } = await axios.delete(`/api/roles/${id}`)
      showMessage('Роль удалена', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      setRoles(data)
    } catch (e) {
      console.error(e)
      showMessage('Ошибка удаления', 'error', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
    }
  }

  useEffect(async () => {
    try {
      const data = await getRoles()

      setRoles(data)
    } catch (e) {
      console.error(e)
      setRoles([])
    }
  }, [])

  return (
    <Box
      display='flex'
      flexDirection='column'
    >
      <BreadcrumbsComponent />
      <PageTitle>Роли</PageTitle>
      {
        createRole
          ? <AddRole onAddRole={onAddRole} />
          : <AlertStyled severity='warning'>Нет разрешения на добавление роли</AlertStyled>
      }
      <ParagraphTitle>Добавленные роли</ParagraphTitle>
      <Divider />
      {!roles && <Box position='relative' paddingTop='70px'><Preloader /></Box>}
      {roles && roles.length === 0 ? (<Box color='warning.main' margin='10px 0 10px 0'><Typography variant='h5'>Список ролей пуст</Typography></Box>) : null}
      {
        roles && roles.length ? roles.map((role) => {
          const { id, name, description, type } = role

          const handleDeleteRole = () => {
            onDeleteRole(id)
          }

          return (
            <RoleCard
              key={id}
              id={id}
              name={name}
              description={description}
              type={type}
              canUpdate={updateRole}
              canDelete={deleteRole}
              onDeleteRole={deleteRole ? handleDeleteRole : null}
              setConfirmDialog={setConfirmDialog}
            />
          )
        }) : null
      }
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Box>
  )
}

export default Roles
