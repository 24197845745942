import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  FormControlLabel, Grid,
  InputLabel, makeStyles,
  Switch,
  TextField,
} from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useFormik } from 'formik'

import axios from '../../helpers/axios'
import canAccess from '../../helpers/canAccess'
import PageTitle from '../../components/PageTitle'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import useMessage from '../../hooks/useMessage'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
import { green, grey } from '@material-ui/core/colors'
import JoditEditor from 'jodit-react'
import useSWR from 'swr'

const { REACT_APP_API_ENDPOINT } = process.env

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 40px;
`

const ImageBlock = styled.div`
  display: flex;
  margin: 40px 0;
`

const Image = styled.img`
  width: ${props => props.width && '500px'};
  margin: ${props => props.width && '0 50px 0 0'};
`

const GreenSwitch = withStyles({
  switchBase: {
    color: grey[600],
    '&$checked': {
      color: green[500]
    },
    '& + $track': {
      backgroundColor: grey[500]
    },
    '&$checked + $track': {
      backgroundColor: green[200]
    }
  },
  checked: {},
  track: {}
})(Switch)

const useStyles = makeStyles(theme => ({
  inputHolder: {
    marginTop: theme.spacing(1.5)
  },
  textInput: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  textLabel: {
    color: '#000',
    fontWeight: '700'
  }
}))

let showMessageFunc

const getConfig = (token) => {
  return {
    height: 800,
    link: {
      processVideoLink: false
    },
    disablePlugins: ['video', 'copy-format', 'file'],
    readonly: false,
    uploader: {
      url: `${REACT_APP_API_ENDPOINT}/api/files`,
      method: 'POST',
      insertImageAsBase64URI: false,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
      data: null,
      headers: {
        Authorization: `Bearer ${token || window.localStorage.getItem('token')}`
      },
      filesVariableName: () => 'files',
      format: 'json',
      prepareData: data => data,
      isSuccess: resp => resp,
      process: resp => resp,
      defaultHandlerSuccess: function (data) {
        const joditObj = this.jodit || this

        if (data && data.length) {
          for (let i = 0; i < data.length; i += 1) {
            joditObj.selection.insertImage(data[i].url, null, 300)
          }
        }
      },
      error: () => {
        showMessageFunc('Ошибка загрузки картинок')
      },
      contentType: function (requestData) {
        return this.j.ow.FormData !== undefined && typeof requestData !== 'string'
          ? false
          : 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }
  }
}

const initialValues = {
  title: '',
  metaInformation: '',
  isPublished: false,
  availableFree: false,
  description: '',
  content: '',
}

const inputsWidth = { width: '400px' }

const Education = () => {
  const { params: { id } } = useRouteMatch({ path: '/education/:id' })
  const isNewEducation = id === 'add'

  const classes = useStyles()
  const editor = useRef(null)
  const [config, setConfig] = useState(getConfig())

  const showMessage = useMessage()
  const history = useHistory()
  const { data: currentEducation = [] } = useSWR(isNewEducation ? null : `/api/educations/cms/${id}`, () => axios.get(`/api/educations/cms/${id}`).then(list => list.data))
  const [icon, setIcon] = useState('')

  useEffect(() => {
    if (!isNewEducation) {
      setIcon(currentEducation?.img)
    }
  }, [id])

  async function changeIcon (event) {
    const uploadIcon = new FormData()
    uploadIcon.append('files', event.currentTarget.files[0])
    try {
      const { data: upload } = await axios.post('/api/files', uploadIcon, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      setIcon(upload[0].url)
    } catch (e) {
    }
  }

  useEffect(async () => {
    showMessageFunc = showMessage
  }, [JSON.stringify(showMessage)])

  useEffect(() => {
    const interval = setInterval(() => {
      const getNewOptions = async () => {
        try {
          const refreshToken = window.localStorage.getItem('refreshToken')
          const { data } = await axios.post(`${REACT_APP_API_ENDPOINT}/api/auth/refresh`, {
            refreshToken
          })

          const { token, refreshToken: receivedRefreshToken } = data

          window.localStorage.setItem('token', token)
          window.localStorage.setItem('refreshToken', receivedRefreshToken)
          setConfig(getConfig(token))
          return null
        } catch (e) {
          console.error(e)
          window.localStorage.removeItem('token')
          window.localStorage.removeItem('refreshToken')
          window.location.reload()
        }
      }
      getNewOptions()
    }, 270000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const canCreate = canAccess('education', 'create')
  const canUpdate = canAccess('education', 'update')

  const formik = useFormik({
    initialValues: isNewEducation ? {} : currentEducation,
    enableReinitialize: true,
    validate (values) {
      const errors = {}

      return errors
    },
    async onSubmit (values) {
      const valuesForSend = {
        title: values.title,
        availableFree: values.availableFree,
        isPublished: values.isPublished,
        metaInformation: values.metaInformation,
        content: values.content,
        description: values.description,
        img: icon
      }
      try {
        if (isNewEducation) {
          await axios.post('/api/educations/cms', valuesForSend)
        } else {
          await axios.patch(`/api/educations/cms/${id}`, valuesForSend)
        }
        history.push('/educations')
      } catch (e) {
        console.error(e)
        showMessage(`Ошибка: ${e.response.data?.message}`)
      }
    }
  })

  const {
    title = '',
    metaInformation = '',
    description = '',
    content = '',
    isPublished = '',
    availableFree = ''
  } = formik.values

  return (
    <>
      <BreadcrumbsComponent
        forceMatched={[
          {
            name: 'Все статьи',
            href: '/educations'
          },
          {
            name: isNewEducation ? 'Новый прогноз' : `Редактирование статьи ${title}`
          }
        ]}
      />
      <PageHeader>
        <PageTitle>{isNewEducation ? 'Создание новой статьи' : 'Редактирование статьи'}</PageTitle>
      </PageHeader>

      <form onSubmit={formik.handleSubmit}>
        <Box display="flex">
          <Box margin="0 50px 0 0">
            <TextField
              required
              id="name"
              variant="outlined"
              focused={!!currentEducation}
              margin="normal"
              name="name"
              label="Заголовок статьи"
              style={inputsWidth}
              value={currentEducation.title || title}
              onChange={e => formik.setFieldValue('title', e.target.value)}
            />
          </Box>
          <FormControlLabel
            control={
              <GreenSwitch
                checked={currentEducation.isPublished || isPublished}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="isPublished"
              />
            }
            label={isPublished ? 'Опубликована' : 'Не опубликована'}
          />
          <FormControlLabel
            control={
              <GreenSwitch
                checked={availableFree}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="availableFree"
              />
            }
            label={availableFree ? 'Без подписки' : 'Нужна подписка'}
          />
        </Box>
        <ImageBlock>
          <Image src={currentEducation?.img || icon} alt="" width={!!currentEducation.img || !!icon}/>
          <input
            accept="image/*"
            id={`raised-button-file`}
            name="files"
            multiple
            type="file"
            onChange={changeIcon}
            hidden
          />
          <label htmlFor={`raised-button-file`}>
            <Button variant="contained" size="small" component="span">
              Загрузить картинку
            </Button>
          </label>
        </ImageBlock>
        <Grid item xs={12}>
          <Box className={classes.inputHolder}>
            <InputLabel htmlFor="desc" className={classes.textLabel}>Описание мета инофрмации</InputLabel>
            <TextField
              className={classes.textInput}
              variant="outlined"
              margin="normal"
              name="desc"
              label="Описание мета информации"
              multiline
              rows={6}
              rowsMax={40}
              value={metaInformation}
              onChange={e => formik.setFieldValue('metaInformation', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.inputHolder}>
            <InputLabel htmlFor="desc" className={classes.textLabel}>Описание статьи</InputLabel>
            <TextField
              className={classes.textInput}
              variant="outlined"
              margin="normal"
              name="desc"
              label="Описание статьи"
              multiline
              rows={6}
              rowsMax={40}
              value={description}
              onChange={e => formik.setFieldValue('description', e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.inputHolder}>
            <InputLabel className={classes.textLabel}>Текст статьи</InputLabel>
          </Box>
          <Box className={classes.textInput}>
            <JoditEditor
              ref={editor}
              tabIndex={1}
              config={config}
              value={content}
              onChange={(content) => {
                formik.setFieldValue('content', content)
              }}
            />
          </Box>
        </Grid>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          disabled={!canCreate && !canUpdate}
          style={{ marginTop: 30 }}
          onClick={() => {}}
        >
          {isNewEducation ? 'Создать ликбез' : 'Сохранить ликбез'}
        </Button>
      </form>
    </>
  )
}

export default Education
