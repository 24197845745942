import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

export const SyncDataTableContainer = ({ children }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell> ID синхронизации </TableCell>
          <TableCell> Статус </TableCell>
          <TableCell> Причина ошибки </TableCell>
          <TableCell> Продолжительность </TableCell>
          <TableCell> Дата начала синхронизации</TableCell>
          <TableCell> Дата завершения синхронизации</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {children}
      </TableBody>
    </Table>
  )
}
