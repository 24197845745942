import React from 'react'
import styled from 'styled-components'
import { Box, Button, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useFormik } from 'formik'
import useSWR from 'swr'

import axios from '../../helpers/axios'
import canAccess from '../../helpers/canAccess'
import PageTitle from '../../components/PageTitle'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import { TariffFeaturesListItem } from '../../components/Tariffs/FeaturesListItem'
import useMessage from '../../hooks/useMessage'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 40px;
`

const inputsWidth = { width: '400px' }

const Tariff = () => {
  const { params: { id } } = useRouteMatch({ path: '/tariffs/:id' })
  const isNewTariff = id === 'add'

  const showMessage = useMessage()
  const history = useHistory()
  const { data: currentTariff = [] } = useSWR(isNewTariff ? null : `/api/tariffs/${id}`, () => axios.get(`/api/tariffs/${id}`).then(list => list.data))
  const { data: roles = [] } = useSWR('/api/roles', () => axios.get('/api/roles').then(list => list.data))

  const canCreate = canAccess('tariff', 'create')
  const canUpdate = canAccess('tariff', 'update')

  const formik = useFormik({
    initialValues: isNewTariff ? {} : currentTariff,
    enableReinitialize: true,
    validate (values) {
      const errors = {}

      return errors
    },
    async onSubmit (values) {
      try {
        if (isNewTariff) {
          await axios.post('/api/tariffs', values)
        } else {
          await axios.put(`/api/tariffs/${id}`, values)
        }
        history.push('/tariffs')
      } catch (e) {
        console.error(e)
        showMessage(`Ошибка: ${e.response.data?.message}`)
      }
    }
  })

  const { title = '', name = '', cost = '', role = '', content: { feature = [] } = {}, discount = '' } = formik.values

  const deleteFeature = (idx) => {
    const newList = feature.filter((item, currentIndex) => currentIndex !== idx)
    formik.setFieldValue('content.feature', newList)
  }

  return (
    <>
      <BreadcrumbsComponent />
      <PageHeader>
        <PageTitle>{isNewTariff ? 'Создание нового тарифа' : 'Редактирование тарифа'}</PageTitle>
      </PageHeader>

      <form onSubmit={formik.handleSubmit}>
        <Box>
        <TextField
          required
          id='name'
          variant='outlined'
          margin='normal'
          name='name'
          label='Именование в системе'
          style={inputsWidth}
          value={name}
          onChange={e => formik.setFieldValue('name', e.target.value)}
        />
      </Box>
        <Box>
          <TextField
            required
            id='title'
            variant='outlined'
            margin='normal'
            name='title'
            label='Название тарифа'
            style={inputsWidth}
            value={title}
            onChange={e => formik.setFieldValue('title', e.target.value)}
          />
        </Box>
        <Box>
          <TextField
            required
            id='cost'
            variant='outlined'
            margin='normal'
            name='cost'
            label='Стоимость в рублях'
            style={inputsWidth}
            value={cost}
            onChange={e => formik.setFieldValue('cost', e.target.value)}
          />
        </Box>
        <Box>
          <TextField
            required
            id='discount'
            variant='outlined'
            margin='normal'
            name='discount'
            label='Скидка в %'
            style={inputsWidth}
            value={discount}
            onChange={e => formik.setFieldValue('discount', e.target.value)}
          />
        </Box>
        <Box>
          <InputLabel>Роль</InputLabel>
          <Select
            required
            variant='outlined'
            name='dealType '
            defaultValue={role || 'роль не выбрана'}
            value={role || 'роль не выбрана'}
            onChange={e => formik.setFieldValue('role', e.target.value)}
          >
            {roles.map(role => <MenuItem value={role?.id}>{role?.description}</MenuItem>)}
          </Select>
        </Box>
        <Box style={{ marginTop: 30 }}>
          <Typography variant='h5' component='h5'>
            Список преимуществ
          </Typography>
          {feature?.map((featureItem, index) =>
            <TariffFeaturesListItem
              indexInList={index}
              deleteFeature={deleteFeature}
              featureIcon={featureItem?.icon}
              featureName={featureItem?.name}
              featurePoints={featureItem?.data}
              setData={callback => formik.setFieldValue('content.feature', callback(feature))}
            />)}
          {!feature.length && <Typography variant='h6' component='div'>список пуст...</Typography>}
          <Button
            color='primary'
            variant='contained'
            size='small'
            style={{ margin: '20px 0' }}
            onClick={() => formik.setFieldValue('content.feature', [...feature, {}])}
          >
            Добавить преимущество
          </Button>
        </Box>
        <Button
          type='submit'
          color='primary'
          variant='contained'
          size='large'
          disabled={!canCreate && !canUpdate}
          style={{ marginBottom: 30 }}
          onClick={() => {}}
        >
          {isNewTariff ? 'Создать тариф' : 'Сохранить тариф'}
        </Button>
      </form>
    </>
  )
}

export default Tariff
