import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Button from '../Button'

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
  },
  dialogTitle: {
    textAlign: 'center'
  },
  dialogContent: {
    textAlign: 'center'
  },
  dialogAction: {
    justifyContent: 'center'
  },
  titleIcon: {
    backgroundColor: '#FFF',
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: '#FFF',
      cursor: 'default'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '8rem'
    }
  }
}))

export default function ConfirmDialog ({ confirmDialog, setConfirmDialog }) {
  const { confirmButtonText, cancelButtonText, confirmButtonColor, cancelButtonColor } = confirmDialog
  const classes = useStyles()

  return (
    <Dialog
      onClose={() => setConfirmDialog(false)}
      open={confirmDialog.isOpen}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <ErrorOutlineIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant='h5'>
          {confirmDialog.title}
        </Typography>
        <Typography variant='subtitle2'>
          {confirmDialog.subTitle}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          text={cancelButtonText}
          color={cancelButtonColor}
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
        <Button
          text={confirmButtonText}
          color={confirmButtonColor}
          onClick={confirmDialog.onConfirm}
        />
      </DialogActions>
    </Dialog>
  )
}