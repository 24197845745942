import React from 'react'
import axios from 'axios'
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import useMessage from '../hooks/useMessage'
import { fetcher } from '../hooks/useUserRole'

const { REACT_APP_API_ENDPOINT } = process.env

const Login = () => {
  const showMessage = useMessage()

  const formik = useFormik({
    initialValues: {
      login: '',
      password: ''
    },
    validate (values) {
      const { login, password } = values
      const errors = {}

      if (login.length < 4) {
        errors.login = true
      }

      if (password.length < 6) {
        errors.password = true
      }

      return errors
    },
    async onSubmit (values) {
      const { login, password } = values

      try {
        /* Аутентификация */
        const { data } = await axios({
          method: 'POST',
          url: `${REACT_APP_API_ENDPOINT}/api/auth/cms`,
          data: {
            ...(+login ? { phone: login } : { email: login }),
            password
          }
        })

        const { token, refreshToken } = data

        /* Проверка данных пользователя на доступ к редактированию */
        await fetcher(null, token)

        /* Сохраняем данные токенов */
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('refreshToken', refreshToken)

        /* Перезагрузка приложения */
        window.location.reload()

        /* Записываем пользователя в контекст */
      } catch (e) {
        const errorStatus = e?.response?.status ?? null

        if (errorStatus) {
          if (errorStatus === 400 || errorStatus === 401) {
            showMessage('Неверный логин или пароль')
            return false
          }

          if (errorStatus === 403) {
            showMessage('У вас нет доступа к этому ресурсу')
            return false
          }

          if (errorStatus === 500) {
            showMessage('Ошибка сервера')
            return false
          }
        }

        showMessage('Неизвестная ошибка')
        return false
      }
    }
  })

  const {
    errors: {
      login: loginErr,
      password: passwordErr
    },
    touched: {
      login: loginTouched,
      password: passwordTouched
    }
  } = formik

  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      style={{ height: '100vh' }}
    >
      <Box marginBottom='20px' width='300px' textAlign='center'>
        <Typography
          variant='h1'
          color='primary'
          style={{ fontSize: '30px' }}
        >
          CMS Инвестиционный сервис.
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        p={2}
        border={1}
        borderColor='primary.main'
        borderRadius='borderRadius'
      >
        <form onSubmit={formik.handleSubmit}>
          <Box marginBottom='10px'>
            <TextField
              name='login'
              label='Логин'
              variant='outlined'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={loginErr && loginTouched}
            />
          </Box>
          <Box marginBottom='10px'>
            <TextField
              name='password'
              label='Пароль'
              variant='outlined'
              type='password'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={passwordErr && passwordTouched}
            />
          </Box>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            type='submit'
            disabled={loginErr || passwordErr || !loginTouched && !passwordTouched}
          >
            Вход
          </Button>
        </form>
      </Box>
    </Grid>
  )
}

export default Login
