import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useAuthSWR } from '../../helpers/axios'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import ProgressBar from '../../components/Explorer/syncStatistic/ProgressBar'
import Statistic from '../../components/Explorer/syncStatistic/Statistic'
import { motion } from 'framer-motion'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`
const MainWrapper = styled(motion.div)`
  margin-bottom: 100px;
`

const SyncStatistic = () => {
  const { data: currentSync } = useAuthSWR('/api/stock-sync/cms/in-progress', { refreshInterval: 3000 })
  const { data: lastSync, mutate: updateLastSync } = useAuthSWR('/api/stock-sync/cms/last/completed')

  useEffect(() => {
    updateLastSync()
  }, [currentSync])

  const isLoading = currentSync && Object.keys(currentSync).length > 1

  return (
    <>
      <BreadcrumbsComponent />
      <PageHeader>
        <PageTitle> Cтатистика синхронизации </PageTitle>
      </PageHeader>
      <MainWrapper
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <ProgressBar currentSync={currentSync} isLoading={isLoading} />
        {isLoading && <Statistic sync={currentSync} isLoading={isLoading} />}
        <Statistic sync={lastSync} key={lastSync} />
      </MainWrapper>
    </>
  )
}

export default SyncStatistic
