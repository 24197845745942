import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  Box,
  Grid,
  TextField,
  TextareaAutosize,
  Button,
  FormControlLabel,
  Switch,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  List,
  ListItem,
  Link,
  IconButton,
  Tooltip
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import { DeleteForever, Edit } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { green, grey } from '@material-ui/core/colors'
import { Alert, Autocomplete } from '@material-ui/lab'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { useFormik } from 'formik'
import ruLocale from 'date-fns/locale/ru'
import DateFnsUtils from '@date-io/date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// components
import PageTitle from '../../components/PageTitle'
import SearchResult from '../../components/SearchResult'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import Preloader from '../../components/Preloader'
import { SortableElement, SortableContainer } from '../../components/HOC'
import Popup from '../../components/Popup'

// other
import axios from '../../helpers/axios'
import { latinSmallAlphabetHypenAndNumbers, numbersValid } from '../../helpers/regexp'
import generateId from '../../helpers/generateId'
import useMessage from '../../hooks/useMessage'
import canAccess from '../../helpers/canAccess'

const useStyles = makeStyles(theme => ({
  form: {
    '& .MuiFormControl-root': {
      width: '90%',
      margin: theme.spacing(1)
    }
  },
  textInput: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  textArea: {
    width: '100%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '5px',
    borderColor: '#C4C4C4'
  },
  textLabel: {
    color: '#000',
    fontWeight: '700'
  },
  inputHolder: {
    marginTop: theme.spacing(1.5)
  },
  limitedWidthLg: {
    width: '590px'
  },
  limitedWidthMd: {
    width: '400px'
  },
  listItem: {
    padding: '8px',
    marginBottom: '5px'
  },
  link: {
    marginLeft: '10px'
  },
  resize: {
    resize: 'vertical'
  }
}))
const GreenSwitch = withStyles({
  switchBase: {
    color: grey[600],
    '&$checked': {
      color: green[500]
    },
    '& + $track': {
      backgroundColor: grey[500]
    },
    '&$checked + $track': {
      backgroundColor: green[200]
    }
  },
  checked: {},
  track: {}
})(Switch)
const TextFieldStyled = styled(TextField)`
  width: 590px;
`
const TextFieldMarginStyled = styled(TextFieldStyled)`
  flex: 0 0 590px;
  margin-right: 20px;
`
const KeyboardDatePickerStyled = styled(KeyboardDatePicker)`
  width: 265px;
`
const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 40px;
`

const CommentsSortableItem = SortableElement(({ text, id, index, onRemove, onEdit, canDelete, canUpdate }) => {
  const classes = useStyles()
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided) => (
        <ListItem
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={classes.listItem}
          divider
        >
          <LinkIcon color='primary' />
          <Typography style={{ marginLeft: '10px' }}>
            {text}
          </Typography>
          <Box style={{ marginLeft: '20px' }}>
            <Tooltip title='Редактировать комментарий'>
              <IconButton
                size='small'
                disabled={!canUpdate}
                style={{ color: canUpdate ? '#1976d2' : '#BDBDBD' }}
                onClick={() => onEdit(id)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title='Удалить комментарий'>
              <IconButton
                size='small'
                disabled={!canDelete}
                style={{ color: canDelete ? '#f44336' : '#BDBDBD' }}
                onClick={() => onRemove(id)}
              >
                <DeleteForever />
              </IconButton>
            </Tooltip>
          </Box>
        </ListItem>
      )}
    </Draggable>
  )
})

const CommentsContainer = SortableContainer(({ comments, onDragEnd, onRemove, onEdit, canUpdate, canDelete }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='commentaryList'>
        {(provided) => (
          <List {...provided.droppableProps} ref={provided.innerRef} alignItems='center'>
            {comments &&
            comments.map((comment, index) => (
              <CommentsSortableItem
                key={comment?.id}
                {...comment}
                onRemove={onRemove}
                index={index}
                onEdit={onEdit}
                canDelete={canDelete}
                canUpdate={canUpdate}
              />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
})

const CommentsEditor = ({ items, onChange, canUpdate, canDelete }) => {
  const [modalActive, setModalActive] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [currentId, setCurrentId] = useState('')

  const classes = useStyles()

  function onDragEnd (result) {
    if (!result.destination) return

    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    onChange(items)
  }

  function onEdit (id) {
    const item = items.find(entity => entity.id === id)
    setNewComment(item.text)
    setModalActive(true)
    setCurrentId(id)
  }

  function onRemove (id) {
    onChange(items.filter(comment => comment.id !== id))
  }

  return (
    <>
      <CommentsContainer
        onDragEnd={onDragEnd}
        comments={items}
        onRemove={onRemove}
        onEdit={onEdit}
        canUpdate={canUpdate}
        canDelete={canDelete}
      />
      <Popup title='Новый комментарий' openPopup={modalActive} setOpenPopup={setModalActive}>
        <TextareaAutosize
          rowsMin={10}
          className={classes.textArea}
          variant='outlined'
          label='Новый комментарий'
          margin='normal'
          value={newComment}
          onChange={e => setNewComment(e.target.value)}
        />
        <Button
          variant='contained'
          color='primary'
          type='button'
          style={{ display: 'block', margin: '15px auto' }}
          disabled={newComment.length <= 0}
          onClick={() => {
            onChange(items.map(comment => (comment.id === currentId ? { ...comment, text: newComment } : comment)))
            setModalActive(false)
            setCurrentId('')
            setNewComment('')
          }}
        >
          ОК
        </Button>
      </Popup>
    </>
  )
}

const LinksSortableItem = SortableElement(({ text, url, id, index, onRemove, onEdit }) => {
  const classes = useStyles()
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided) => (
        <ListItem
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={classes.listItem}
          divider
        >
          <LinkIcon color='primary' />
          <Link href={url} className={classes.link}>
            <Typography> {text} </Typography>
          </Link>
          <Box style={{ marginLeft: '20px' }}>
            <Tooltip title='Редактировать ссылку'>
              <IconButton size='small' style={{ color: '#1976d2' }} onClick={() => onEdit(id)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title='Удалить ссылку'>
              <IconButton size='small' style={{ color: '#f44336' }} onClick={() => onRemove(id)}>
                <DeleteForever />
              </IconButton>
            </Tooltip>
          </Box>
        </ListItem>
      )}
    </Draggable>
  )
})

const LinksContainer = SortableContainer(({ links, onDragEnd, onRemove, onEdit }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='linksList'>
        {(provided) => (
          <List {...provided.droppableProps} ref={provided.innerRef} alignItems='center'>
            {links &&
            links.map((link, index) => (
              <LinksSortableItem key={link?.id} {...link} onRemove={onRemove} index={index} onEdit={onEdit} />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
})

const LinksEditor = ({ items, onChange }) => {
  const [modalActive, setModalActive] = useState(false)
  const [newLink, setNewLink] = useState('')
  const [newLinkText, setNewLinkText] = useState('')
  const [currentId, setCurrentId] = useState('')

  const classes = useStyles()

  function onDragEnd (result) {
    if (!result.destination) return

    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    onChange(items)
  }

  function onEdit (id) {
    const item = items.find(entity => entity.id === id)
    setNewLink(item.url)
    setNewLinkText(item.text)
    setCurrentId(id)
    setModalActive(true)
  }

  function onRemove (id) {
    onChange(items.filter(link => link.id !== id))
  }

  return (
    <>
      <LinksContainer
        onDragEnd={onDragEnd}
        links={items}
        onRemove={onRemove}
        onEdit={onEdit}
      />
      <Popup title='Новая ссылка' openPopup={modalActive} setOpenPopup={setModalActive}>
        <TextField
          className={classes.textInput}
          variant='outlined'
          label='Ссылка на график'
          margin='normal'
          value={newLink}
          onChange={e => setNewLink(e.target.value)}
          inputProps={{ 'aria-label': 'Ссылка на график popup' }}
        />
        <TextField
          className={classes.textInput}
          variant='outlined'
          label='Текст ссылки'
          margin='normal'
          value={newLinkText}
          onChange={e => setNewLinkText(e.target.value)}
          inputProps={{ 'aria-label': 'Текст ссылки popup' }}
        />
        <Button
          variant='contained'
          color='primary'
          type='button'
          style={{ display: 'block', margin: '15px auto' }}
          disabled={newLinkText.length <= 0 || newLink.length <= 0}
          onClick={() => {
            onChange(items.map(link => (link.id === currentId ? { ...link, url: newLink, text: newLinkText } : link)))
            setModalActive(false)
            setCurrentId('')
            setNewLink('')
            setNewLinkText('')
          }}
        >
          ОК
        </Button>
      </Popup>
    </>
  )
}

const initialValues = {
  company: '',
  ticker: '',
  currentPrice: '',
  closePrice: '',
  openPrice: '',
  url: '',
  period: {
    from: '',
    to: '',
    type: 'month'
  },
  publicationStatus: false,
  ideaStatus: 'open',
  dealType: 'buy',
  openDate: utcToZonedTime(new Date(), 'Europe/Moscow'),
  comments: [],
  links: [],
  isDatePickerOpen: false,
  isUpdatingError: false
}

async function sendCompanyDes (desc, id) {
  await axios.put(`api/companies/${id}`, {
    description: desc
  })
}

async function getCompanyDesc (id, setState) {
  const { data } = await axios.get(`api/companies/${id}`)
  setState(data.description)
}

const calculateIncomes = ({ currentPrice, closePrice, openPrice, dealType }) => {
  const price = currentPrice || closePrice

  return dealType === 'buy'
    ? ((price - openPrice) * 100) / openPrice
    : (((price - openPrice) * 100) / openPrice) * -1
}

const InvestIdea = () => {
  const {
    params: { id }
  } = useRouteMatch({
    path: '/invest-ideas/:id'
  })
  const deleteComment = canAccess('invest-idea', 'deleteIdeaComment')
  const updateComment = canAccess('invest-idea', 'updateIdeaComment')
  const createComment = canAccess('invest-idea', 'createIdeaComments')

  const classes = useStyles()
  const showMessage = useMessage()
  const history = useHistory()
  const isAdding = id === 'add'

  const [ideaCommentary, setIdeaCommentary] = useState('')
  const [ideaLink, setIdeaLink] = useState('')
  const [ideaLinkText, setIdeaLinkText] = useState('')
  const [searchData, setSearchData] = useState([])
  const [companyName, setCompanyName] = useState('')
  const [companyDesc, setCompanyDesc] = useState('')

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate (values) {
      const { company, url, currentPrice, closePrice, openPrice } = values
      const errors = {}

      if (company.length === 0) {
        errors.company = true
      }
      if (url.length === 0 || !latinSmallAlphabetHypenAndNumbers.test(url)) {
        errors.url = true
      }
      if (!numbersValid.test(currentPrice)) {
        errors.currentPrice = true
      }
      if (!numbersValid.test(closePrice)) {
        errors.closePrice = true
      }
      if (!numbersValid.test(openPrice)) {
        errors.openPrice = true
      }
      return errors
    },
    async onSubmit (values) {
      const {
        company,
        url,
        ticker,
        content,
        publicationStatus,
        openDate,
        currentPrice,
        closePrice,
        openPrice,
        dealType,
        links,
        comments,
        period
      } = values
      const editedDate = zonedTimeToUtc(openDate, 'Europe/Moscow').toISOString()

      if (isAdding) {
        try {
          await axios.post('/api/invest-ideas', {
            company,
            url,
            ticker,
            content,
            publicationStatus: publicationStatus ? 'published' : 'draft',
            ideaStatus,
            openDate: editedDate,
            currentPrice,
            closePrice,
            openPrice,
            dealType,
            links,
            comments,
            period,
            predictableIncome: closePrice && openPrice && dealType === 'buy'
              ? (((closePrice - openPrice) * 100) / openPrice).toFixed(2)
              : ((((closePrice - openPrice) * 100) / openPrice) * -1).toFixed(2)
          })
          showMessage('Идея добавлена', 'success')
          history.push('/invest-ideas')
        } catch (e) {
          if (e.response.data.message.includes('duplicate key error collection')) {
            showMessage('Возможно url не уникален')
          } else {
            showMessage('Ошибка сети')
          }
          console.error(e)
        }
      } else {
        try {
          await axios.put(`/api/invest-ideas/${id}`, {
            company,
            url,
            ticker,
            content,
            publicationStatus: publicationStatus ? 'published' : 'draft',
            ideaStatus,
            openDate: editedDate,
            currentPrice,
            closePrice,
            openPrice,
            dealType,
            links,
            comments,
            period,
            predictableIncome: closePrice && openPrice && dealType === 'buy'
              ? (((closePrice - openPrice) * 100) / openPrice).toFixed(2)
              : ((((closePrice - openPrice) * 100) / openPrice) * -1).toFixed(2)
          })
          showMessage('Идея обновлена', 'success')
          history.push('/invest-ideas')
        } catch (e) {
          if (e.response.data.message.includes('duplicate key error collection')) {
            showMessage('Возможно url не уникален')
          } else {
            showMessage('Ошибка сети')
          }
          console.error(e)
        }
      }
    }
  })

  const {
    values,
    handleSubmit,
    setFieldValue,
    setValues,
    errors: {
      company: companyErr,
      url: urlErr,
      currentPrice: currentPriceErr,
      closePrice: closePriceErr,
      openPrice: openPriceErr
    },
    touched: {
      company: companyTouched,
      url: urlTouched,
      currentPrice: currentPriceTouched,
      closePrice: closePriceTouched,
      openPrice: openPriceTouched
    }
  } = formik

  const {
    company,
    url,
    ticker,
    publicationStatus,
    ideaStatus,
    currentPrice,
    closePrice,
    openPrice,
    dealType,
    openDate,
    isUpdatingError,
    isDatePickerOpen,
    links,
    comments
  } = values

  if (Object.getPrototypeOf(company) === Object.prototype) {
    setFieldValue('company', company.id)
    setFieldValue('ticker', company.ticker)
    setCompanyName(company.name)
  }

  if (comments === undefined) {
    setFieldValue('comments', [])
  }
  if (links === undefined) {
    setFieldValue('links', [])
  }

  const handleDatePick = (date) => {
    setValues({
      ...values,
      openDate: date,
      isDatePickerOpen: false
    })
  }

  const onDatePickerOpen = () => {
    setFieldValue('isDatePickerOpen', !isDatePickerOpen)
  }

  useEffect(async () => {
    if (isAdding && company) {
      setValues(initialValues)
    }
    if (!isAdding) {
      try {
        const { data } = await axios.get(`/api/invest-ideas/${id}`)
        await setValues({
          ...data,
          publicationStatus: data.publicationStatus === 'published',
          openDate: utcToZonedTime(data.openDate, 'Europe/Moscow'),
          isDatePickerOpen: false
        })
      } catch (e) {
        console.error(e)
        setFieldValue('isUpdatingError', true)
        showMessage('Ошибка загрузки идеи')
      }
    }
  }, [id])

  const calculatedPredictedIncome = calculateIncomes({ openPrice, closePrice, dealType })

  const calculatedCurrentIncome = calculateIncomes({ openPrice, currentPrice, dealType })

  useEffect(() => {
    if (company) {
      getCompanyDesc(company, setCompanyDesc)
    }
  }, [company])

  return (
    <>
      <BreadcrumbsComponent
        forceMatched={[
          {
            name: 'Все идеи',
            href: '/invest-ideas'
          },
          {
            name: `Идея по акциям ${companyName}`
          }
        ]}
      />
      <PageHeader>
        <PageTitle>{isAdding ? 'Создание идеи' : 'Обновление идеи'}</PageTitle>
      </PageHeader>
      {!isAdding && !company && !isUpdatingError
        ? <Box position='relative' padding='40px 0 28px 0'> <Preloader /> </Box>
        : null}
      {isUpdatingError
        ? <Box style={{ marginBottom: '10px' }}>
            <Alert className={classes.limitedWidthLg} severity='error'> Ошибка загрузки идеи </Alert>
          </Box>
        : null}
      {
        (!isAdding && company) || isAdding ? (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid container alignItems='center'>
                {
                  isAdding ? (
                    <Grid item xs={6}>
                      <Autocomplete
                        className={classes.limitedWidthLg}
                        freeSolo
                        disableClearable
                        options={searchData}
                        onChange={(event, value, reason) => {
                          if (reason === 'select-option') {
                            const { id: receivedId, name: receivedName, ticker, currentPrice } = value
                            const editedUrl = `${receivedName.replace(' ', '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase()}-${generateId().replace(/[^a-zA-Z0-9]/g, '')}`
                            setCompanyName(receivedName)
                            setValues({
                              ...values,
                              company: receivedId,
                              url: editedUrl,
                              ticker,
                              currentPrice
                            })
                          }
                        }}
                        onInputChange={
                          async (e) => {
                            const { type, target: { value } } = e
                            if (type === 'change' && value) {
                              try {
                                const { data } = await axios.get(`/api/screener/companies/search?query=${value}`)
                                setSearchData(data)
                              } catch (e) {
                                console.error(e)
                                showMessage('Ошибка поиска', 'error', { preventDuplicate: true })
                                setSearchData([])
                              }
                            }
                          }
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) =>
                          <SearchResult
                            key={option.name}
                            img={option.logoUrl}
                            name={option.name}
                            ticker={option.ticker}
                            currentPrice={option.currentPrice}
                          />
                        }
                        filterOptions={(options) => options}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='company'
                            label='Поиск компании'
                            variant='outlined'
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                              onBlur: formik.handleBlur
                            }}
                          />
                        )}
                      />
                    </Grid>
                  ) : null
                }
                {
                  company ? (
                    <Grid item xs={6}>
                      <TextField
                        className={classes.limitedWidthLg}
                        variant='outlined'
                        disabled
                        value={company}
                        label='ID компании'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  ) : null
                }
                {
                  companyErr && companyTouched
                    ? <Alert className={classes.limitedWidthLg} severity='error'>Компания не выбрана</Alert>
                    : null
                }
              </Grid>
              <Grid container alignItems='center' spacing={2}>
                <Grid item xs>
                  <TextFieldMarginStyled
                    variant='outlined'
                    name='url'
                    margin='normal'
                    value={url}
                    error={urlErr && urlTouched}
                    label={urlErr && urlTouched ? 'Только английские буквы, цифры и тире' : 'Название страницы компании'}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                    <KeyboardDatePickerStyled
                      inputVariant='outlined'
                      margin='dense'
                      format='d MMMM yyyy в HH:mm'
                      id='date-picker-inline'
                      name='openDate'
                      label='Дата создания идеи'
                      open={isDatePickerOpen}
                      value={openDate}
                      onBlur={formik.handleBlur}
                      onChange={handleDatePick}
                      onOpen={onDatePickerOpen}
                      onClose={onDatePickerOpen}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={publicationStatus}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name='publicationStatus'
                      />
                    }
                    label={publicationStatus ? 'Опубликована' : 'Не опубликована'}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.inputHolder}>
                    <InputLabel htmlFor='desc' className={classes.textLabel}>Описание компании</InputLabel>
                    <TextField
                      id='desc'
                      className={classes.textInput}
                      variant='outlined'
                      margin='normal'
                      name='desc'
                      label='Описание компании'
                      multiline
                      inputProps={{ className: classes.resize, rowsMin: 10 }}
                      value={companyDesc}
                      onChange={e => setCompanyDesc(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.inputHolder}>
                    <InputLabel htmlFor='content' className={classes.textLabel}>Краткое обоснование</InputLabel>
                    <TextField
                      id='content'
                      className={classes.textInput}
                      variant='outlined'
                      margin='normal'
                      name='content'
                      label='Краткое обоснование'
                      multiline
                      inputProps={{ className: classes.resize, rowsMin: 10 }}
                      required
                      value={formik.values.content}
                      onBlur={formik.handleBlur}
                      onChange={e => formik.setFieldValue('content', e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.inputHolder}>
                    <InputLabel htmlFor='ticker' className={classes.textLabel}>Тикер</InputLabel>
                    <TextField
                      id='ticker'
                      className={classes.textInput}
                      variant='outlined'
                      margin='normal'
                      name='ticker'
                      label='Тикер'
                      required
                      value={formik.values.ticker}
                      onBlur={formik.handleBlur}
                      onChange={e => formik.setFieldValue('ticker', e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.inputHolder}>
                    <InputLabel htmlFor='currentPrice' className={classes.textLabel}>Текущая цена</InputLabel>
                    <TextField
                      data-testid='currentPrice'
                      id='currentPrice'
                      className={classes.textInput}
                      type='text'
                      variant='outlined'
                      margin='normal'
                      name='currentPrice'
                      error={currentPriceErr && currentPriceTouched}
                      label={currentPriceErr && currentPriceTouched ? 'Неверный формат цены' : 'Текущая цена'}
                      required
                      value={formik.values.currentPrice}
                      onBlur={formik.handleBlur}
                      onChange={e => formik.setFieldValue('currentPrice', e.target.value.trim().replace(/,/g, '.'))}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className={classes.inputHolder}>
                    <InputLabel className={classes.textLabel}>Тип сделки</InputLabel>
                    <Select
                      className={classes.textInput}
                      variant='outlined'
                      name='dealType '
                      defaultValue='buy'
                      required
                      value={formik.values.dealType}
                      onChange={e => formik.setFieldValue('dealType', e.target.value)}
                    >
                      <MenuItem value='buy'>Покупка</MenuItem>
                      <MenuItem value='sell'>Продажа</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.inputHolder}>
                    <InputLabel className={classes.textLabel}>Прогнозируемая доходность</InputLabel>
                    <Box className={classes.inputHolder}>
                      <Box>
                        {
                          isNaN(calculatedPredictedIncome)
                            ? <Alert className={classes.limitedWidthMd} severity='warning'>
                                Для отображения прогнозируемой доходности введите больше данных
                              </Alert>
                            : <Typography> Прогнозируемая доходность: {calculatedPredictedIncome} </Typography>
                        }
                      </Box>
                      <Box style={{ marginTop: '10px' }}>
                        {
                          isNaN(calculatedCurrentIncome)
                            ? <Alert className={classes.limitedWidthMd} severity='warning'>
                                Для отображения текущей доходности введите больше данных
                              </Alert>
                            : <Typography> Текущая доходность: {calculatedCurrentIncome} </Typography>
                        }
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.inputHolder}>
                    <InputLabel htmlFor='openPrice' className={classes.textLabel}>Цена открытия</InputLabel>
                    <TextField
                      id='openPrice'
                      className={classes.textInput}
                      variant='outlined'
                      margin='normal'
                      name='openPrice'
                      error={openPriceErr && openPriceTouched}
                      label={openPriceErr && openPriceTouched ? 'Неверный формат цены' : 'Цена открытия'}
                      required
                      value={formik.values.openPrice}
                      onBlur={formik.handleBlur}
                      onChange={e => formik.setFieldValue('openPrice', e.target.value.trim().replace(/,/g, '.'))}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.inputHolder}>
                    <InputLabel htmlFor='closePrice' className={classes.textLabel}>Цена закрытия</InputLabel>
                    <TextField
                      id='closePrice'
                      className={classes.textInput}
                      variant='outlined'
                      margin='normal'
                      name='closePrice'
                      error={closePriceErr && closePriceTouched}
                      label={closePriceErr && closePriceTouched ? 'Неверный формат цены' : 'Цена закрытия'}
                      required
                      value={formik.values.closePrice}
                      onBlur={formik.handleBlur}
                      onChange={e => formik.setFieldValue('closePrice', e.target.value.trim().replace(/,/g, '.'))}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <Box className={classes.inputHolder}>
                      <InputLabel className={classes.textLabel}>Срок инвестирования</InputLabel>
                      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <TextField
                          className={classes.textInput}
                          style={{ marginRight: '10px' }}
                          type='number'
                          variant='outlined'
                          name='period.from'
                          placeholder='от'
                          required
                          value={formik.values?.period?.from}
                          onBlur={formik.handleBlur}
                          onChange={e => formik.setFieldValue('period.from', e.target.value)}
                        />
                        <TextField
                          className={classes.textInput}
                          style={{ marginRight: '10px' }}
                          type='number'
                          variant='outlined'
                          name='period.to'
                          placeholder='до'
                          required
                          value={formik.values?.period?.to}
                          onBlur={formik.handleBlur}
                          onChange={e => formik.setFieldValue('period.to', e.target.value)}
                        />
                        <Select
                          className={classes.textInput}
                          variant='outlined'
                          defaultValue='month'
                          value={formik.values?.period?.type}
                          onChange={e => formik.setFieldValue('period.type', e.target.value)}
                        >
                          <MenuItem value='month'>Месяцев</MenuItem>
                          <MenuItem value='week'>Недель</MenuItem>
                        </Select>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.inputHolder}>
                    <InputLabel className={classes.textLabel}>Ссылки на графики</InputLabel>
                    <TextField
                      className={classes.textInput}
                      variant='outlined'
                      margin='normal'
                      name='linkUrl'
                      label='Ссылка на график'
                      value={ideaLink}
                      onChange={e => setIdeaLink(e.target.value)}
                      inputProps={{ 'aria-label': 'Ссылка на график' }}
                    />
                    <TextField
                      className={classes.textInput}
                      variant='outlined'
                      margin='normal'
                      name='linkText'
                      label='Текст ссылки'
                      value={ideaLinkText}
                      onChange={e => setIdeaLinkText(e.target.value)}
                      inputProps={{ 'aria-label': 'Текст ссылки' }}
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={ideaLinkText.length <= 0 || ideaLink.length <= 0}
                      type='button'
                      onClick={() => {
                        formik.setFieldValue('links', [...formik.values?.links,
                          { text: ideaLinkText, url: ideaLink, id: generateId() }])
                        setIdeaLink('')
                        setIdeaLinkText('')
                      }}
                    >
                      Добавить ссылку
                    </Button>
                    {formik.values?.links && formik.values.links.length > 0 && (
                      <LinksEditor
                        items={formik.values.links}
                        onChange={newResult => formik.setFieldValue('links', newResult)}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.inputHolder}>
                    <InputLabel htmlFor='comments' className={classes.textLabel}>
                      Комментарии аналитиков
                    </InputLabel>
                    {createComment && (
                      <>
                        <TextField
                          id='comments'
                          className={classes.textInput}
                          variant='outlined'
                          label='Комментарий'
                          margin='normal'
                          name='comments'
                          multiline
                          inputProps={{ className: classes.resize, rowsMin: 10 }}
                          value={ideaCommentary}
                          onChange={e => setIdeaCommentary(e.target.value)}
                        />
                        <Button
                          variant='contained'
                          color='primary'
                          type='button'
                          disabled={ideaCommentary.length <= 0 || !createComment}
                          onClick={() => {
                            formik.setFieldValue('comments', [...formik.values?.comments,
                              { text: ideaCommentary, id: generateId(), date: Date.now() }])
                            setIdeaCommentary('')
                          }}
                        >
                          Добавить комментарий
                        </Button>
                      </>
                    )}
                    <Grid item xs={6}>
                      {formik.values?.comments && formik.values.comments.length > 0 && (
                        <CommentsEditor
                          items={formik.values.comments}
                          onChange={newComments => formik.setFieldValue('comments', newComments)}
                          canDelete={deleteComment}
                          canUpdate={updateComment}
                        />
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Box style={{ margin: '30px 0' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={!company || !url || !ticker || !currentPrice || !closePrice || !openPrice}
                    onClick={() => sendCompanyDes(companyDesc, company)}
                  >
                    {isAdding ? 'Создать' : 'Сохранить'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        ) : null
      }
    </>
  )
}

export default InvestIdea
