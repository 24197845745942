import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  TextField,
  Fade,
  Fab,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Edit, Close } from '@material-ui/icons'
import { useFormik } from 'formik'

// other
import axios from '../../helpers/axios'
import { alphabetHypenSpaceAnd } from '../../helpers/regexp'
import useMessage from '../../hooks/useMessage'

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameTranslation: PropTypes.string,
  setIndustry: PropTypes.func.isRequired,
  last: PropTypes.bool.isRequired,
  canUpdate: PropTypes.bool.isRequired
}

const defaultProps = {
  nameTranslation: ''
}

const useStyles = makeStyles((theme) => ({
  typography: {
    display: 'inline'
  },
  typographyButton: {
    display: 'inline',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  textField: {
    width: '300px',
    margin: '0 10px 0 0'
  }
}))

const IndustryCard = ({
  id,
  name,
  nameTranslation: propsNameTranslation,
  last,
  canUpdate,
  setIndustry
}) => {
  const classes = useStyles()
  const showMessage = useMessage()

  const formik = useFormik({
    initialValues: {
      editing: false,
      nameTranslation: propsNameTranslation,
      newNameTranslation: propsNameTranslation
    },
    validate (values) {
      const { newNameTranslation } = values
      const errors = {}

      if (!alphabetHypenSpaceAnd.test(newNameTranslation)) {
        errors.newNameTranslation = true
      }

      return errors
    },
    async onSubmit (values) {
      const { newNameTranslation } = values

      try {
        const { data } = await axios.put(`/api/industry/${id}`, {
          nameTranslation: newNameTranslation
        })

        const { nameTranslation: receivedNameTranslation } = data

        await formik.setValues({
          ...values,
          nameTranslation: receivedNameTranslation,
          newNameTranslation: receivedNameTranslation
        })

        setIndustry(data)

        showMessage('Перевод сохранен успешно', 'success')
      } catch (e) {
        console.error(e)
        showMessage('Ошибка сохранения перевода')
      }
    }
  })

  const {
    values,
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    errors: {
      newNameTranslation: newNameTranslationErr
    },
    touched: {
      newNameTranslation: newNameTranslationTouched
    }
  } = formik

  const {
    editing,
    nameTranslation,
    newNameTranslation
  } = values

  const onChangeClick = async () => {
    if (!editing) {
      setFieldValue('editing', true)
    } else {
      setFieldValue('editing', false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        padding='5px 10px'
        minHeight={canUpdate ? '57px' : 'auto'}
        height='auto'
        border={1}
        borderColor='primary.main'
        borderRadius='borderRadius'
        {... last ? {} : { borderBottom: '0' }}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          flexWrap='wrap'
          flex='1 1 100%'
          marginRight='10px'
        >
          <Box
            flex='0 1 auto'
            marginRight='20px'
          >
            <Typography variant='body1'>{name}</Typography>
          </Box>
          <Box
            flex='1 1 auto'
          >
            <Fade in={!editing}>
              <Box
                overflow='hidden'
                height={!editing ? 'auto' : '0px'}
                color={nameTranslation ? 'unset' : 'primary.main'}
                display={canUpdate ? 'block' : 'none'}
              >
                <Typography
                  variant='body1'
                  className={nameTranslation ? classes.typography : classes.typographyButton}
                  onClick={nameTranslation ? null : onChangeClick}
                >
                  {nameTranslation ? `Перевод: ${nameTranslation}` : 'Перевод отсутствует'}
                </Typography>
              </Box>
            </Fade>
            <Fade in={editing}>
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                overflow='hidden'
                height={editing ? 'auto' : '0px'}
              >
                <TextField
                  name='newNameTranslation'
                  margin='dense'
                  className={classes.textField}
                  value={newNameTranslation}
                  error={newNameTranslationErr && newNameTranslationTouched}
                  label={newNameTranslationErr && newNameTranslationTouched ? 'Только буквы, пробелы, тире и &' : 'Перевод названия'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={!(nameTranslation !== newNameTranslation)}
                  type='submit'
                >
                  Сохранить
                </Button>
              </Box>
            </Fade>
          </Box>
        </Box>
        {
          canUpdate ? (
            <Fab size='small' color='primary' aria-label='add' onClick={onChangeClick}>
              {editing ? <Close /> : <Edit />}
            </Fab>
          ) : null
        }
      </Box>
    </form>
  )
}

IndustryCard.propTypes = propTypes
IndustryCard.defaultProps = defaultProps

export default IndustryCard
