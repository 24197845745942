import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Paper, Box, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import { useDebounce } from 'use-debounce'
import useSWR from 'swr'

import axios from '../../../../helpers/axios'
import useMessage from '../../../../hooks/useMessage'
import Preloader from '../../../Preloader'
import HorizontalList from '../../../HorizontalList'
import SortButton from '../SortButton'

const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`
const StyledCircular = styled(CircularProgress)`
  position: absolute;
  top: 55%;
  right: 11.5%;
  margin: -20px 0 0 -20px;
`
const SearchWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

function limitItems (data, sorter) {
  switch (sorter) {
    case 'highToLow':
      return data.sort((a, b) => a.companies - b.companies)
    case 'lowToHigh':
      return data.sort((a, b) => b.companies - a.companies)
    default:
      return data
  }
}

async function getSearchResults (name, setIsFetching, setNotFound) {
  if (!name) return []
  setIsFetching(true)

  try {
    const { data: industry } = await axios.get(`/api/screener/stats/companies/industries?name=${name}`)
    setIsFetching(false)
    if (industry.length) {
      setNotFound(false)
      return limitItems(industry)
    } else {
      setNotFound(true)
      return null
    }
  } catch (e) {
    setNotFound(true)
    setIsFetching(false)
    return null
  }
}

const CompaniesIndustryTable = () => {
  const showMessage = useMessage()
  const [companiesIndustry, setCompaniesIndustry] = useState()
  const [sorter, setSorter] = useState('')

  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch] = useDebounce(searchValue, 500)
  const [isFetching, setIsFetching] = useState(false)
  const [notFound, setNotFound] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)

  const resultPage = useMemo(() => (currentPage * 10) - 10, [currentPage])

  const { data: initialIndustriesList = [] } = useSWR(`/industries?skip=${resultPage}`, () => axios.get(`/api/screener/stats/companies/industries?limit=10&skip=${resultPage}`).then(industries => industries.data))
  const { data: industriesCount = 0, mutate: mutateCount } = useSWR('/industries/count', () => axios.get('/api/screener/stats/companies/industries/count').then(count => count.data))
  const { data: suggestions = [] } = useSWR(() =>
    debouncedSearch !== undefined && searchValue === debouncedSearch
      ? ['/industry', debouncedSearch]
      : null, () => getSearchResults(debouncedSearch, setIsFetching, setNotFound))

  const industriesList = useMemo(() => limitItems(initialIndustriesList, sorter), [initialIndustriesList, sorter])

  useEffect(() => {
    if (suggestions?.length) {
      setCompaniesIndustry(suggestions)
      mutateCount(suggestions.length)
    }
  }, [suggestions?.length])

  useEffect(() => {
    if (notFound) {
      showMessage('Ничего не найдено')
    }
  }, [notFound])

  function changePage (newPage) {
    setCurrentPage(newPage)
  }

  function sorterHandler () {
    sorter === '' || sorter === 'highToLow' ? setSorter('lowToHigh') : setSorter('highToLow')
  }

  return (
    <>
      <Paper>
        <Box>
          {industriesList &&
            <SearchWrap>
              {isFetching ? <StyledCircular size={30} /> : null}
              <SortButton sorter={sorter} sorterHandler={sorterHandler} />
              <Autocomplete
                id='combo-box'
                freeSolo
                disabled={!initialIndustriesList.length}
                inputValue={searchValue}
                onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
                options={companiesIndustry || []}
                getOptionLabel={option => option?.name}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label='Поиск по названию отрасли' variant='outlined' />}
              />
            </SearchWrap>}
          {!initialIndustriesList.length ? <Box position='relative' paddingTop='100px'><Preloader /></Box> : null}

          {industriesList && industriesList.map(item =>
            <HorizontalList
              withAnimation
              nameHeader='Название'
              textHeader='Количество компаний'
              subtextHeader='Перевод'
              key={item.name}
              name={item.name}
              text={item.companies}
              subtext={item.translate || 'Нет'}
            />
          )}
        </Box>
      </Paper>
      {industriesCount > 10
        ? <PaginationWrap>
            <Pagination
              count={Math.ceil(industriesCount / 10)}
              page={currentPage}
              onChange={(e, value) => changePage(value)}
            />
          </PaginationWrap>
        : ''}
    </>
  )
}

export default CompaniesIndustryTable
