import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

// blocks
import Filter from '../../blocks/Filter'
import AddFilter from '../../blocks/AddFilter'
import ActiveFilters from '../../blocks/ActiveFilters'

// components
import PageTitle from '../../components/PageTitle'
import ParagraphTitle from '../../components/ParagraphTitle'
import Preloader from '../../components/Preloader'
import ConfirmDialog from '../../components/ConfirmDialog'
import BreadcrumbsComponent from '../../components/Breadcrumbs'

// helpers
import axios, { useAuthSWR } from '../../helpers/axios'
import canAccess from '../../helpers/canAccess'

// hooks
import useMessage from '../../hooks/useMessage'

const AlertStyled = styled(Alert)`
  width: 590px;
`
const TypographyStyled = styled(Typography)`
  margin-bottom: 10px;
`
const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const Filters = () => {
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false })
  const [isCreateFilterMode, setIsCreateFilterMode] = useState(false)

  const showMessage = useMessage()
  const createFilter = canAccess('filters', 'create')
  const readFilter = canAccess('filters', 'read')
  const updateFilter = canAccess('filters', 'update')
  const deleteFilter = canAccess('filters', 'delete')

  const { data: filters, mutate: updateFilters } = useAuthSWR('/api/filters', { revalidateOnFocus: false, revalidateOnReconnect: false })
  const { data: indexes } = useAuthSWR(readFilter ? '/api/stock-indexes/available' : null)
  const { data: allRoles } = useAuthSWR('/api/roles')

  /* Добавляем фильтр */
  const onAddFilter = (newFilter) => {
    updateFilters([...filters, newFilter], false)
    setIsCreateFilterMode(false)
  }

  /* Меняем фильтр */
  const onChangeFilter = (newFilter) => {
    const { id: newFilterId } = newFilter

    const newFilters = filters.map((filter) => {
      const { id } = filter

      if (newFilterId === id) {
        return newFilter
      } else {
        return filter
      }
    })

    updateFilters(newFilters)
  }
  return (
    <>
      <BreadcrumbsComponent />
      <PageHeaderContainer>
        <PageTitle>Фильтры</PageTitle>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setIsCreateFilterMode(!isCreateFilterMode)}
        >
          {isCreateFilterMode ? 'скрыть создание фильтра' : 'создать фильтр'}
        </Button>
      </PageHeaderContainer>
      <Box
        marginBottom='20px'
      >
        {isCreateFilterMode &&
          <Box
            padding='0 0 20px'
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
          >
            <Grid container spacing={2}>
              <Grid item xs>
                {
                  createFilter
                    ? <AddFilter key={Math.random()} indexes={indexes} onAddFilter={onAddFilter} allRoles={allRoles} />
                    : <AlertStyled severity='warning'>Нет разрешения на добавление фильтра</AlertStyled>
                }
              </Grid>
            </Grid>
          </Box>}
        <Grid item xs>
          {
            createFilter && filters
              ? <ActiveFilters filters={filters} />
              : null
          }
        </Grid>
        <ParagraphTitle>Все фильтры</ParagraphTitle>
        {!filters && <Box position='relative' paddingTop='40px'><Preloader /></Box>}
        {filters && filters.length === 0 ? (<Box color='warning.main'><TypographyStyled variant='h5'>Список фильтров пуст</TypographyStyled></Box>) : null}
        {filters && filters.length
          ? filters.map((filter) => {
              const { id, displayName, exchangeCodes, roles, stockIndex, country } = filter

              const onDeleteFilter = async () => {
                setConfirmDialog({
                  ...confirmDialog,
                  isOpen: false
                })
                try {
                  const { data } = await axios.delete(`/api/filters/${id}`)
                  if (data) {
                    updateFilters()
                    const newFilters = filters.filter((filter) => filter.id !== id)
                    updateFilters(newFilters, false)
                    showMessage('Фильтр успешно удален', 'success')
                  }
                } catch (e) {
                  console.error(e)
                  showMessage('Ошибка удаления фильтра')
                }
              }

              return (
                <Filter
                  key={id}
                  id={id}
                  name={displayName}
                  exchangeCodes={exchangeCodes}
                  index={stockIndex}
                  country={country}
                  indexes={indexes}
                  roles={roles}
                  allRoles={allRoles}
                  onDeleteFilter={deleteFilter ? onDeleteFilter : null}
                  onChangeFilter={updateFilter ? onChangeFilter : null}
                  setConfirmDialog={setConfirmDialog}
                />
              )
            })
          : null}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </Box>
    </>
  )
}

export default Filters
