import React from 'react'
import { IconButton } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { Clear } from '@material-ui/icons'

/* type: default, success, error, warning, info */
const useMessage = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const action = key => (
    <IconButton aria-label='delete' size='small' onClick={() => { closeSnackbar(key) }}>
      <Clear style={{ color: '#ffffff' }} />
    </IconButton>
  )

  const showMessage = (message = 'Ошибка', type = 'error', options = {}) => {
    enqueueSnackbar(message, {
      variant: type,
      action,
      autoHideDuration: 3000,
      ...options
    })
  }

  return showMessage
}

export default useMessage
