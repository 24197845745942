import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { format, formatDistance } from 'date-fns'
import ruLocale from 'date-fns/locale/ru'
import {
  makeStyles,
  Box,
  Typography,
  Paper,
  Grid
} from '@material-ui/core'

const propTypes = {
  isLoading: PropTypes.bool,
  sync: PropTypes.object.isRequired
}

const useStyles = makeStyles(theme => ({
  statisticWrapper: {
    position: 'relative',
    minHeight: '100px',
    marginTop: '40px',
    padding: '20px',
    border: '1px solid lightgray',
    borderRadius: '7px'
  },
  textHeader: {
    textTransform: 'uppercase',
    fontWeight: 700
  }
}))

const Text = styled.span`
  margin-left: 5px;
`

function durationTimeInWords (seconds) {
  return formatDistance(0, seconds * 1000, { includeSeconds: true, locale: ruLocale })
}

function syncBeginDate (date) {
  return format(new Date(date), 'dd.MM.yyyy HH:mm')
}

const Statistic = ({ isLoading, sync }) => {
  const classes = useStyles()

  return (

    <Paper className={classes.statisticWrapper} elevation={2}>
      <Box mb={2}>
        <Typography className={classes.textHeader}>
          {isLoading ? 'Статистика текущей синхронизации' : 'Статистика последней синхронизации'}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {sync?.syncEndDate
          ? (<>
            <Grid item xs={6}>
              <Typography>
                Статус:
                <Text> {sync.status === 'success' ? 'Успех' : 'Ошибка'} </Text>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                Время синхронизации:
                <Text> {syncBeginDate(sync.syncEndDate)} </Text>
              </Typography>
            </Grid>
            {sync.failReason
              ? (
                <Grid item xs={6}>
                  <Typography>
                    Ошибка:
                    <Text> {sync.failReason} </Text>
                  </Typography>
                </Grid>
                )
              : null}
          </>)
          : null}
        <Grid item xs={6}>
          <Typography>
            Общее количество компаний:
            <Text> {sync?.companiesToSyncCount} </Text>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isLoading ? 'Оставшиеся компании:' : 'Успешно синхронизированные компании:'}
            <Text> {isLoading ? sync?.companiesToSyncRemaining : sync?.syncedCompaniesCount} </Text>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Количество ошибок:
            <Text> {sync?.syncErrorsCount} </Text>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isLoading ? 'Время начала синхронизации:' : 'Продолжительность синхронизации:'}
            <Text>
              {
                isLoading
                  ? (sync?.syncStartDate && (syncBeginDate(sync?.syncStartDate)))
                  : (sync?.duration && durationTimeInWords(sync?.duration))
              }
            </Text>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

Statistic.propTypes = propTypes

export default React.memo(Statistic)
