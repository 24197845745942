import React from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@material-ui/core'

const propTypes = {
  size: PropTypes.number
}

const Preloader = ({ size }) => {
  return (
    <Box
      position='absolute'
      top='50%'
      left='50%'
      margin='-20px 0 0 -20px'
    >
      <CircularProgress size={size} />
    </Box>
  )
}

Preloader.propTypes = propTypes

export default Preloader
