import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Button, Grid, TextField, CircularProgress } from '@material-ui/core'
import { format } from 'date-fns'
import axios from '../../helpers/axios'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import Errors from '../../components/Explorer/syncErrors/Errors'
import Calendar from '../../components/Explorer/syncErrors/Calendar'
import Preloader from '../../components/Preloader'
import { motion } from 'framer-motion'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`
const Input = styled(TextField)`
  margin-left: 10px;
  max-width: 60px;
  text-align: center;
`
const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`

const SyncErrors = () => {
  const [errors, setErrors] = useState('')
  const [loadingCount, setLoadingCount] = useState(3)
  const [chosenDate, setChosenDate] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  function formatedDate (date) {
    return format(new Date(date), 'yyyy-MM-dd')
  }

  async function getErrors () {
    try {
      setIsFetching(true)
      const { data } = loadingCount <= 3
        ? await axios.get('/api/stock-sync/cms/last')
        : await axios.get(`/api/stock-sync/cms/last?limit=${loadingCount}`)
      setIsFetching(false)
      return data.reverse()
    } catch (e) {
      setIsFetching(false)
      console.error(e)
      return []
    }
  }

  useEffect(async () => {
    if (chosenDate) {
      const fromDate = formatedDate(chosenDate)
      const toDate = `${formatedDate(chosenDate)}T23:59:59`
      const { data } = await axios.get(`/api/stock-sync/cms/calendar?from=${fromDate}&to=${toDate}`)
      setErrors(data)
    } else {
      getErrors().then(errors => setErrors(errors))
    }
  }, [chosenDate])

  useEffect(() => {
    if (chosenDate) {
      setLoadingCount('')
    }
  }, [chosenDate])

  return (
    <>
      <BreadcrumbsComponent />
      <PageHeader>
        <PageTitle>Ошибки синхронизации</PageTitle>
      </PageHeader>
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.4, delay: 0.3 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={7}>
            {!errors
              ? <Box position='relative' paddingTop='100px'><Preloader /></Box>
              : <>
                  <Errors errors={errors} />
                  <ButtonHolder>
                    {isFetching
                      ? <Box position='relative' marginRight='20px'> <CircularProgress size={30} /> </Box>
                      : <Button color='primary' variant='contained' disabled={!loadingCount} onClick={() => getErrors().then(errors => setErrors(errors))}> Показать еще </Button>
                    }
                    <Input
                      variant='outlined'
                      inputProps={{
                        style: {
                          padding: 8,
                          textAlign: 'center'
                        }
                      }}
                      placeholder='3'
                      value={loadingCount}
                      onChange={e => setLoadingCount(e.target.value)}
                    />
                  </ButtonHolder>
                </>
            }
          </Grid>
          <Grid item xs={3}>
            <Calendar chosenDate={chosenDate} setChosenDate={setChosenDate} />
          </Grid>
        </Grid>
      </motion.div>
    </>
  )
}

export default SyncErrors
