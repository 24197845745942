const routes = [
  {
    name: 'Прогнозы',
    icon: 'EventNote',
    subject: 'forecast',
    visibility: false,
    routes: [
      {
        name: 'Создание прогноза',
        action: 'getRecent',
        icon: 'FormatIndentIncrease',
        href: '/forecasts/add',
        page: 'forecasts-add',
        visibility: false
      },
      {
        name: 'Все прогнозы',
        icon: 'FormatAlignJustify',
        action: 'getRecent',
        actionDescription: 'Получить последние прогнозы',
        href: '/forecasts',
        page: 'forecasts',
        visibility: false
      }
    ]
  },
  {
    name: 'Статьи',
    icon: 'EventNote',
    subject: 'education',
    visibility: false,
    routes: [
      {
        name: 'Создание статьи',
        action: 'create',
        icon: 'FormatIndentIncrease',
        href: '/education/add',
        page: 'education',
        visibility: false
      },
      {
        name: 'Все статьи',
        icon: 'FormatAlignJustify',
        action: 'getLik',
        actionDescription: 'Получить все статьи',
        href: '/educations',
        page: 'educations',
        visibility: false
      }
    ]
  },
  {
    name: 'Идеи',
    icon: 'WbIncandescent',
    subject: 'invest-idea',
    visibility: false,
    routes: [
      {
        name: 'Создание идеи',
        action: 'create',
        icon: 'PlaylistAdd',
        href: '/invest-ideas/add',
        page: 'invest-ideas-add',
        visibility: false
      },
      {
        name: 'Все идеи',
        action: 'read',
        icon: 'Subject',
        href: '/invest-ideas',
        page: 'invest-ideas',
        visibility: false
      }
    ]
  },
  // {
  //   name: 'Отрасли',
  //   icon: 'Grain',
  //   href: '/industry',
  //   page: 'industry',
  //   subjects: ['industry', 'industry-name'],
  //   exclude: ['industry-name'],
  // },
  {
    name: 'Роли',
    subject: 'role',
    icon: 'AssignmentInd',
    href: '/roles',
    page: 'roles',
    visibility: false
  },
  {
    name: 'Компании',
    subject: 'company',
    icon: 'Business',
    href: '/companies',
    page: 'companies',
    visibility: false,
    singlePage: ['companies', 'company']
  },
  {
    name: 'Скринер',
    subject: 'screener',
    icon: 'Storage',
    visibility: false,
    routes: [
      {
        name: 'X - Рейтинг',
        action: 'makeCustomCalculation',
        icon: 'Functions',
        href: '/screener/x-formula',
        page: 'x-formula',
        visibility: false
      },
      {
        name: 'Список наблюдения',
        action: 'getWatchlist',
        icon: 'AddToQueue',
        href: '/screener/watchlist',
        page: 'watchlist',
        visibility: false
      },
      {
        name: 'Фильтры',
        action: 'read',
        icon: 'Sort',
        href: '/screener/filters',
        page: 'filters',
        visibility: false,
        exception: 'filters'
      }
    ]
  },
  {
    name: 'Explorer',
    icon: 'Explore',
    subject: 'explorer',
    visibility: false,
    routes: [
      {
        name: 'Explorer',
        icon: 'Equalizer',
        href: '/explorer/explorer',
        page: 'explorer',
        visibility: false
      },
      {
        name: 'Статистика синхронизации',
        icon: 'Sync',
        href: '/explorer/sync-statistic',
        page: 'sync-statistic',
        visibility: false
      },
      {
        name: 'Ошибки синхронизации',
        icon: 'SyncDisabled',
        href: '/explorer/sync-errors',
        page: 'sync-errors',
        visibility: false
      },
      {
        name: 'Статистика скринера',
        icon: 'BarChart',
        href: '/explorer/screener-statistic',
        page: 'screener-statistic',
        visibility: false
      }
    ]
  },
  {
    name: 'Переводы',
    icon: 'Translate',
    visibility: false,
    subject: 'translate',
    routes: [
      {
        name: 'Перевод поля "Отрасль"',
        icon: 'CreateOutlined',
        href: '/translate/industries',
        page: 'translateIndustries',
        visibility: false
      },
      {
        name: 'Перевод поля "Название компании"',
        icon: 'CreateOutlined',
        href: '/translate/company-name',
        page: 'translateCompanyName',
        visibility: false
      }
    ]
  },
  {
    name: 'Пользователи',
    subject: 'user',
    icon: 'Group',
    visibility: false,
    routes: [
      {
        name: 'Новый пользователь',
        action: 'registerCmsUser',
        icon: 'GroupAdd',
        href: '/users/add',
        page: 'users-add',
        visibility: false
      },
      {
        name: 'Все пользователи',
        action: 'getUsersList',
        icon: 'SupervisorAccount',
        href: '/users',
        page: 'users',
        visibility: false
      }
    ]
  },
  {
    name: 'Тарифы',
    subject: 'tariff',
    icon: 'MonetizationOn',
    visibility: false,
    routes: [
      {
        name: 'Новый тариф',
        action: 'create',
        icon: 'Add',
        href: '/tariffs/add',
        page: 'tariffs-add',
        visibility: false
      },
      {
        name: 'Все тарифы',
        action: 'read',
        icon: 'ClearAll',
        href: '/tariffs',
        page: 'tariffs',
        visibility: false
      }
    ]
  }
]

export default routes
