/* Only latin small symbols */
export const latinSymbols = /^[a-z]+$/
/* latin, cyrilic, hyphens and spaces */
export const alphabetHypensSpaces = /^[a-zA-Zа-яА-Я\s-ё]*$/
/* latin, cyrilic, hyphens, spaces and & */
export const alphabetHypenSpaceAnd = /^[a-zA-Zа-яА-Я\s-&ё]*$/
/* latin, hyphens, and & */
export const latinSmallAlphabetHypenAndNumbers = /^[a-z\d-]*$/
/* email */
export const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
/* Only number and [.] */
export const numbersValid = /^[0-9]*[.]?[0-9]+?$/
