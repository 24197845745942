import React, { useEffect } from 'react'
import axios from '../../helpers/axios'
import { create, all } from 'mathjs'
import { TextField, Box, Button } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import PageTitle from '../../components/PageTitle'
import { useFormik } from 'formik'

// hooks
import useMessage from '../../hooks/useMessage'
import canAccess from '../../helpers/canAccess'

// other
import columns, { companyRows, industryRows } from '../../assets/static/xFormula'
import BreadcrumbsComponent from '../../components/Breadcrumbs'

/* Создание math */
const math = create(all)
const parser = math.parser()
const mathVars = {}

/* Создание переменных в math */
companyRows.concat(industryRows).forEach((item, i) => {
  mathVars[item.name] = 0
})

/* Добавление переменных в math */
math.import(mathVars)

const XFormula = () => {
  const update = canAccess('screener', 'update')
  const showMessage = useMessage()

  const formik = useFormik({
    initialValues: {
      formula: ''
    },
    validate (values) {
      const { formula } = values
      const errors = {}

      if (formula.length === 0) {
        errors.formula = true
      }

      try {
        parser.evaluate(formula)
      } catch (e) {
        errors.formula = true
      }

      return errors
    },
    async onSubmit (values) {
      const { formula } = values

      try {
        await axios.put('/api/screener', { xRatingFormula: formula })

        showMessage('Формула сохранена', 'success')
      } catch (e) {
        console.error(e)
        showMessage('Ошибка сохранения')
      }
    }
  })

  const {
    values: { formula },
    errors: {
      formula: formulaErr
    },
    touched: {
      formula: formulaTouched
    }
  } = formik

  useEffect(async () => {
    try {
      const { data } = await axios.get('/api/screener')
      const { xRatingFormula } = data[0]

      await formik.setFieldValue('formula', xRatingFormula)
    } catch (e) {
      console.error(e)
      showMessage('Ошибка загрузки формулы')
    }
  }, [])

  return (
    <>
      <BreadcrumbsComponent />
      <PageTitle>X - Рейтинг</PageTitle>
      <Box marginBottom='10px'>
        <p style={{ fontSize: '30px' }}>Формула:</p>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box marginBottom='30px'>
          <TextField
            id='standard-basic'
            name='formula'
            disabled={!update}
            value={formula}
            error={formulaErr && formulaTouched}
            label={formulaErr && formulaTouched ? 'error' : 'valid'}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            fullWidth
            multiline
            inputProps={{ 'aria-label': 'Формула' }}
          />
        </Box>
        <Box marginBottom='30px'>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            disabled={(formulaErr && formulaTouched) || !update}
          >
            Сохранить формулу
          </Button>
        </Box>
      </form>
      <Box marginBottom='10px'>
        <p style={{ fontSize: '30px' }}>Переменные компании:</p>
      </Box>
      <Box height='358px' marginBottom='20px'>
        <DataGrid
          rows={companyRows}
          columns={columns}
          pageSize={10}
          rowHeight={30}
          checkboxSelection={false}
          disableSelectionOnClick
          hideFooter
        />
      </Box>
      <Box marginBottom='10px'>
        <p style={{ fontSize: '30px' }}>Переменные отрасли:</p>
      </Box>
      <Box height='328px' marginBottom='10px'>
        <DataGrid
          rows={industryRows}
          columns={columns}
          pageSize={10}
          rowHeight={30}
          checkboxSelection={false}
          disableSelectionOnClick
          hideFooter
        />
      </Box>
    </>
  )
}

export default XFormula
