import React, { useState, useEffect } from 'react'
import axios from '../../helpers/axios'
import styled from 'styled-components'
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { DataGrid } from '@material-ui/data-grid'
import { Autocomplete, Alert } from '@material-ui/lab'

import PageTitle from '../../components/PageTitle'
import Preloader from '../../components/Preloader'
import SearchResult from '../../components/SearchResult'
import TypeOfDeal from '../../components/TypeOfDeal'
import EntryPrice from '../../components/EntryPrice'
import AddCompany from '../../components/AddCompany'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import ConfirmDialog from '../../components/ConfirmDialog'

import useMessage from '../../hooks/useMessage'
import canAccess from '../../helpers/canAccess'

const DialogContentStyled = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`
const AlertStyled = styled(Alert)`
  width: 590px;
`
const AutocompleteStyled = styled(Autocomplete)`
  width: 590px;
  margin: 0;
`
const IconButtonStyled = styled(IconButton)`
  padding: 9px;
`
const TextFieldStyled = styled(TextField)`
  width: 100%;
`
const ButtonStyled = styled(Button)`
  width: 110px;
`

const createCompaniesData = (data) => data.map((company, i) => ({
  id: i,
  companyId: company?.id,
  logoUrl: company?.logoUrl ?? '',
  name: company?.name ?? '',
  ticker: company?.ticker ?? '',
  estimatedTypeOfDeal: company?.estimatedTypeOfDeal,
  estimatedPrice: company?.estimatedPrice ?? null
}))

const Watchlist = () => {
  const addToWatchlist = canAccess('screener', 'addCompanyToWatchlist')
  const deleteFromWatchlist = canAccess('screener', 'deleteCompanyFromWatchlist')
  const updateCompany = canAccess('company', 'update')

  const [observationData, setObservationData] = useState()
  const [companyToAdd, setCompanyToAdd] = useState({})
  const [searchData, setSearchData] = useState([])
  const [dialogData, setDialogData] = useState({})
  const [curEstimatedPrice, setCurEstimatedPrice] = useState('')
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false })
  const showMessage = useMessage()
  const { companyId, name, estimatedPrice, rowIndex: selectedRowIndex } = dialogData
  /* Доступно ли обновление списка */
  const isDisabled = observationData === null
  /* Цена, отображаемая в диалоге изменения */
  const displayablePrice = curEstimatedPrice || ''
  /* Есть ли добавляемая компания в списке */
  const { isCompanyInList } = companyToAdd

  /* Закрыть окно изменения цены входа */
  const handleOnCloseDialog = () => {
    setDialogData({})
    setCurEstimatedPrice('')
  }

  /* Изменение цены входа */
  const handleOnEstimatedPriceChange = (e) => {
    const { target: { value } } = e

    if (!Number.isNaN(Number(value)) && +value <= 1000000) {
      setCurEstimatedPrice(value)
    }
  }

  /* Сохранение цены входа */
  const saveCurEstimatedPrice = async () => {
    try {
      if (!Number.isNaN(Number(displayablePrice))) {
        const { data } = await axios.put(`/api/companies/${companyId}`, { estimatedPrice: Number(displayablePrice) })

        /* Если это добавляемая компания */
        if (Object.keys(companyToAdd).length && JSON.stringify(companyToAdd) === JSON.stringify(dialogData)) {
          /* Редактируем полученные данные и заменяем их */
          setCompanyToAdd(createCompaniesData([data])[0])
          handleOnCloseDialog()
        } else {
          /* Копируем массив */
          const newObservationData = observationData.slice()
          /* Выбранной компании меняем цену входа */
          newObservationData[selectedRowIndex].estimatedPrice = displayablePrice
          setObservationData(newObservationData)
          handleOnCloseDialog()
        }
      }
    } catch (e) {
      console.error(e)
      showMessage('Ошибка изменения цены входа')
    }
  }

  /* Добавить компанию в список наблюдения */
  const addCompanyToWatchlist = async () => {
    const { companyId: companyToAddId } = companyToAdd

    try {
      const { data } = await axios.post('/api/screener/companies/watchlist/add', { companyId: companyToAddId })

      setCompanyToAdd({})
      setObservationData(createCompaniesData(data))
      showMessage('Компания добавлена в список наблюдения', 'success')
    } catch (e) {
      console.error(e)
      showMessage('Ошибка добавления компании')
    }
  }

  /* Добавить компанию, добавляемую в список в диалог изменения цены */
  const handleOnAddCompanyButtonClick = () => {
    setDialogData(companyToAdd)
  }

  /* Изменение типа сделки */
  const onTypeOfDealChange = async (receivedId, value, rowIndex) => {
    try {
      const { data } = await axios.put(`/api/companies/${receivedId}`, { estimatedTypeOfDeal: value })

      if (typeof rowIndex === 'number') {
        /* Копируем массив */
        const newObservationData = observationData.slice()
        /* Выбранной компании меняем тип сделки */
        newObservationData[rowIndex].estimatedTypeOfDeal = value
        setObservationData(newObservationData)
      } else {
        setCompanyToAdd(createCompaniesData([data])[0])
      }
    } catch (e) {
      console.error(e)
      showMessage('Ошибка изменения типа сделки')
    }
  }

  /* Получение типа сделки в добавляемой компании */
  const handleOnTypeOfDealChange = async (e) => {
    /* Новое значение типа сделки */
    const { target: { value } } = e
    const { companyId: companyToAddId } = companyToAdd

    await onTypeOfDealChange(companyToAddId, value)
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 50, sortable: false, hide: true },
    { field: 'name', headerName: 'Название компании', width: 400, sortable: false },
    { field: 'ticker', headerName: 'Ticker', width: 128, sortable: false },
    {
      field: 'typeOfDeal',
      headerName: 'Тип сделки',
      width: 260,
      sortable: false,
      renderCell: (cellEvent) => {
        /* id, тип сделки, и индекс строки */
        const { row: { companyId: rowCompanyId, estimatedTypeOfDeal }, rowIndex } = cellEvent

        /* Получение типа сделки в компании в списке */
        const typeOfDealChange = async (e) => {
          /* Новое значение типа сделки */
          const { target: { value } } = e

          await onTypeOfDealChange(rowCompanyId, value, rowIndex)
        }

        return (<TypeOfDeal disabled={!updateCompany} value={estimatedTypeOfDeal} onChange={typeOfDealChange} />)
      }
    },
    {
      field: 'estimatedPrice',
      headerName: 'Цена входа',
      width: 340,
      sortable: false,
      renderCell: (cellEvent) => {
        const { row, rowIndex } = cellEvent
        const { estimatedPrice } = row

        const handleOnButtonClick = () => {
          setDialogData({ ...row, rowIndex })
        }

        return (
          <Box display='flex' justifyContent='flex-end' width='100%'>
            <EntryPrice disabled={!updateCompany} value={estimatedPrice} onChange={handleOnButtonClick} />
          </Box>
        )
      }
    },
    {
      field: 'delete',
      headerName: ' ',
      width: 70,
      sortable: false,
      renderCell: (cellEvent) => {
        const { row: { companyId } } = cellEvent

        const onDelete = async () => {
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false
          })
          try {
            const { data } = await axios.delete('/api/screener/companies/watchlist/delete', {
              data: {
                companyId
              }
            })
            showMessage('Компания успешно удалена', 'success')
            setObservationData(createCompaniesData(data))
          } catch (e) {
            console.error(e)
            showMessage('Ошибка удаления')
          }
        }

        return (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <IconButtonStyled
              disabled={!deleteFromWatchlist}
              aria-label='delete'
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: 'Вы уверены что хотите удалить эту компанию?',
                  confirmButtonText: 'Да, удалить',
                  cancelButtonText: 'Отменить',
                  cancelButtonColor: '#f44336',
                  confirmButtonColor: '#1976d2',
                  onConfirm: () => onDelete()
                })
              }}
            >
              <Clear fontSize='small' />
            </IconButtonStyled>
          </Box>
        )
      }
    }
  ]

  useEffect(() => {
    setCurEstimatedPrice(estimatedPrice)
  }, [estimatedPrice])

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get('/api/screener/companies/watchlist')
        const companies = createCompaniesData(data)

        setObservationData(companies)
      } catch (e) {
        console.error(e)
        showMessage('Ошибка загрузки компаний')
        setObservationData(null)
      }
    }

    fetch()
  }, [])

  return (
    <>
      <BreadcrumbsComponent />
      <PageTitle>Список наблюдения</PageTitle>
      <Box
        marginBottom='20px'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        height='112px'
      >
        {
          addToWatchlist && updateCompany ? (
            <>
              <AutocompleteStyled
                freeSolo
                disableClearable
                disabled={isDisabled || observationData?.length >= 10}
                options={searchData}
                onChange={(event, value, reason) => {
                  if (reason === 'select-option') {
                    const receivedCompanyToAdd = createCompaniesData([value])[0]

                    /* Есть ли добавляемый итем в списке */
                    observationData.forEach((observationDataItem) => {
                      if (observationDataItem.companyId === receivedCompanyToAdd.companyId) {
                        receivedCompanyToAdd.isCompanyInList = true
                      }
                    })

                    setCompanyToAdd(receivedCompanyToAdd)
                    setSearchData([])
                  }
                }}
                onInputChange={
                  async (e) => {
                    const { type, target: { value } } = e

                    if (type === 'change' && value) {
                      try {
                        const { data } = await axios.get(`/api/screener/companies/search/?query=${value}`)

                        setSearchData(data)
                      } catch (e) {
                        console.error(e)
                        showMessage('Ошибка поиска', 'error', { preventDuplicate: true })
                        setSearchData([])
                      }
                    }
                  }
                }
                getOptionLabel={(option) => option.name}
                renderOption={(option) => <SearchResult key={option.name} img={option.logoUrl} name={option.name} ticker={option.ticker} />}
                filterOptions={(options) => options}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Поиск компании'
                    margin='normal'
                    variant='outlined'
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                  />
                )}
              />
              {
                Object.keys(companyToAdd).length && addToWatchlist && updateCompany ? (
                  <Box
                    key={companyToAdd.companyId}
                    marginTop='16px'
                    marginBottom='8px'
                  >
                    <AddCompany
                      disabled={isCompanyInList}
                      img={companyToAdd.logoUrl}
                      name={companyToAdd.name}
                      ticker={companyToAdd.ticker}
                      estimatedTypeOfDeal={companyToAdd.estimatedTypeOfDeal}
                      estimatedPrice={companyToAdd.estimatedPrice}
                      onEstimatedPriceChange={handleOnAddCompanyButtonClick}
                      onTypeOfDealChange={handleOnTypeOfDealChange}
                      onAddToWatchList={addCompanyToWatchlist}
                    />
                  </Box>
                ) : null
              }
            </>
          ) : <AlertStyled severity='warning'>Нет разрешения на добавление и изменение компании</AlertStyled>
        }
      </Box>
      {observationData === undefined ? <Preloader /> : null}
      {isDisabled ? <AlertStyled severity='error'>Ошибка загрузки списка наблюдения</AlertStyled> : null}
      {observationData && observationData?.length === 0 ? <AlertStyled severity='info'>Список наблюдения пуст</AlertStyled> : null}
      {
        observationData && observationData.length ? (
          <Box height='438px' marginBottom='20px'>
            {
              observationData?.length % 2 ? (
                <DataGrid
                  key='OriginDataGrid'
                  rows={observationData}
                  columns={columns}
                  pageSize={10}
                  rowHeight={38}
                  checkboxSelection={false}
                  disableSelectionOnClick
                  hideFooter
                />
              ) : (
                <DataGrid
                  key='ReplacementDataGrid'
                  rows={observationData}
                  columns={columns}
                  pageSize={10}
                  rowHeight={38}
                  checkboxSelection={false}
                  disableSelectionOnClick
                  hideFooter
                />
              )
            }
          </Box>
        ) : null
      }
      <Dialog open={!!Object.keys(dialogData).length} onClose={handleOnCloseDialog} fullWidth>
        <DialogTitle id='max-width-dialog-title'>{name}</DialogTitle>
        <DialogContentStyled>
          <DialogContentText>
            Изменить цену входа:
          </DialogContentText>
          <Box marginBottom='20px'>
            <TextFieldStyled
              label='Number'
              value={displayablePrice}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                onChange: handleOnEstimatedPriceChange
              }}
              autoFocus
            />
          </Box>
          <ButtonStyled
            variant='contained'
            color='primary'
            onClick={saveCurEstimatedPrice}
            disabled={!displayablePrice}
          >
            Сохранить
          </ButtonStyled>
        </DialogContentStyled>
      </Dialog>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  )
}

export default Watchlist
