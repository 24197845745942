import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import ruLocale from 'date-fns/locale/ru'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { Box, Badge, IconButton, makeStyles, Tooltip, CircularProgress } from '@material-ui/core'
import { Event, Sync, Clear } from '@material-ui/icons'
import axios from '../../../../helpers/axios'

const propTypes = {
  chosenDate: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  setChosenDate: PropTypes.func.isRequired
}

const useStyles = makeStyles(theme => ({
  titleIcon: {
    color: theme.palette.info.main,
    height: '1rem'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
}))

const Calendar = ({ chosenDate, setChosenDate }) => {
  const classes = useStyles()
  const dateFns = new DateFnsUtils()

  const [requestedData, setRequestedDate] = useState([])

  async function getSyncsDate () {
    const { data: syncs } = await axios.get('/api/stock-sync/cms/calendar')
    await setRequestedDate(formateDateArray(syncs))
  }

  function formateDateArray (data) {
    const array = []
    data.forEach(item => {
      const currentSync = item.syncEndDate
      array.push(format(dateFns.date(currentSync), 'dd.MM.yyyy'))
    })
    return array
  }

  function resetDate () {
    setChosenDate(null)
  }

  useEffect(async () => {
    getSyncsDate()
  }, [])

  return (
    <>
      <Box className={classes.wrapper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <DatePicker
            inputVariant='outlined'
            format='dd.MM.yyyy'
            id='date-picker-inline'
            label='Дата синхронизации'
            value={chosenDate}
            onChange={setChosenDate}
            cancelLabel='Отменить'
            onMonthChange={getSyncsDate}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const isSelected = isInCurrentMonth && requestedData.includes(format(day, 'dd.MM.yyyy'))
              return (
                <Badge badgeContent={isSelected ? <Sync className={classes.titleIcon} /> : undefined}>
                  {dayComponent}
                </Badge>
              )
            }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <Event />
                </IconButton>
              )
            }}
          />
        </MuiPickersUtilsProvider>
        <Box>
          {chosenDate && (
            <Tooltip title=' Сбросить дату'>
              <IconButton
                onClick={() => resetDate()}
              >
                <Clear />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </>
  )
}

Calendar.propTypes = propTypes

export default Calendar
