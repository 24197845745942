import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Box, Avatar, Fab, CircularProgress, makeStyles, Fade } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { BrokenImage, Add } from '@material-ui/icons'

// components
import TypeOfDeal from '../TypeOfDeal'
import EntryPrice from '../EntryPrice'

// helpers
import cutString from '../../helpers/cutString'

const useStyles = makeStyles((theme) => ({
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-34px',
    marginLeft: '-34px'
  }
}))

const propTypes = {
  name: PropTypes.string.isRequired,
  ticker: PropTypes.string.isRequired,
  img: PropTypes.string,
  estimatedPrice: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  estimatedTypeOfDeal: PropTypes.string,
  onEstimatedPriceChange: PropTypes.func.isRequired,
  onTypeOfDealChange: PropTypes.func.isRequired,
  onAddToWatchList: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

const defaultProps = {
  img: '',
  entryPrice: null,
  typeOfDeal: null,
  disabled: false
}

const Name = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-right: 10px;
  max-height: 44px;
  overflow: hidden;
  width: 320px;
`

const Ticker = styled.p`
  font-size: 16px;
  line-height: 20px;
  flex: 0 0 auto;
  width: 110px;
`

const AddCompany = ({
  name,
  ticker,
  img,
  estimatedPrice,
  estimatedTypeOfDeal,
  onEstimatedPriceChange,
  onTypeOfDealChange,
  onAddToWatchList,
  disabled
}) => {
  const [enabled, setEnabled] = useState(false)
  const [loaderVisibility, setLoaderVisibility] = useState(true)
  const classes = useStyles()

  useEffect(() => {
    if (estimatedPrice && estimatedTypeOfDeal) {
      setEnabled(true)
    }
  }, [estimatedPrice, estimatedTypeOfDeal])

  useEffect(() => {
    if (enabled) {
      setTimeout(() => {
        setLoaderVisibility(false)
      }, 2000)
    }
  }, [enabled])

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      width='590px'
      height='112px'
      borderRadius='4px'
      boxShadow='0 0 5px 1px rgba(0,0,0,0.2),0 2px 1px -1px rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 1px 3px 0 rgba(0,0,0,0.12)'
    >
      <Box
        display='flex'
        flexDirection='column'
      >
        <Box
          display='flex'
          alignItems='center'
          height='56px'
        >
          <Box padding='8px' marginRight='10px'>
            <Avatar variant='rounded' src={img}>
              <BrokenImage />
            </Avatar>
          </Box>
          <Name>{cutString(name, 44, '...')}</Name>
          <Ticker>{cutString(ticker, 6, '...')}</Ticker>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          height='56px'
          padding='0 8px 0 8px'
        >
          <TypeOfDeal value={estimatedTypeOfDeal} disabled={disabled} onChange={onTypeOfDealChange} />
          <Box
            width='265px'
            overflow='hidden'
            whiteSpace='nowrap'
          >
            <EntryPrice value={estimatedPrice} disabled={disabled} onChange={onEstimatedPriceChange} />
          </Box>
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flex='0 0 80px'
        position='relative'
      >
        <Fade in={enabled && loaderVisibility && !disabled}>
          <CircularProgress size={68} color='green' position='absolute' className={classes.fabProgress} />
        </Fade>
        <Fab
          color='primary'
          disabled={disabled || !enabled}
          onClick={onAddToWatchList}
        >
          <Add />
        </Fab>
      </Box>
    </Box>
  )
}

AddCompany.propTypes = propTypes
AddCompany.defaultProps = defaultProps

export default AddCompany
