import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const AnimationWrapper = styled(motion.div)`
  padding: 30px 0 30px 0;
`

const TabAnimation = ({ children }) => (

  <AnimationWrapper
    initial={{ opacity: 0, y: 100 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.2 }}
  >
    {children}
  </AnimationWrapper>
)
export default React.memo(TabAnimation)
