import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Table, TableBody, TableHead, TableContainer } from '@material-ui/core'
import InvestIdeasTableHead from '../InvestIdeasTableHead'
import InvestIdeasTableItem from '../InvestIdeasTableItem'
import Preloader from '../../Preloader'

const PreloaderWrapper = styled.div`
  position: relative;
  margin: 150px auto;
  padding: 40px 0;
`

const propTypes = {
  ideas: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  setConfirmDialog: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired
}

const InvestIdeaTable = ({ ideas, onEdit, onDelete, onClose, onOpen, setConfirmDialog, onSort }) => {
  return (
    <>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <InvestIdeasTableHead onSort={onSort} />
          </TableHead>
          <TableBody>
            {ideas && ideas.map(idea =>
              <InvestIdeasTableItem
                key={idea.id}
                idea={idea}
                onEdit={onEdit}
                onDelete={onDelete}
                onClose={onClose}
                onOpen={onOpen}
                setConfirmDialog={setConfirmDialog}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {ideas.length === 0 ? <PreloaderWrapper> <Preloader /> </PreloaderWrapper> : null}
    </>
  )
}

InvestIdeaTable.propTypes = propTypes

export default InvestIdeaTable
