import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import axios from '../../helpers/axios'
import { Box, Fab, Tooltip, Link, Zoom, TextField } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Alert, Autocomplete, Pagination } from '@material-ui/lab'
import { produce, enableMapSet } from 'immer'
import { useDebounce } from 'use-debounce'
import useSWR from 'swr'

import CompanyForecasts from '../../blocks/CompanyForecasts'
import PageTitle from '../../components/PageTitle'
import useMessage from '../../hooks/useMessage'
import Preloader from '../../components/Preloader'
import BreadcrumbsComponent from '../../components/Breadcrumbs'

enableMapSet()

const AlertStyled = styled(Alert)`
  width: 590px;
`
const ForecastWrapper = styled.div`
  margin-top: 30px;
`
const ButtonHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
`
const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`
async function getSearchResults (query, url) {
  if (!query) return []
  try {
    const { data } = await axios.get(`${url}&query=${query}`)
    return data
  } catch (e) {
    return []
  }
}

const transformList = list => produce([], draft =>
  list.forEach(forecast => {
    const { company: { name } } = forecast
    if (draft.find(company => company.name === name)) {
      const indexOfSuggestionCompany = draft.findIndex(company => company.name === name)
      draft[indexOfSuggestionCompany]?.values.push(forecast)
    } else {
      draft.push({ name, values: [forecast] })
    }
  })
)

const Forecasts = () => {
  const [forecasts, setForecasts] = useState(null)
  const [forecastsCount, setForecastsCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch] = useDebounce(searchValue, 500)
  const [fetchError, setFetchError] = useState(false)
  const showMessage = useMessage()

  const { data: suggestions } = useSWR(() => debouncedSearch !== undefined && searchValue === debouncedSearch
    ? `/get?search=${debouncedSearch}`
    : null, () => getSearchResults(debouncedSearch, '/api/forecasts/published/search?'))

  const paginationCount = Math.ceil(forecastsCount / 10)
  const resultPage = page => (page * 10) - 10
  const tryCatchWrapper = callback => {
    try {
      callback()
    } catch (e) {
      console.error(e)
      showMessage('Ошибка загрузки прогнозов')
      setFetchError(true)
    }
  }

  const getBaseForecastsList = async () => {
    setForecasts(null)
    const { data: count } = await axios.get('/api/forecasts/count')
    const { data: allForecastsList } = await axios.get(`/api/forecasts?limit=${count}`)
    const { data: forecastList } = await axios.get(`/api/forecasts?limit=10&skip=${resultPage(currentPage)}`)
    setForecastsCount(transformList(allForecastsList).length)
    setForecasts(transformList(forecastList))
  }

  useEffect(async () => {
    tryCatchWrapper(getBaseForecastsList)
  }, [])

  async function chooseCompany (companyId) {
    if (companyId) {
      const callback = async () => {
        const { data: company } = await axios.get(`/api/forecasts/published?companyId=${companyId}`)
        setForecasts(transformList(company))
        setForecastsCount(1)
      }
      tryCatchWrapper(callback)
    } else {
      tryCatchWrapper(getBaseForecastsList)
    }
  }

  async function changePage (page) {
    setForecasts(null)
    const callback = async () => {
      const { data: newForecastsList } = await axios.get(`/api/forecasts?limit=10&skip=${resultPage(page)}`)
      setForecasts(transformList(newForecastsList))
      setCurrentPage(page)
    }
    tryCatchWrapper(callback)
  }

  return (
    <>
      <BreadcrumbsComponent />
      <PageTitle>Все прогнозы</PageTitle>
      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Autocomplete
            id='combo-box'
            freeSolo
            inputValue={searchValue}
            onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
            onChange={(e, value) => chooseCompany(value?.id)}
            options={suggestions || []}
            getOptionLabel={option => option?.ticker}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label='Поиск прогноза по компании' variant='outlined' />}
          />
          <ButtonHolder>
            <Zoom in timeout={700}>
              <Link component={RouterLink} to='/forecasts/add'>
                <Tooltip title='Создать новый прогноз'>
                  <Fab role='create-forecast' color='primary' size='medium'>
                    <Add />
                  </Fab>
                </Tooltip>
              </Link>
            </Zoom>
          </ButtonHolder>
        </Box>
          <ForecastWrapper>
            {forecasts === null ? <Box position='relative' style={{ margin: '200px auto' }}><Preloader /></Box> : null}
            {fetchError ? <AlertStyled severity='error'>Ошибка загрузки прогнозов</AlertStyled> : null}
            {forecasts && forecasts?.length === 0 ? <AlertStyled severity='warning'>Список прогнозов пуст</AlertStyled> : null}
            {forecasts && forecasts.length
              ? forecasts.map(company =>
                <CompanyForecasts
                  key={company?.name}
                  name={company?.name}
                  forecasts={forecasts}
                  companyForecasts={company?.values}
                  setForecasts={setForecasts}
                />)
              : null}
          </ForecastWrapper>
        </Box>
      {forecasts?.length > 0 && forecastsCount > 10
        ? <PaginationWrap>
          <Pagination
            count={paginationCount}
            page={currentPage}
            onChange={(e, value) => changePage(value)}
          />
        </PaginationWrap>
        : ''}
    </>
  )
}

export default Forecasts
