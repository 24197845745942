import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  TextField,
  Card,
  CardHeader,
  CardActions,
  Collapse,
  CardContent,
  Typography,
  Fade,
  IconButton,
  MenuItem,
  Tooltip
} from '@material-ui/core'
import { ExpandMore, DeleteForever } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'

// components
import PermissionsCard from '../../components/PermissionsCard'

// other
import axios from '../../helpers/axios'
import { alphabetHypensSpaces } from '../../helpers/regexp'
import useMessage from '../../hooks/useMessage'

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string,
  exchangeCodes: PropTypes.arrayOf(PropTypes.string),
  canUpdate: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  index: PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.string
  }),
  indexes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      id: PropTypes.string
    })
  ),
  onDeleteRole: PropTypes.func,
  setConfirmDialog: PropTypes.func
}

const defaultProps = {
  exchangeCodes: [],
  disabled: false,
  index: {},
  indexes: null,
  onDeleteRole: null
}

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: '5px'
  },
  cardActions: {
    padding: '0 16px 8px 16px',
    position: 'relative'
  },
  cardHeader: {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  cardContent: {
    '&.MuiCardContent-root': {
      padding: '8px 16px'
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  checkbox: {
    padding: '0 10px 0 0'
  },
  button: {
    width: '157px',
    justifyContent: 'space-between'
  }
}))

const RoleCard = ({
  id,
  name: propsName,
  description: propsDescription,
  type: propsRoleType,
  canUpdate,
  canDelete,
  onDeleteRole,
  setConfirmDialog
}) => {
  const showMessage = useMessage()
  const classes = useStyles()
  const openButtonText = canUpdate ? 'Редактировать' : 'Посмотреть'
  const closeButtonText = canUpdate ? 'Отмена' : 'Закрыть'

  const formik = useFormik({
    initialValues: {
      editing: false,
      name: propsName,
      newName: propsName,
      description: propsDescription,
      newDescription: propsDescription,
      type: propsRoleType,
      newType: propsRoleType,
      permissions: [],
      newPermissions: [],
      mainInfoIsChanged: false,
      permissionsIsChanged: false
    },
    validate (values) {
      const { name, newName, description, newDescription, type, newType, permissions, newPermissions } = values
      const errors = {}

      if (!alphabetHypensSpaces.test(newName)) {
        errors.newName = true
      }

      if (newDescription.length === 0) {
        errors.newDescription = true
      }

      if (name !== newName || description !== newDescription || type !== newType) {
        formik.setFieldValue('mainInfoIsChanged', true, false)
      } else {
        formik.setFieldValue('mainInfoIsChanged', false, false)
      }

      if (JSON.stringify(permissions) !== JSON.stringify(newPermissions) && permissions.length !== 0 && newPermissions.length !== 0) {
        formik.setFieldValue('permissionsIsChanged', true, false)
      } else {
        formik.setFieldValue('permissionsIsChanged', false, false)
      }

      return errors
    },
    async onSubmit (values) {
      const { newName, newDescription, newType, newPermissions, mainInfoIsChanged, permissionsIsChanged } = values

      const dataObj = {
        name: newName,
        description: newDescription,
        type: newType
      }

      if (mainInfoIsChanged) {
        try {
          const { data } = await axios.put(`/api/roles/${id}`, dataObj)
          const {
            name: receivedName,
            description: receivedDescription,
            type: receivedType,
            permissions: receivedPermissions
          } = data

          await formik.setValues({
            ...values,
            name: receivedName,
            description: receivedDescription,
            type: receivedType,
            permissions: receivedPermissions
          })

          showMessage('Имя и описание сохранены успешно', 'success',
            {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
        } catch (e) {
          console.error(e)
          showMessage('Ошибка сохранения имени и описания', 'error', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        }
      }

      if (permissionsIsChanged) {
        try {
          const { data } = await axios.put(`/api/roles/permissions/${id}`, {
            ...dataObj,
            permissions: newPermissions
          })

          const { name: receivedName, description: receivedDescription, type: receivedType, permissions: receivedPermissions } = data

          await formik.setValues({
            ...values,
            name: receivedName,
            description: receivedDescription,
            permissions: receivedPermissions,
            type: receivedType
          })

          showMessage('Разрешения сохранены успешно', 'success', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        } catch (e) {
          console.error(e)
          showMessage('Ошибка сохранения разрешений', 'error', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        }
      }
    }
  })

  const {
    setValues,
    values,
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    errors: {
      newName: newNameErr,
      newDescription: newDescriptionErr
    },
    touched: {
      newName: newNameTouched,
      newDescription: newDescriptionTouched
    }
  } = formik

  const {
    newName,
    newDescription,
    newType,
    newPermissions,
    editing,
    mainInfoIsChanged,
    permissionsIsChanged
  } = values

  const onChangeClick = async () => {
    if (!editing) {
      try {
        const { data } = await axios.get(`/api/roles/${id}`)
        const {
          name: receivedName,
          description: receivedDescription,
          type: receivedType,
          permissions: receivedPermissions
        } = data

        await setValues({
          ...values,
          name: receivedName,
          newName: receivedName,
          description: receivedDescription,
          newDescription: receivedDescription,
          type: receivedType,
          newType: receivedType,
          permissions: receivedPermissions,
          newPermissions: receivedPermissions,
          editing: true
        })
      } catch (e) {
        showMessage('Не удалось получить данные о роли', 'error', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        console.error(e)
      }
    } else {
      await setValues({
        ...values,
        mainInfoIsChanged: false,
        permissionsIsChanged: false,
        editing: false
      })
    }
  }

  const sortNewPermissionsByActionsCount = useMemo(() =>
    newPermissions.sort((a, b) =>
      Object.keys(b?.actions).length - Object.keys(a?.actions).length), [newPermissions])

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={newName}
          subheader={newDescription}
        />
        <CardActions className={classes.cardActions} disableSpacing>
          <Box marginRight='10px'>
            <Button
              variant='contained'
              color='primary'
              size='small'
              className={classes.button}
              endIcon={<ExpandMore className={editing ? classes.expandOpen : classes.expand} />}
              onClick={onChangeClick}
            >
              {editing ? closeButtonText : openButtonText}
            </Button>
          </Box>
          {
            canUpdate ? (
              <Fade in={editing}>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={!(mainInfoIsChanged || permissionsIsChanged)}
                  type='submit'
                >
                  Сохранить
                </Button>
              </Fade>
            ) : null
          }
          {
            onDeleteRole ? (
              <Box
                position='absolute'
                bottom='0'
                right='0'
              >
                <Tooltip title='Удалить'>
                  <IconButton
                    color='secondary'
                    disabled={!canDelete}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Вы уверены что хотите удалить эту роль?',
                        confirmButtonText: 'Да, удалить',
                        cancelButtonText: 'Отменить',
                        cancelButtonColor: '#f44336',
                        confirmButtonColor: '#1976d2',
                        onConfirm: () => { onDeleteRole() }
                      })
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : null
          }
        </CardActions>
        <Collapse in={editing} timeout='auto' unmountOnExit>
          <CardContent className={classes.cardContent}>
            {
              canUpdate ? (
                <Box
                  display='flex'
                  flexDirection='column'
                  marginBottom='10px'
                >
                  <TextField
                    style={{ width: '500px' }}
                    name='newName'
                    margin='dense'
                    value={newName}
                    error={newNameErr && newNameTouched}
                    label={newNameErr && newNameTouched ? 'Только буквы, пробелы и тире' : 'Название'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'Название' }}
                  />
                  <TextField
                    style={{ width: '500px' }}
                    name='newDescription'
                    margin='dense'
                    value={newDescription}
                    error={newDescriptionErr && newDescriptionTouched}
                    label={newDescriptionErr && newDescriptionTouched ? 'Описание не должно быть пустым' : 'Описание'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'Описание' }}
                  />
                  <TextField
                    style={{ width: '500px' }}
                    name='newType'
                    label='Тип роли'
                    select
                    margin='dense'
                    value={newType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'Тип роли' }}
                  >
                    <MenuItem value='customer'>Клиент</MenuItem>
                    <MenuItem value='editor'>Редактор</MenuItem>
                    <MenuItem value='admin'>Администратор</MenuItem>
                  </TextField>
                </Box>
              ) : null
            }
            <Box marginBottom='10px'>
              <Typography variant='h6'>Разрешения:</Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              flexWrap='wrap'
            >
              {
                sortNewPermissionsByActionsCount.length ? sortNewPermissionsByActionsCount.map((newPermission, i) => {
                  const { description, subject, actions } = newPermission
                  /* Объект с родительской сущностью */
                  let parentObject = {}
                  let parentSubject = ''

                  if (subject === 'x-formula' || subject === 'watchlist' || subject === 'filters') {
                    parentSubject = 'screener'
                  }

                  /* Если у сущности есть родительская сущность */
                  if (parentSubject) {
                    /* Бежим по родительским сущностям */
                    newPermissions.forEach(permission => {
                      const { subject: permissionSubject } = permission

                      /* Если находим родительскую сущность, сохраняем её */
                      if (permissionSubject === parentSubject) {
                        parentObject = permission
                      }
                    })
                  }

                  const { description: parentDescription, actions: parentActions } = parentObject
                  const parentManage = parentActions?.manage?.enabled ?? null

                  return (
                    <PermissionsCard
                      key={`${i}${description}`}
                      subjectNumber={i}
                      description={description}
                      parentDescription={parentDescription}
                      margin={(i + 1) % 4 === 0 ? '0 0 10px 0' : '0 10px 10px 0'}
                      permissions={newPermissions}
                      actions={actions}
                      parentActions={parentActions}
                      manage={parentManage}
                      canUpdate={canUpdate}
                      setFieldValue={setFieldValue}
                    />
                  )
                }) : null
              }
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </form>
  )
}

RoleCard.propTypes = propTypes
RoleCard.defaultProps = defaultProps

export default RoleCard
