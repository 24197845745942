import React from 'react'
import styled from 'styled-components'
import { Typography, Accordion, Divider, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const StyledAccordion = styled(Accordion)`
  border: 1px solid lightgray;
`
const SubHeader = styled(Typography)`
  font-size: 17px;
`

const ExplorerAccordionItem = ({ children, headerText }) => {
  return (
    <StyledAccordion key={headerText} elevation={2}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <SubHeader> {headerText} </SubHeader>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default React.memo(ExplorerAccordionItem)
