import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { motion } from 'framer-motion'

const propTypes = {
  name: PropTypes.string.isRequired,
  nameHeader: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textHeader: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  subtextHeader: PropTypes.string,
  withAnimation: PropTypes.bool
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    borderBottom: '1px solid lightgray',
    marginBottom: theme.spacing(2),
    padding: '0 10px 15px 10px;'
  },
  header: {
    fontWeight: 300,
    marginBottom: '10px',
    color: 'gray'
  }
}))

const HorizontalList = ({ name, nameHeader, text, textHeader, subtext, subtextHeader, withAnimation }) => {
  const classes = useStyles()

  return (
    <motion.div
      initial={withAnimation && { opacity: 0 }}
      animate={withAnimation && { opacity: 1 }}
      transition={withAnimation && { duration: 0.7 }}
    >
      <Grid container className={classes.container}>
        <Grid item xs={subtext ? 4 : 5}>
          <Typography className={classes.header}> {nameHeader} </Typography>
          <Typography> {name} </Typography>
        </Grid>
        {subtext && (
          <Grid item xs={4}>
            <Typography className={classes.header}> {subtextHeader} </Typography>
            <Typography> {subtext || 'Нет'} </Typography>
          </Grid>
        )}
        <Grid item xs={subtext ? 4 : 5}>
          <Typography className={classes.header}> {textHeader} </Typography>
          <Typography> {text || 'Нет'} </Typography>
        </Grid>
      </Grid>
    </motion.div>
  )
}

HorizontalList.propTypes = propTypes

export default HorizontalList
