import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@material-ui/core'

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

const defaultProps = {
  value: null,
  disabled: false
}

const ButtonStyled = styled(Button)`
  min-width: 93px;
`

const EntryPrice = ({ value, onChange, disabled }) => {
  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='flex-end'
      alignItems='center'
      width='auto'
    >
      <Box fontSize='16px' paddingRight='10px'>
        {
          value === null
            ? <Typography variant='body1'>Цена не установлена</Typography>
            : <Typography variant='body1'>$ {value}</Typography>
        }
      </Box>
      <ButtonStyled disabled={disabled} variant='contained' color='primary' size='small' onClick={onChange}>
        Изменить
      </ButtonStyled>
    </Box>
  )
}

EntryPrice.propTypes = propTypes
EntryPrice.defaultProps = defaultProps

export default EntryPrice
