import React from 'react'
import { Box, Button, IconButton, Tooltip, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'

export const AddCountryButton = ({ onHandleClick, customText, onCenter, margin }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: onCenter && 'center',
    margin
  }
  return (
    <Box sx={style}>
      <Button
        variant='outlined'
        color='primary'
        size='small'
        onClick={onHandleClick}
        style={{ minWidth: 50, marginBottom: 10 }}
      >
        <Typography>{customText}</Typography>
        <Tooltip title='Добавить' placement='top'>
          <IconButton size='small' color='primary'>
            <Add />
          </IconButton>
        </Tooltip>
      </Button>
    </Box>
  )
}
