import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ExpandMore } from '@material-ui/icons'
import { format } from 'date-fns'
import Error from '../Error'

const useStyles = makeStyles((theme) => ({
  accordionHeader: {
    fontWeight: 700
  },
  accordion: {
    border: '1px solid lightgray'
  },
  limitedWidth: {
    width: '590px'
  }
}))

const propTypes = {
  errors: PropTypes.array.isRequired
}

const Errors = ({ errors }) => {
  const classes = useStyles()

  function formatedDate (date) {
    return format(new Date(date), 'dd.MM.yyyy')
  }

  return (
    <>
      {errors && errors.length === 0 ? <Alert severity='warning' className={classes.limitedWidth}>Список ошибок пуст</Alert> : null}
      {errors && errors.length > 0 && errors.map(error =>
        <Accordion key={error.id} defaultExpanded className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography className={classes.accordionHeader}>
              Синхронизация за {error.syncEndDate && formatedDate(error.syncEndDate)}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Error
              key={error.name}
              syncErrorsCount={error.syncErrorsCount}
              status={error.status}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}

Errors.propTypes = propTypes

export default Errors
