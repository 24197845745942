import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Paper, Grid, Typography, Divider, CircularProgress } from '@material-ui/core'
import { useDebounce } from 'use-debounce'
import ExplorerSyncDataTable from '../ExplorerSyncDataTable'
import ExplorerCompanyDataTable from '../ExplorerCompanyDataTable'
import ExplorerAccordionItem from '../ExplorerAccordionItem'
import { useAuthSWR } from '../../../../helpers/axios'

const propTypes = {
  currentCompany: PropTypes.object.isRequired,
  onCurrentCompanyChange: PropTypes.func,
  syncsData: PropTypes.array.isRequired,
  xFormula: PropTypes.string.isRequired,
  recalculateXFormula: PropTypes.func.isRequired
}

const defaultProps = {
  onCurrentCompanyChange: null
}

const Wrapper = styled(Paper)`
  border: 1px solid lightgray;
  border-top: 5px solid #3F51B5;
  padding: 15px;
`
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
`
const Header = styled(Typography)`
  font-size: 17px;
`
const CompanyWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const CompanyLogo = styled.img`
  width: 30px;
  margin-left: 10px;
`
const StyledDivider = styled(Divider)`
  background: #3F51B5;
  height: 2px;
  margin-bottom: 30px;
`

const ExplorerInfoItem = ({
  currentCompany,
  onCurrentCompanyChange,
  syncsData,
  xFormula,
  recalculateXFormula
}) => {
  const [newRating, setNewRating] = useState('')
  const [debouncedCompany] = useDebounce(currentCompany, 500)

  const { data: place } = useAuthSWR(debouncedCompany ? `api/companies/cms/rating-place?ticker=${debouncedCompany.ticker}` : null)

  useEffect(() => {
    async function recalculateCompanyRating () {
      try {
        const response = await recalculateXFormula(debouncedCompany.id, xFormula, debouncedCompany)
        setNewRating(response.rating)
        if (!currentCompany.rating && onCurrentCompanyChange) {
          onCurrentCompanyChange(company => ({ ...company, rating: response.rating }))
        }
      } catch (e) {
        console.error(e)
      }
    }
    recalculateCompanyRating()
  }, [debouncedCompany])

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header> {currentCompany?.ticker} </Header>
        <CompanyWrapper>
          <Header>  {currentCompany?.name} </Header>
          <CompanyLogo src={currentCompany?.logoUrl} />
        </CompanyWrapper>
        <Header> {newRating || currentCompany?.rating} </Header>
        <Header>
          {place
            ? `Предполагаемое место: ${place}`
            : <CircularProgress size={25} />}
        </Header>
      </HeaderWrapper>
      <StyledDivider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ExplorerAccordionItem headerText='Данные о компании'>
            <ExplorerCompanyDataTable company={currentCompany} onCompanyChange={onCurrentCompanyChange} key={currentCompany} />
          </ExplorerAccordionItem>
        </Grid>
        <Grid item xs={12}>
          <ExplorerAccordionItem headerText='Сырые данные о компании'>
            <ExplorerSyncDataTable ticker={currentCompany.ticker} syncsData={syncsData} key={syncsData} />
          </ExplorerAccordionItem>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

ExplorerInfoItem.propTypes = propTypes
ExplorerInfoItem.defaultProps = defaultProps

export default ExplorerInfoItem
