import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { IconButton, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core'
import { DeleteForever, Edit } from '@material-ui/icons'

import canAccess from '../../../helpers/canAccess'

const translationsRoles = {
  admin: 'Администратор',
  analyst: 'Аналитик',
  premium: 'Премиум',
  free: 'Бесплатный',
  custom: 'Кастомный'
}

const propTypes = {
  user: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setConfirmDialog: PropTypes.func.isRequired
}

const useStyles = makeStyles(theme => ({
  iconButton: {
    marginRight: '5px'
  },
  cellName: {
    maxWidth: '200px',
    padding: '25px',
    paddingLeft: 0
  },
  th: {
    fontSize: '16px',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  }
}))

const UserTableItem = ({ user, onEdit, onDelete, setConfirmDialog }) => {
  const classes = useStyles()
  const deleteUser = canAccess('user', 'deleteCmsUser')
  const editUser = canAccess('user', 'editCmsUser')

  const { id, displayName, role, subscription } = user

  return (
    <TableRow
      key={id}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
    >
      <TableCell className={classes.cellName}> {displayName} </TableCell>
      <TableCell>{user?.phone?.value || 'Нет'}</TableCell>
      <TableCell>{user?.email?.value || 'Нет'}</TableCell>
      <TableCell>{translationsRoles[role.name]}</TableCell>
      <TableCell>{subscription?.tariff || 'Отсутствует'}</TableCell>
      <TableCell>
        <Tooltip title='Редактировать'>
          <IconButton
            role='editing-button'
            size='small'
            disabled={!editUser}
            className={classes.iconButton}
            style={{ color: editUser ? '#1976d2' : '#BDBDBD' }}
            onClick={() => onEdit(id)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title='Удалить'>
          <IconButton
            size='small'
            style={{ color: deleteUser ? '#f44336' : '#BDBDBD' }}
            disabled={!deleteUser}
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Вы уверены что хотите удалить этого пользователя?',
                confirmButtonText: 'Да, удалить',
                cancelButtonText: 'Отменить',
                cancelButtonColor: '#f44336',
                confirmButtonColor: '#1976d2',
                onConfirm: () => { onDelete(id) }
              })
            }}
          >
            <DeleteForever />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

UserTableItem.propTypes = propTypes

export default UserTableItem
