import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Container } from '@material-ui/core'
import Navigation from '../components/Navigation'
import Forecast from '../pages/forecasts/forecast'
import Forecasts from '../pages/forecasts/forecasts'
import Industry from '../pages/industry'
import Roles from '../pages/roles'
import Watchlist from '../pages/screener/watchlist'
import xFormula from '../pages/screener/x-formula'
import NotFound from '../pages/screener/not-found'
import Filters from '../pages/screener/filters'
import InvestIdea from '../pages/investIdeas/investIdea'
import InvestIdeas from '../pages/investIdeas/investIdeas'
import Companies from '../pages/companies/companies'
import Company from '../pages/companies/company'
import { TranslateIndustries } from '../pages/translate/industries'
import { TranslateCompaniesName } from '../pages/translate/companyName'
import Users from '../pages/users/users'
import User from '../pages/users/user'
import SyncStatistic from '../pages/explorer/sync-statistic'
import SyncErrors from '../pages/explorer/sync-errors'
import ScreenerStatistic from '../pages/explorer/screener-statistic'
import Explorer from '../pages/explorer/explorer'
import Tariffs from '../pages/tariffs/tariffs'
import Tariff from '../pages/tariffs/tariff'
import useUserRole from '../hooks/useUserRole'
import Educations from '../pages/educations/educations'
import Education from '../pages/educations/education'

const routesObj = {
  'forecasts-add': <Route key='/forecasts/:id' exact path='/forecasts/:id' component={Forecast} />,
  forecasts: <Route key='/forecasts' exact path='/forecasts' component={Forecasts} />,
  'invest-ideas-add': <Route key='/investIdeas/:id' exact path='/invest-ideas/:id' component={InvestIdea} />,
  'invest-ideas': <Route key='/investIdeas' exact path='/invest-ideas' component={InvestIdeas} />,
  industry: <Route key='/industry' exact path='/industry' component={Industry} />,
  roles: <Route key='/roles' exact path='/roles' component={Roles} />,
  companies: <Route key='/companies' exact path='/companies' component={Companies} />,
  company: <Route key='/companies/:id' exact path='/companies/:id' component={Company} />,
  'x-formula': <Route key='/screener/x-formula' exact path='/screener/x-formula' component={xFormula} />,
  watchlist: <Route key='/screener/watchlist' exact path='/screener/watchlist' component={Watchlist} />,
  filters: <Route key='/screener/filters' exact path='/screener/filters' component={Filters} />,
  translateCompanyName: <Route key='/translate/company-name' exact path='/translate/company-name' component={TranslateCompaniesName} />,
  translateIndustries: <Route key='/translate/industries' exact path='/translate/industries' component={TranslateIndustries} />,
  'users-add': <Route key='/users/:id' exact path='/users/:id' component={User} />,
  users: <Route key='/users' exact path='/users' component={Users} />,
  'sync-statistic': <Route key='/explorer/sync-statistic' exact path='/explorer/sync-statistic' component={SyncStatistic} />,
  'sync-errors': <Route key='/explorer/sync-errors' exact path='/explorer/sync-errors' component={SyncErrors} />,
  'screener-statistic': <Route key='/explorer/screener-statistic' exact path='/explorer/screener-statistic' component={ScreenerStatistic} />,
  explorer: <Route key='/explorer/explorer' exact path='/explorer/explorer' component={Explorer} />,
  tariffs: <Route key='/tariffs' exact path='/tariffs' component={Tariffs} />,
  educations: <Route key='/educations' exact path='/educations' component={Educations} />,
  education: <Route key='/education/:id' exact path='/education/:id' component={Education} />,
  'tariffs-add': <Route key='/tariffs/:id' exact path='/tariffs/:id' component={Tariff} />
}

const Authorized = () => {
  const { routesPermission } = useUserRole()

  return (
    <Container
      style={{
        width: '1200px',
        padding: 0
      }}
    >
      <Route path='/' component={Navigation} />
      <Switch>
        {
          routesPermission.length && routesPermission.map(route => {
            if (route.routes) {
              return route.routes.map(item => {
                return item.visibility ? routesObj[item.page] : false
              })
            }
            if (route.singlePage) {
              return route.singlePage.map(item => routesObj[item])
            }
            return routesObj[route.page]
          })
        }
        <Redirect exact from='/' to='/screener/x-formula' />
        <Route path='*' component={NotFound} />
      </Switch>
    </Container>
  )
}

export default Authorized
