import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core'
import { DeleteForever, Edit, LockOpenOutlined, LockOutlined } from '@material-ui/icons'
import { format, utcToZonedTime } from 'date-fns-tz'
import ruLocale from 'date-fns/locale/ru'
import canAccess from '../../../helpers/canAccess'

const propTypes = {
  idea: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  setConfirmDialog: PropTypes.func.isRequired
}

const IconButtonStyled = styled(IconButton)`
  margin-right: 5px;
`
const CellName = styled(TableCell)`
  max-width: 200px;
  padding: 25px 25px 25px 0;
`

const InvestIdeasTableItem = ({
  idea,
  onEdit,
  onDelete,
  onClose,
  onOpen,
  setConfirmDialog
}) => {
  const deleteIdea = canAccess('invest-idea', 'delete')
  const editIdea = canAccess('invest-idea', 'update')
  const closeIdea = canAccess('invest-idea', 'close')

  const { id, publicationStatus, ideaStatus, currentPrice, closePrice } = idea
  const formattedDate = idea.openDate
    ? format(utcToZonedTime(idea.openDate, 'Europe/Moscow'), 'dd.MM.yyyy', { locale: ruLocale })
    : ''
  const formattedPublicationStatus = publicationStatus === 'published' ? 'Опубликована' : 'Не опубликована'
  const formattedIdeaStatus = ideaStatus === 'open' ? 'Открыта' : 'Закрыта'

  return (
    <TableRow
      key={id}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
    >
      <CellName> Идея по акциям {idea?.company?.name} </CellName>
      <TableCell align='center'>{idea?.company?.ticker || 'Нет'}</TableCell>
      <TableCell align='center'>{formattedDate}</TableCell>
      <TableCell align='center'>{formattedPublicationStatus}</TableCell>
      <TableCell align='center' style={{ color: ideaStatus === 'open' ? 'green' : 'red' }}>
        {formattedIdeaStatus}
      </TableCell>
      <TableCell align='center'>{currentPrice}</TableCell>
      <TableCell align='center'>{closePrice}</TableCell>
      <TableCell>
        <Tooltip title='Редактировать'>
          <IconButtonStyled
            size='small'
            disabled={!editIdea}
            style={{ color: editIdea ? '#1976d2' : '#BDBDBD' }}
            onClick={() => onEdit(id)}
          >
            <Edit />
          </IconButtonStyled>
        </Tooltip>
        {ideaStatus !== 'close' && (
          <Tooltip title='Закрыть идею'>
            <IconButtonStyled
              size='small'
              disabled={!closeIdea}
              style={{ color: closeIdea ? '#ffb74d' : '#BDBDBD' }}
              onClick={() => onClose(id)}
            >
              <LockOutlined />
            </IconButtonStyled>
          </Tooltip>
        )}
        {ideaStatus === 'close' && (
          <Tooltip title='Открыть идею'>
            <IconButtonStyled
              size='small'
              disabled={!closeIdea}
              style={{ color: closeIdea ? '#81c784' : '#BDBDBD' }}
              onClick={() => onOpen(id)}
            >
              <LockOpenOutlined />
            </IconButtonStyled>
          </Tooltip>
        )}
        <Tooltip title='Удалить'>
          <IconButton
            size='small'
            style={{ color: deleteIdea ? '#f44336' : '#BDBDBD' }}
            disabled={!deleteIdea}
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Вы уверены что хотите удалить эту идею?',
                confirmButtonText: 'Да, удалить',
                cancelButtonText: 'Отменить',
                cancelButtonColor: '#f44336',
                confirmButtonColor: '#1976d2',
                onConfirm: () => { onDelete(id) }
              })
            }}
          >
            <DeleteForever />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

InvestIdeasTableItem.propTypes = propTypes

export default InvestIdeasTableItem
