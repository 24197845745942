import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core'
import { motion } from 'framer-motion'
import { DeleteForever, Edit } from '@material-ui/icons'
import React from 'react'
import { format } from 'date-fns'

export const TariffsListElement = ({ tariffData, onEdit, onDelete, setConfirmDialog, canDelete, canEdit }) => {
  const { id, title, cost, discount, updatedAt } = tariffData

  return (
    <TableRow
      key={id}
      component={motion.div}
      style={{ width: '100%' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
    >
      <TableCell style={{ fontWeight: 'bold' }}>{title}</TableCell>
      <TableCell>{cost}</TableCell>
      <TableCell>{discount}%</TableCell>
      <TableCell>{format(new Date(updatedAt), 'dd.MM.yyyy')}</TableCell>
      <TableCell>
        <Tooltip title='Редактировать'>
          <IconButton
            role='editing-button'
            size='small'
            disabled={!canEdit}
            style={{ color: canEdit ? '#1976d2' : '#BDBDBD' }}
            onClick={() => onEdit(id)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title='Удалить'>
          <IconButton
            size='small'
            style={{ color: canDelete ? '#f44336' : '#BDBDBD' }}
            disabled={!canDelete}
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Вы уверены что хотите удалить этот тариф?',
                confirmButtonText: 'Да, удалить',
                cancelButtonText: 'Отменить',
                cancelButtonColor: '#f44336',
                confirmButtonColor: '#1976d2',
                onConfirm: () => { onDelete(id) }
              })
            }}
          >
            <DeleteForever />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
