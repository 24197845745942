import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const propTypes = {
  name: PropTypes.string.isRequired,
  ticker: PropTypes.string.isRequired,
  img: PropTypes.string
}

const defaultProps = {
  img: ''
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
`

const Image = styled.div`
  width: 23px;
  height: 23px;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center center;
  margin: 0 15px 0 0;
`
const Name = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  margin-right: 20px;
`

const Ticker = styled.p`
  font-size: 16px;
  line-height: 16px;
`

const SearchResult = ({ name, ticker, img }) => {
  return (
    <Wrap>
      {img && <Image img={img} />}
      <Name>{name}</Name>
      <Ticker>{ticker}</Ticker>
    </Wrap>
  )
}

SearchResult.propTypes = propTypes
SearchResult.defaultProps = defaultProps

export default SearchResult
