import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, TextField } from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import { useDebounce } from 'use-debounce'
import useSWR from 'swr'

import useMessage from '../../hooks/useMessage'
import axios from '../../helpers/axios'
import { CompanyNameListItem } from '../../components/TranslatePages/CompanyNameListItems'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import { TranslateTableHead } from '../../components/TranslatePages/TableHead'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 40px;
`
const PageTitle = styled.h1`
  font-weight: 600;
  font-size: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: grey;
  padding: 20px 0;
`
const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`

async function getSearchResults (query) {
  if (!query) return []
  try {
    const { data: companies } = await axios.get(`/api/companies?exchangeCode=MCX&name=/${query}/mi`)
    return companies
  } catch (e) {
    return []
  }
}

export const TranslateCompaniesName = () => {
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch] = useDebounce(searchValue, 500)

  const [companies, setCompanies] = useState([])
  const [companiesCount, setCompaniesCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const showMessage = useMessage()
  const paginationCount = Math.ceil(companiesCount / 10)

  const { data: suggestions } = useSWR(() =>
    debouncedSearch !== undefined && searchValue === debouncedSearch ? ['/companies/get', debouncedSearch] : null, () => getSearchResults(debouncedSearch))

  useEffect(() => {
    try {
      axios.get('/api/companies?exchangeCode=MCX').then(companiesList => setCompanies(companiesList.data))
      axios.get('/api/companies/count?exchangeCode=MCX').then(count => setCompaniesCount(count.data))
    } catch (e) {
      console.error(e)
      showMessage('Ошибка загрузки компаний')
    }
  }, [])

  useEffect(() => {
    try {
      axios.get('/api/companies?exchangeCode=MCX').then(companiesList => setCompanies(companiesList.data))
    } catch (error) {
      showMessage('Ошибка загрузки компаний')
    }
  }, [debouncedSearch.length === 0])

  useEffect(() => {
    if (suggestions?.length) {
      setCompanies(suggestions)
    } else {
      setCompanies(companies)
    }
  }, [suggestions?.length])

  const changePage = async (newPage) => {
    const resultPage = (page) => {
      if (page === 1) {
        return ''
      } else {
        return ((page + '0') - 10)
      }
    }
    try {
      const { data: newCompaniesList } = await axios.get(`/api/companies?skip=${resultPage(newPage)}&exchangeCode=MCX`)
      setCompanies(newCompaniesList)
      setCurrentPage(newPage)
    } catch (e) {
      console.error(e)
      showMessage('Ошибка отправки данных')
    }
  }

  return (
    <>
      <Box>
        <BreadcrumbsComponent />
        <PageHeader>
          <PageTitle>Перевод названий у компаний Московской биржи</PageTitle>
          <Autocomplete
            id='combo-box-demo'
            freeSolo
            inputValue={searchValue}
            onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
            options={suggestions || []}
            getOptionLabel={(option) => option?.name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label='Поиск компании' variant='outlined' />}
          />
        </PageHeader>
        <TranslateTableHead />
        {companies.map(company => <CompanyNameListItem key={company.id} {...company} />)}
      </Box>
      {!debouncedSearch.length && companies.length && companiesCount > 10 ?
        <PaginationWrap>
          <Pagination count={paginationCount} page={currentPage} onChange={(e, value) => changePage(value)} />
        </PaginationWrap>
        : ''}
    </>
  )
}
