import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import produce from 'immer'
import { Paper, Box } from '@material-ui/core'
import axios from '../../../../helpers/axios'

import Preloader from '../../../Preloader'
import HorizontalList from '../../../HorizontalList'
import SortButton from '../SortButton'

const SearchWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

function sortItems (object, sorter) {
  let newState
  switch (sorter) {
    case 'highToLow':
      newState = produce(object, draft => {
        draft = Object.entries(draft)
          .sort((a, b) => a[1] - b[1])
        return draft
      })
      break
    case 'lowToHigh':
      newState = produce(object, draft => {
        draft = Object.entries(draft)
          .sort((a, b) => b[1] - a[1])
        return draft
      })
      break
    default:
      newState = produce(object, draft => {
        draft = Object.entries(draft)
        return draft
      })
  }
  return newState
}

const CompaniesIndustryTable = () => {
  const [noDataCompanies, setNoDataCompanies] = useState()
  const [companiesObject, setCompaniesObject] = useState()
  const [sorter, setSorter] = useState('')

  function sorterHandler () {
    sorter === '' || sorter === 'highToLow' ? setSorter('lowToHigh') : setSorter('highToLow')
  }

  useEffect(() => {
    if (sorter !== '') {
      setNoDataCompanies(sortItems(companiesObject, sorter))
    }
  }, [sorter])

  useEffect(async () => {
    const { data } = await axios.get('/api/screener/stats/companies/incompleted')
    setNoDataCompanies(sortItems(data, sorter))

    setCompaniesObject(data)
  }, [])

  return (
    <>
      <Paper>
        <Box>
          {!noDataCompanies ? <Box position='relative' paddingTop='100px'><Preloader /></Box> : null}
          {companiesObject && noDataCompanies && (
            <SearchWrap>
              <SortButton sorter={sorter} sorterHandler={sorterHandler} />
            </SearchWrap>
          )}
          {companiesObject && noDataCompanies && noDataCompanies.map(item =>
            item[0] !== 'companies' && item[0] !== 'rating' && item[0] !== 'overheatingTrendDirection' && item[0] !== 'stockIndex'
              ? <HorizontalList
                  withAnimation
                  nameHeader='Название'
                  textHeader='Количество компаний'
                  key={item[0]}
                  name={item[0]}
                  text={item[1]}
                />
              : null
          )}
        </Box>
      </Paper>
    </>
  )
}

export default CompaniesIndustryTable
