import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Box, makeStyles, Typography, Paper, Grid } from '@material-ui/core'
import { motion } from 'framer-motion'
import Preloader from '../../../Preloader'

const propTypes = {
  allCompanies: PropTypes.shape({
    companies: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
    incompleted: PropTypes.number.isRequired
  }).isRequired,
  countries: PropTypes.object.isRequired
}

const AllCompaniesAnimation = styled(motion.div)``

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  wrapperItemCompanies: {
    width: '25%'
  },
  wrapperItemCountries: {
    width: '70%'
  },
  allCompaniesInfo: {
    position: 'relative',
    minHeight: '130px',
    border: '1px solid lightgray',
    padding: '14px',
    borderRadius: '7px'
  },
  allCompaniesInfoHeader: {
    textTransform: 'uppercase',
    fontSize: '18px',
    margin: '5px 0 10px 0'
  },
  allCompaniesFull: {
    color: '#1B5E20',
    margin: '5px 0'
  },
  allCompaniesNotFull: {
    color: '#BE1C1C',
    margin: '5px 0',
    fontSize: '16px'
  },
  countriesItem: {
    margin: '5px 0'
  }
}))

const abbreviations = {
  'Соединённые Штаты Америки': 'США',
  'Южно-Африканская Республика': 'ЮАР'
}

const AllCompaniesTable = ({ allCompanies, countries }) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.wrapperItemCompanies}>
          <Paper elevation={3} className={classes.allCompaniesInfo}>
            {!allCompanies
              ? <Preloader />
              : <AllCompaniesAnimation
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  <Typography className={classes.allCompaniesInfoHeader}>
                    Всего компаний: {allCompanies?.companies}
                  </Typography>
                  <Typography className={classes.allCompaniesFull}>
                    С полными данными: {allCompanies?.completed}
                  </Typography>
                  <Typography className={classes.allCompaniesNotFull}>
                    С неполными данными: {allCompanies?.incompleted}
                  </Typography>
                </AllCompaniesAnimation>
            }

          </Paper>
        </Box>
        <Box className={classes.wrapperItemCountries}>
          <Paper elevation={3} className={classes.allCompaniesInfo}>
            {!countries
              ? <Preloader />
              : <AllCompaniesAnimation
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Typography className={classes.allCompaniesInfoHeader}> Компании по странам </Typography>
                <Grid container>
                  {Object.keys(countries).map(country =>
                    country !== 'companies'
                      ? <Grid item xs={3}>
                          <Typography key={country} className={classes.countriesItem}>
                            {abbreviations[country] ? abbreviations[country] : country}: {countries[country]}
                          </Typography>
                        </Grid>
                      : null
                  )}
                </Grid>

                </AllCompaniesAnimation>
            }
          </Paper>
        </Box>
      </Box>
    </>
  )
}

AllCompaniesTable.propTypes = propTypes

export default AllCompaniesTable
