import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Typography
} from '@material-ui/core'
import Preloader from '../../components/Preloader'

const propTypes = {
  exchangeCodes: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.string
  }),
  country: PropTypes.shape({
    translatedName: PropTypes.string,
    id: PropTypes.string
  }),
  roles: PropTypes.arrayOf(PropTypes.string),
  allRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string
    })
  )
}

const defaultProps = {
  exchangeCodes: [],
  index: {},
  country: {},
  roles: null,
  allRoles: null
}

const TypographyStyled = styled(Typography)`
  margin-bottom: 10px;
`

const ChipStyled = styled(Chip)`
  line-height: normal;
  margin-bottom: 10px;
`

const CheckboxStyled = styled(Checkbox)`
  padding: 2px;
`

const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: -5px;
`

const FilterPresentation = ({ exchangeCodes, index, country, roles, allRoles }) => (
  <Box
    display='flex'
    flexDirection='column'
  >
    <Box
      display='flex'
      flexDirection='column'
    >
      <TypographyStyled variant='h6'>Список бирж:</TypographyStyled>
      <Box>
        {
          exchangeCodes.map((exchangeCode, i) => (
            <ChipStyled
              color='primary'
              key={`${i}${exchangeCode}`}
              style={{ marginRight: exchangeCodes.length - 1 === i ? '0' : '10px' }}
              label={exchangeCode}
            />
          ))
        }
      </Box>
    </Box>
    <Box
      display='flex'
      flexDirection='column'
    >
      {!allRoles ? <Box position='relative' paddingTop='40px'><Preloader /></Box> : null}
      {
        !roles || (allRoles && allRoles.length === 0)
          ? (
            <Box color='warning.main'>
              <Typography variant='h6'>Данные о ролях недоступны</Typography>
            </Box>
            )
          : null
      }
      {
        roles && allRoles && allRoles.length
          ? (
            <Box
              display='flex'
              flexDirection='column'
            >
              <TypographyStyled variant='h6'>Роли, для которых доступен фильтр:</TypographyStyled>
              {
              allRoles.map((role) => {
                const { id, description } = role

                return (
                  <FormControlLabelStyled
                    key={id}
                    disabled
                    control={<CheckboxStyled checked={roles.includes(id)} />}
                    label={description}
                  />
                )
              })
            }
            </Box>
            )
          : null
      }
      <Box marginTop='6px'>
        {
          Object.keys(index).length
            ? (
              <>
                <TypographyStyled variant='h6'>Индекс:</TypographyStyled>
                <Typography variant='body2'>{index.code}</Typography>
              </>
              )
            : (
              <TypographyStyled variant='h6'>Индекс отсутствует</TypographyStyled>
              )
        }
      </Box>
      <Box marginTop='6px'>
        {
          country && Object.keys(country).length
            ? (
              <>
                <TypographyStyled variant='h6'>Страна:</TypographyStyled>
                <Typography variant='body2'>{country.translatedName}</Typography>
              </>
              )
            : (
              <TypographyStyled variant='h6'>Страна не указана</TypographyStyled>
              )
        }
      </Box>
    </Box>
  </Box>
)

FilterPresentation.propTypes = propTypes
FilterPresentation.defaultProps = defaultProps

export default FilterPresentation
