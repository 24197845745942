const columns = [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'name', headerName: 'Название переменной', width: 300 },
  { field: 'description', headerName: 'Описание', width: 848 }
]

export const companyRows = [
  { id: 1, name: 'marketCap', description: 'Рыночная капитализация' },
  { id: 2, name: 'forwardPE', description: 'Прогноз будущей прибыли' },
  { id: 3, name: 'priceToBookRatio', description: 'Цена/балансная стоимость' },
  { id: 4, name: 'priceToSalesRatio', description: 'Цена/объем продаж' },
  { id: 5, name: 'priceToFreeCashFlowRatio', description: 'Цена/свободный денежный поток' },
  { id: 6, name: 'dividendYield', description: 'Дивидентная доходность' },
  { id: 7, name: 'enterpriseValueEbitda', description: 'Стоимость компании/полученная прибыль до уплаты налогов' },
  { id: 8, name: 'debtToEquityRatio', description: 'Финансовый рычаг' },
  { id: 9, name: 'estimateEarningsCurrentYear', description: 'Прогнозируемый доход за текущий год' },
  { id: 10, name: 'fiveYearTotalRevenue', description: 'Доход за последние 5 лет' }
]

export const industryRows = [
  { id: 1, name: 'industryForwardPE', description: 'Средний прогноз будущей прибыли для отрасли' },
  { id: 2, name: 'industryPriceToBookRatio', description: 'Среднее значение цены/балансная стоимость для отрасли' },
  { id: 3, name: 'industryPriceToSalesRatio', description: 'Среднее значение для цены/объем продаж для отрасли' },
  { id: 4, name: 'industryPriceToFreeCashFlowRatio', description: 'Среднее значение для цены/свободного денежного потока для отрасли' },
  { id: 5, name: 'industryDividendYield', description: 'Средняя дивидентная доходность для отрасли' },
  { id: 6, name: 'industryEnterpriseValueEbitda', description: 'Среднее значение для стоимости компании/полученной прибыли до уплаты налогов для отрасли' },
  { id: 7, name: 'industryDebtToEquityRatio', description: 'Средний финансовый рычаг для отрасли' },
  { id: 8, name: 'industryEstimateEarningsCurrentYear', description: 'Средний прогнозируемый доход за текущий год для отрасли' },
  { id: 9, name: 'industryFiveYearTotalRevenue', description: 'Средний доход за последние 5 лет для отрасли' }
]

export default columns
