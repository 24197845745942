import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  ListItem,
  Checkbox,
  Typography,
  ListItemText
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const actionsPropTypes = PropTypes.shape({
  create: PropTypes.shape({
    description: PropTypes.string,
    enabled: PropTypes.bool
  }),
  delete: PropTypes.shape({
    description: PropTypes.string,
    enabled: PropTypes.bool
  }),
  manage: PropTypes.shape({
    description: PropTypes.string,
    enabled: PropTypes.bool
  }),
  read: PropTypes.shape({
    description: PropTypes.string,
    enabled: PropTypes.bool
  }),
  update: PropTypes.shape({
    description: PropTypes.string,
    enabled: PropTypes.bool
  })
})

const propTypes = {
  subjectNumber: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  parentDescription: PropTypes.string,
  margin: PropTypes.string.isRequired,
  manage: PropTypes.bool,
  canUpdate: PropTypes.bool.isRequired,
  actions: actionsPropTypes.isRequired,
  parentActions: actionsPropTypes,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      subject: PropTypes.string,
      actions: actionsPropTypes
    })
  ),
  setFieldValue: PropTypes.func.isRequired
}

const defaultProps = {
  parentDescription: '',
  manage: null,
  parentActions: {}
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: '0 10px 0 0'
  }
}))

const PermissionsCard = ({
  subjectNumber,
  description,
  parentDescription,
  actions,
  parentActions,
  permissions,
  margin,
  manage,
  canUpdate,
  setFieldValue
}) => {
  const classes = useStyles()

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='284px'
      margin={margin}
      padding='10px'
      border='1px solid #000000'
      borderRadius='5px'
      borderColor='primary.main'
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
      >
        <Typography variant='body1'>{description}</Typography>
        {
          parentDescription ? (
            <Box color='green'>
              <Typography variant='body2'>{`(${parentDescription})`}</Typography>
            </Box>
          ) : null
        }
      </Box>
      {
        Object.keys(actions).map((actionName, i) => {
          const action = actions[actionName]
          const { description: actionDescription, enabled } = action
          /* Не доступен ли чекбокс для изменения */
          let disabledCheckbox = !canUpdate
          /* Не доступен ли чекбокс визуально, принадлежность к родительской сущности */
          let disabledCheckboxSubject = null

          /* Если manage=true у родительской сущности */
          if (manage && !disabledCheckbox) {
            disabledCheckbox = false
            disabledCheckboxSubject = false
          }

          /* Если есть родительская сущность и manage у неё false */
          if (parentDescription && !manage) {
            /* Сохраням объект с данными о родительском действии для действия дочерней сущности */
            const parentActionObj = parentActions[actionName]

            /* Если есть объект с данными о родительском действии */
            if (parentActionObj !== undefined) {
              /* Сохраняем доступно ли данное действие у родителя */
              const parentActionEnabled = parentActions[actionName].enabled
              /* Если нет прав на редактирование или у аналогичного действия
              родительской сущности делаем чекбокс не доступным */
              disabledCheckbox = disabledCheckbox || !parentActionEnabled
              /* Если нет прав у аналогичного действия родительской сущности
              делаем чекбокс не доступным визуально */
              disabledCheckboxSubject = !parentActionEnabled
            }
          }

          const onCheckboxClick = () => {
            const copyPermissions = JSON.parse(JSON.stringify(permissions))

            copyPermissions[subjectNumber].actions[actionName].enabled = !enabled

            setFieldValue('newPermissions', copyPermissions)
          }

          return (
            <ListItem
              dense
              button
              disabled={disabledCheckbox}
              key={`${i}${actionDescription}`}
              onClick={onCheckboxClick}
            >
              <Checkbox
                edge='start'
                color='primary'
                disabled={disabledCheckboxSubject}
                className={classes.checkbox}
                checked={action.enabled}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={action.description} />
            </ListItem>
          )
        })
      }
    </Box>
  )
}

PermissionsCard.propTypes = propTypes
PermissionsCard.defaultProps = defaultProps

export default PermissionsCard
