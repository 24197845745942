import useUserRole from '../hooks/useUserRole'

export default function canAccess (subject, action) {
  const { userRole } = useUserRole()

  if (userRole) {
    return Boolean(userRole.permissions.find(permission => (
      permission.subject === subject && permission.action === action
    )))
  }
  return false
}
