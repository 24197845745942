export default (str, strLength = 120, additionalStr = '') => {
  const receivedStringLength = str.length
  let finalStr = ''

  if (receivedStringLength > strLength) {
    finalStr = str.substring(0, strLength - additionalStr.length)
    return `${finalStr}${additionalStr}`
  }

  return str
}
