import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

const TableHead = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(70px, 1fr)) 60px;
  align-items: end;
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
`

export const ListOfCompanies = ({ children }) => {
  return (
    <>
      <TableHead>
        <Typography variant='h6' component='span'>Тикер</Typography>
        <Typography variant='h6' component='span'>Код биржи</Typography>
        <Typography variant='h6' component='span'>Страна биржи</Typography>
        <Typography variant='h6' component='span'>Список стран</Typography>
      </TableHead>
      {children}
    </>
  )
}
