import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  makeStyles,
  TableCell,
  TableRow,
  Box
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  iconButton: {
    marginRight: '5px'
  },
  th: {
    fontSize: '16px',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  }
}))
const ItemWrapper = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  margin-left: -1px;
  cursor: ${props => props.isSortable ? 'pointer' : 'default'}
`
const Icon = styled.img` 
  height: 10px;  
`

const propTypes = {
  onSort: PropTypes.func.isRequired
}

const tableHeaderArray = [
  { cellName: 'secondName', title: 'Имя пользователя', isSortable: true },
  { cellName: 'phone', title: 'Телефон', isSortable: true },
  { cellName: 'email', title: 'Почта', isSortable: true },
  { cellName: 'role', title: 'Уровень доступа', isSortable: true },
  { cellName: 'subscription', title: 'Статус подписки', isSortable: true },
  { cellName: 'actions', title: 'Действия', isSortable: false }
]

const UsersTableHead = ({ onSort }) => {
  const classes = useStyles()
  const [sortColumn, setSortColumn] = useState({})

  const sortingHandler = (cellName) => {
    if (onSort) {
      const columnName = sortColumn.name
      const sortDirection = sortColumn.direction

      if (cellName !== columnName) {
        onSort(cellName, 'lowToHigh')
        setSortColumn({ name: cellName, direction: 'lowToHigh' })
      }
      if (cellName === columnName && sortDirection === 'lowToHigh') {
        setSortColumn({ ...sortColumn, direction: 'highToLow' })
        onSort(columnName, 'highToLow')
      }
      if (cellName === columnName && sortDirection === 'highToLow') {
        setSortColumn({ ...sortColumn, direction: 'lowToHigh' })
        onSort(columnName, 'lowToHigh')
      }
    }
  }

  return (
    <TableRow>
      {tableHeaderArray.map(item => (
        <TableCell
          className={classes.th}
          key={item.cellName}
          onClick={() => sortingHandler(item.cellName)}
        >
          <ItemWrapper isSortable={item.isSortable}>
            <p> {item.title} </p>
            <Box ml={0.5}>
              {
                item.isSortable && (
                  <Icon src={`/icons/${item.isSortable && sortColumn.name === item.cellName
                    ? sortColumn.direction
                    : 'filterArrowsIcon'}.svg`}
                  />
                )
              }
            </Box>
          </ItemWrapper>
        </TableCell>
      ))}
    </TableRow>
  )
}

UsersTableHead.propTypes = propTypes

export default UsersTableHead
