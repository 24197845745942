import React, { useState, useEffect, useContext } from 'react'
import axios from '../helpers/axios'
import { Box, TextField } from '@material-ui/core'
import { Alert, Autocomplete, Pagination } from '@material-ui/lab'
import styled from 'styled-components'

// blocks
import IndustryCard from '../blocks/IndustryCard'

// components
import PageTitle from '../components/PageTitle'
import Preloader from '../components/Preloader'

// hooks
import useMessage from '../hooks/useMessage'
import useUserRole from '../hooks/useUserRole'
import canAccess from '../helpers/canAccess'

const AutocompleteStyled = styled(Autocomplete)`
  width: 590px;
  margin: 0;
`

const AlertStyled = styled(Alert)`
  width: 590px;
`

const AlertNotFoundStyled = styled(Alert)`
  width: 590px;
  align-self: flex-start;
`

const pageItems = 10
let receivedIndustries

const Industry = () => {
  const update = canAccess('industry', 'updateIndustryName')

  const [industries, setIndustries] = useState()
  const [page, setPage] = useState(1)
  const showMessage = useMessage()
  const paginationCount = industries ? Math.ceil(industries.length / pageItems) : null
  const firstItemNumber = pageItems * page - pageItems
  const lastItemNumber = pageItems * page
  const industriesLength = industries?.length

  const setIndustry = (receivedIndustry) => {
    const newIndustries = JSON.parse(JSON.stringify(industries))
    const { id: receivedIndustryId } = receivedIndustry

    const editedIndustries = newIndustries.map((industry) => {
      const { id } = industry

      if (receivedIndustryId === id) {
        return receivedIndustry
      } else {
        return industry
      }
    })

    receivedIndustries = receivedIndustries.map((industry) => {
      const { id } = industry

      if (receivedIndustryId === id) {
        return receivedIndustry
      } else {
        return industry
      }
    })

    setIndustries(editedIndustries)
  }

  const handleChangePage = (event, value) => {
    setPage(value)
  }

  useEffect(async () => {
    try {
      const { data } = await axios.get('/api/industry')

      receivedIndustries = data
      setIndustries(data)
    } catch (e) {
      console.error(e)
      receivedIndustries = null
      setIndustries(null)
      showMessage('Ошибка загрузки отраслей')
    }
  }, [])

  useEffect(async () => {
    if (industriesLength) {
      setPage(1)
    }
  }, [industriesLength])

  return (
    <>
      <PageTitle>Отрасли</PageTitle>
      {receivedIndustries === undefined ? <Box position='relative' paddingTop='40px'><Preloader /></Box> : null}
      {receivedIndustries === null ? <AlertStyled severity='error'>Ошибка загрузки отраслей</AlertStyled> : null}
      {receivedIndustries && receivedIndustries.length === 0 ? <AlertStyled severity='warning'>Список отраслей пуст</AlertStyled> : null}
      {
        receivedIndustries && receivedIndustries.length ? (
          <Box>
            <Box
              marginBottom='20px'
            >
              <AutocompleteStyled
                key='IndustryAutocomplete'
                freeSolo
                disableClearable
                options={receivedIndustries}
                disableListWrap
                disablePortal
                filterSelectedOptions
                getOptionLabel={(option) => option.name}
                renderOption={(option) => <div option={option} />}
                filterOptions={(options, state) => {
                  const { inputValue } = state
                  const filtredOptions = []

                  options.forEach((option) => {
                    const { name, nameTranslation } = option

                    if (
                      (name.toLowerCase().indexOf(inputValue) !== -1) ||
                      (nameTranslation !== undefined && nameTranslation.toLowerCase().indexOf(inputValue) !== -1)
                    ) {
                      filtredOptions.push(option)
                    }
                  })

                  if (filtredOptions.length === 0 && industries.length !== 0) {
                    setIndustries(filtredOptions)
                  }

                  return filtredOptions
                }}
                ListboxComponent={(params) => {
                  if (industries?.length !== params.children.length && params.children.length !== 0) {
                    const selectedIndustries = params.children.map((industry) => {
                      return industry?.props?.children?.props?.option
                    })

                    setIndustries(selectedIndustries)
                  }

                  return <div />
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Поиск отрасли'
                    margin='normal'
                    variant='outlined'
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                  />
                )}
              />
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              {
                industriesLength ? (
                  <>
                    <Box marginBottom='20px' width='100%'>
                      {
                        industries.map((industry, i) => {
                          const { id } = industry

                          if (i >= firstItemNumber && i < lastItemNumber) {
                            return (
                              <IndustryCard
                                key={`${i}${industriesLength}${id}`}
                                {...industry} last={i + 1 === lastItemNumber || industriesLength - 1 === i}
                                setIndustry={setIndustry}
                                canUpdate={update}
                              />
                            )
                          } else {
                            return null
                          }
                        })
                      }
                    </Box>
                    <Pagination count={paginationCount} page={page} onChange={handleChangePage} />
                  </>
                ) : null
              }
              {!industries ? <Box position='relative' paddingTop='40px'><Preloader /></Box> : null}
              {industries && industries.length === 0 ? <AlertNotFoundStyled severity='warning'>Отрасль не найдена</AlertNotFoundStyled> : null}
            </Box>
          </Box>
        ) : null
      }
    </>
  )
}

export default Industry
