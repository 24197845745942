import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'

const propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  activeFilters: PropTypes.object
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: '1px solid rgb(63, 81, 181)',
    padding: '15px',
    borderRadius: '4px'
  },
  title: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(1)
  },
  checkBox: {
    padding: '5px'
  }
}))

const ActiveFilterPresentation = ({ title, id, activeFilters }) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      control={
        <Checkbox
          color='primary'
          className={classes.checkBox}
          checked={activeFilters[id] || false}
          disabled
        />
      }
      label={title}
    />
  )
}

ActiveFilterPresentation.propTypes = propTypes

export default ActiveFilterPresentation
