import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  makeStyles,
  Box,
  Grid,
  TextField,
  InputLabel,
  Button
} from '@material-ui/core'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import axios from '../../helpers/axios'
import useMessage from '../../hooks/useMessage'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 40px;
  margin-top: 20px;
`

const useStyles = makeStyles(theme => ({
  inputHolder: {
    marginTop: theme.spacing(1.5)
  },
  textInput: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  textLabel: {
    color: '#000',
    fontWeight: '700'
  }
}))

const Company = () => {
  const {
    params: { id }
  } = useRouteMatch({
    path: '/companies/:id'
  })

  const classes = useStyles()
  const showMessage = useMessage()
  const history = useHistory()

  const [currentCompany, setCurrentCompany] = useState('')
  const [companyDesc, setCompanyDesc] = useState('')

  useEffect(() => {
    axios.get(`api/companies/${id}`).then(company => {
      setCurrentCompany(company.data)
      setCompanyDesc(company.data.description)
    })
  }, [])

  async function updateCompanyDesc (id, desc) {
    try {
      await axios.put(`api/companies/${id}`, {
        description: desc
      })
      await showMessage('Описание обновлено', 'success')
      history.push('/companies')
    } catch (e) {
      console.error(e)
      await showMessage('Не удалось обновить описание')
    }
  }

  return (
    <>
      <BreadcrumbsComponent
        forceMatched={[
          {
            name: 'Компании',
            href: '/companies'
          },
          {
            name: `Компания ${currentCompany.name}`
          }
        ]}
      />
      <PageHeader>
        <PageTitle>Компания {currentCompany.name}</PageTitle>
      </PageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.inputHolder}>
            <InputLabel htmlFor='desc' className={classes.textLabel}>Описание компании</InputLabel>
            <TextField
              id='desc'
              className={classes.textInput}
              variant='outlined'
              margin='normal'
              label='Описание компании'
              name='desc'
              multiline
              rows={10}
              rowsMax={50}
              value={companyDesc}
              onChange={e => setCompanyDesc(e.target.value)}
            />
            <Box style={{ margin: '30px 0' }}>
              <Button
                variant='contained'
                color='primary'
                type='button'
                disabled={!companyDesc || companyDesc.length === 0}
                onClick={() => updateCompanyDesc(currentCompany.id, companyDesc)}
              >
                Сохранить
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
)
}

export default Company
