import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { makeStyles, Button, Grid, Typography, Box } from '@material-ui/core'
import { CloudBlue } from '../../../constants/colors'
import useUserRole from '../../../hooks/useUserRole'
import canAccess from '../../../helpers/canAccess'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    borderBottom: '1px solid lightgray',
    marginBottom: theme.spacing(2),
    padding: '0 10px 15px 10px;'
  },
  header: {
    fontWeight: 300,
    marginBottom: '10px',
    color: 'gray'
  },
  buttonHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  description: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}))
const SaveButtonWrap = styled(motion.div)`
  min-width: 150px;
  display: ${props => props.isShow ? 'block' : 'none'};
  color: ${CloudBlue};
`

const CompaniesListItem = ({ name, description, id, onEdit }) => {
  const editCompany = canAccess('company', 'update')

  const [isButtonShow, setIsButtonShow] = useState(false)
  const classes = useStyles()

  return (
    <Grid
      container
      className={classes.container}
      onMouseOver={() => setIsButtonShow(true)}
      onMouseLeave={() => setIsButtonShow(false)}
    >
      <Grid item xs={3}>
        <Typography className={classes.header}> Название </Typography>
        <Typography> {name} </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography className={classes.header}> Описание </Typography>
        <Box className={classes.description}>
          {description}
        </Box>
      </Grid>
      <Grid item xs={2} className={classes.buttonHolder}>
        <SaveButtonWrap
          initial='hidden'
          animate={isButtonShow ? 'visible' : 'hidden'}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -40 }
          }}
          transition={{ ease: 'easeIn', duration: 0.2 }}
          isShow={isButtonShow}
        >
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={() => onEdit(id)}
            disabled={!editCompany}
          >
            Редактировать
          </Button>
        </SaveButtonWrap>
      </Grid>
    </Grid>
  )
}

export default CompaniesListItem
