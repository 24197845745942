import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TextField, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { compile } from 'mathjs'
import useMessage from '../../../../hooks/useMessage'
import Preloader from '../../../Preloader'

const propTypes = {
  xFormula: PropTypes.string.isRequired,
  setXFormula: PropTypes.func.isRequired,
  recalculateXFormula: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired
}

const XFormulaWrapper = styled.div`
  margin: 10px 0 20px;
`
const ButtonWrapper = styled.div`
  margin: 20px 0 0;
  display: flex;
  justify-content: flex-end;
`
const PreloaderWrapper = styled.div`
  position: relative;
  width: 100px;
  margin: 10px;
  padding: 10px;
`
const RatingInfo = styled(Alert)`
  max-width: 400px;
  font-size: 16px;
  border-radius: 15px;
`

function checkFormula (formula) {
  try {
    compile(formula)
    return true
  } catch (e) {
    return false
  }
}

const xRating = ({ xFormula, setXFormula, recalculateXFormula, companyId }) => {
  const showMessage = useMessage()

  const [newRating, setNewRating] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [calculatingRating, setCalculatingRating] = useState(false)

  const isFormulaValid = checkFormula(xFormula)

  useEffect(() => {
    const calculateRating = async () => {
      setCalculatingRating(true)
      try {
        const response = await recalculateXFormula(companyId, xFormula)
        setNewRating(response.rating)
      } catch (e) {
        setErrorMessage(e.response?.data?.message || 'Неизвестная ошибка')
      }
      setCalculatingRating(false)
    }

    calculateRating()
  }, [])

  return (
    <XFormulaWrapper>
      <TextField
        placeholder='X-Formula'
        fullWidth
        multiline
        error={!isFormulaValid}
        value={xFormula}
        onChange={e => setXFormula(e.target.value)}
      />
      <ButtonWrapper>
        <Button
          variant='contained'
          color='primary'
          disabled={!xFormula || !isFormulaValid}
          onClick={async () => {
            setCalculatingRating(true)
            try {
              const response = await recalculateXFormula(companyId, xFormula)
              if (response.rating !== null) {
                setNewRating(response.rating)
              } else {
                showMessage('Невозможно рассчитать рейтинг')
              }
            } catch (e) {
              showMessage('Невозможно рассчитать рейтинг')
              console.error(e)
            }
            setCalculatingRating(false)
          }}
        >
          Пересчитать
        </Button>
      </ButtonWrapper>
      <div>
        {newRating && <RatingInfo severity='info' icon={false}> Предполагаемый рейтинг: {calculatingRating ? 'Загрузка' : newRating}</RatingInfo>}
        {!newRating && !calculatingRating && !errorMessage && <RatingInfo severity='error'> Нет данных о рейтинге </RatingInfo>}
        {!newRating && calculatingRating && !errorMessage && <PreloaderWrapper> <Preloader /> </PreloaderWrapper>}
        {!newRating && !calculatingRating && errorMessage && <RatingInfo severity='error'>{errorMessage}</RatingInfo>}
      </div>
    </XFormulaWrapper>
  )
}

xRating.propTypes = propTypes

export default React.memo(xRating)
