import React, { useEffect, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { makeStyles, Box, Grid, TextField, Button, MenuItem, InputLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import NumberFormat from 'react-number-format'
import { useFormik } from 'formik'
import { useDebounce } from 'use-debounce'
import { zonedTimeToUtc } from 'date-fns-tz'
import DateFnsUtils from '@date-io/date-fns'
import ruLocale from 'date-fns/locale/ru'
import useSWR from 'swr'
import useMessage from '../../hooks/useMessage'
import { emailValid } from '../../helpers/regexp'
import axios from '../../helpers/axios'
import PageTitle from '../../components/PageTitle'
import BreadcrumbsComponent from '../../components/Breadcrumbs'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`
const useStyles = makeStyles(theme => ({
  textInput: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  inputHolder: {
    marginTop: theme.spacing(1)
  }
}))

async function getSearchResults (query) {
  if (!query) return []
  try {
    const { data: cities } = await axios.get(`/api/city?city=${query}`)
    return cities
  } catch (error) {
    return []
  }
}

const User = () => {
  const {
    params: { id }
  } = useRouteMatch({
    path: '/users/:id'
  })
  const showMessage = useMessage()
  const history = useHistory()
  const classes = useStyles()
  const isAdding = id === 'add'

  const [roles, setRoles] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [debouncedSearch] = useDebounce(searchValue, 500)

  const { data: suggestions } = useSWR(() =>
    debouncedSearch !== undefined && searchValue === debouncedSearch
      ? ['/cities/get', debouncedSearch]
      : null, () => getSearchResults(debouncedSearch))

  useEffect(() => {
    if (suggestions?.length === 1) {
      formik.setFieldValue('city', suggestions.join())
    } else {
      formik.setFieldValue('city', searchValue)
    }
  }, [suggestions?.length])

  const initialValues = {
    firstName: '',
    secondName: '',
    email: '',
    phone: '',
    role: ''
  }

  const formik = useFormik({
    initialValues,
    validate (values) {
      const { firstName, email, phone } = values
      const errors = {}

      if (!firstName || firstName.length === 0) {
        errors.firstName = true
      }

      if (!secondName || secondName.length === 0) {
        errors.secondName = true
      }

      if (!phone || phone.length < 11) {
        errors.phone = true
      }

      if (!email || email.length === 0) {
        errors.email = true
      }
      return errors
    },
    async onSubmit (values) {
      const {
        firstName,
        middleName,
        secondName,
        username,
        city,
        birthday,
        email,
        phone,
        role
      } = values

      if (isAdding) {
        try {
          await axios.post('/api/users/cms', {
            firstName,
            middleName,
            secondName,
            username,
            city,
            birthday: birthday || selectedDate ? zonedTimeToUtc(selectedDate, 'Europe/Moscow').toISOString() : '',
            email,
            phone,
            role
          })
          showMessage('Пользователь успешно создан', 'success')
          history.push('/users')
        } catch (e) {
          if (e.response.data.message.includes('duplicate key error collection')) {
            showMessage('Такой email или телефон уже используется')
          } else {
            showMessage('Ошибка сети')
          }
          console.error(e)
        }
      } else {
        try {
          await axios.put(`/api/users/cms/${id}`, {
            firstName,
            middleName,
            secondName,
            username,
            city,
            birthday: birthday || selectedDate ? zonedTimeToUtc(selectedDate, 'Europe/Moscow').toISOString() : '',
            email,
            phone,
            role
          })
          showMessage('Данные пользователя обновлены', 'success')
          history.push('/users')
        } catch (e) {
          if (e.response.data.message.includes('duplicate key error collection')) {
            showMessage('Такой email или телефон уже используется')
          } else {
            showMessage('Ошибка сети')
          }
          console.error(e)
        }
      }
    }
  })

  const {
    values,
    handleSubmit,
    setValues,
    errors: {
      firstName: firstNameErr,
      secondName: secondNameErr,
      email: emailErr,
      phone: phoneErr
    },
    touched: {
      firstName: firstNameTouched,
      secondName: secondNameTouched,
      email: emailTouched,
      phone: phoneTouched
    }
  } = formik

  const {
    firstName,
    secondName,
    birthday,
    email,
    phone
  } = values

  function emailValidCheck () {
    return !(formik.values?.email && !emailValid.test(formik.values?.email))
  }

  useEffect(() => {
    if (birthday) {
      setSelectedDate(birthday)
    }
  }, [birthday])

  useEffect(async () => {
    if (isAdding) {
      setValues(initialValues)
    }
    if (!isAdding) {
      try {
        const { data } = await axios.get(`/api/users/cms/${id}`)
        setValues({
          ...data,
          role: data.role?.id,
          phone: data.phone?.value || '',
          email: data.email?.value || ''
        })
        if (data?.city) {
          setSearchValue(data?.city)
        }
      } catch (e) {
        console.error(e)
        showMessage('Ошибка загрузки данных пользователя')
      }
    }
  }, [id])

  useEffect(async () => {
    const { data: roles } = await axios.get('api/roles')
    setRoles(roles)
  }, [])

  return (
    <>
      <BreadcrumbsComponent
        forceMatched={[
          {
            name: 'Все пользователи',
            href: '/users'
          },
          {
            name: `${firstName} ${secondName}`
          }
        ]}
      />
      <PageHeader>
        <PageTitle>{isAdding ? 'Создание пользователя' : 'Редактирование данных пользователя'}</PageTitle>
      </PageHeader>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={4}>
            <Box className={classes.inputHolder}>
              <InputLabel shrink id='firstName'> Имя </InputLabel>
              <TextField
                id='firstName'
                className={classes.textInput}
                variant='outlined'
                margin='normal'
                name='firstName'
                placeholder='Имя'
                required
                value={formik.values.firstName}
                onBlur={formik.handleBlur}
                onChange={e => formik.setFieldValue('firstName', e.target.value)}
                error={(firstNameErr && firstNameTouched) || firstNameErr}
                inputProps={{ 'aria-label': 'Имя' }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.inputHolder}>
              <InputLabel shrink id='secondName'> Фамилия </InputLabel>
              <TextField
                id='secondName'
                className={classes.textInput}
                variant='outlined'
                margin='normal'
                name='secondName'
                placeholder='Фамилия'
                required
                value={formik.values.secondName}
                onBlur={formik.handleBlur}
                onChange={e => formik.setFieldValue('secondName', e.target.value)}
                error={(secondNameErr && secondNameTouched) || secondNameErr}
                inputProps={{ 'aria-label': 'Фамилия' }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.inputHolder}>
              <InputLabel shrink id='middleName'> Отчество </InputLabel>
              <TextField
                id='middleName'
                className={classes.textInput}
                variant='outlined'
                margin='normal'
                name='middleName'
                placeholder='Отчество'
                value={formik.values.middleName}
                onBlur={formik.handleBlur}
                onChange={e => formik.setFieldValue('middleName', e.target.value)}
                inputProps={{ 'aria-label': 'Отчество' }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.inputHolder}>
              <InputLabel shrink id='email'> Email </InputLabel>
              <TextField
                id='email'
                className={classes.textInput}
                variant='outlined'
                margin='normal'
                name='email'
                placeholder='example@example.com'
                required
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={e => formik.setFieldValue('email', e.target.value)}
                error={(emailErr && emailTouched) || emailErr || !emailValidCheck()}
                inputProps={{ 'aria-label': 'Email' }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.inputHolder}>
              <InputLabel shrink id='phone'> Телефон </InputLabel>
              <NumberFormat
                value={formik.values?.phone}
                onValueChange={(values) => formik.setFieldValue('phone', values.value)}
                name='phone'
                type='text'
                placeholder='+7 (___) ___-__-__'
                format='+# (###) ###-##-##'
                customInput={TextField}
                id='phone'
                className={classes.textInput}
                variant='outlined'
                margin='normal'
                required
                error={(phoneErr && phoneTouched) || phoneErr}
                inputProps={{ 'aria-label': 'Телефон' }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.inputHolder}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <KeyboardDatePicker
                  inputVariant='outlined'
                  margin='dense'
                  format='dd.MM.yyyy'
                  id='date-picker-inline'
                  name='birthday'
                  label='Дата рождения'
                  value={selectedDate}
                  onChange={setSelectedDate}
                  onBlur={formik.handleBlur}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.inputHolder}>
              <InputLabel shrink id='username'> Никнейм </InputLabel>
              <TextField
                id='username'
                className={classes.textInput}
                variant='outlined'
                margin='normal'
                name='username'
                placeholder='Никнейм'
                value={formik.values.username}
                onBlur={formik.handleBlur}
                onChange={e => formik.setFieldValue('username', e.target.value)}
                inputProps={{ 'aria-label': 'Никнейм' }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.inputHolder}>
              <InputLabel shrink id='city'> Город </InputLabel>
              <Autocomplete
                id='combo-box'
                freeSolo
                inputValue={searchValue}
                onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
                options={suggestions || []}
                getOptionLabel={(option) => option}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    placeholder='Город'
                    variant='outlined'
                    id='city'
                    className={classes.textInput}
                    margin='normal'
                  />}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <InputLabel shrink id='role'> Уровень доступа</InputLabel>
            <TextField
              select
              id='role'
              className={classes.textInput}
              variant='outlined'
              name='role'
              value={formik.values.role}
              onChange={e => formik.setFieldValue('role', e.target.value)}
            >
              {roles.map(role => <MenuItem key={role.id} value={role.id}> {role.description} </MenuItem>)}
            </TextField>
          </Grid>
        </Grid>
        <Grid container>
          <Box style={{ margin: '30px 0' }}>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              disabled={!firstName || !phone || !email || phone.length < 11 || !emailValidCheck()}
            >
              {isAdding ? 'Создать' : 'Сохранить'}
            </Button>
          </Box>
        </Grid>
      </form>
    </>
  )
}

export default User
