import React, { useEffect, useState } from 'react'
import { produce } from 'immer'
import { Box, Button, IconButton, TextField } from '@material-ui/core'
import { Add, Cancel } from '@material-ui/icons'
import styled from 'styled-components'
import axios from '../../helpers/axios'

const ItemContainer = styled(Box)`
  border: 1px solid grey;
  border-radius: 7px;
  padding: 20px;
  margin-top: 20px;
`
const AvatarBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
  min-width: 200px;
`
const AvatarImage = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid lightgray;
`

const DeleteFeature = styled.div`
  font-size: 18px;
  color: red;
  line-height: 20px;
  width: fit-content;
  margin-bottom: 10px;
  &:hover{
    cursor: pointer;
    background-color: #ccc;
  }
`

export const TariffFeaturesListItem = ({
  indexInList,
  featureIcon,
  featureName,
  setData,
  featurePoints = [],
  deleteFeature
}) => {
  const [icon, setIcon] = useState(featureIcon || '')
  const [name, setName] = useState(featureName || '')
  const [points, setPoints] = useState(featurePoints || [])

  useEffect(() => {
    setData(updateFeaturesList)
  }, [icon, name, points])

  function updateFeaturesList (initialGlobalList) {
    return produce(initialGlobalList, draft => {
      draft[indexInList] = { icon, name, data: points }
    })
  }

  async function changeIcon (event) {
    const uploadIcon = new FormData()
    uploadIcon.append('files', event.currentTarget.files[0])
    try {
      const { data: upload } = await axios.post('/api/files', uploadIcon, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      setIcon(upload[0].url)
    } catch (e) {
    }
  }
  function changeTitle (event) {
    setName(event.target?.value)
  }
  function changeFeatureListItemValue (index, value) {
    const newList = produce(points, draft => {
      draft[index] = value
    })
    setPoints(newList)
  }
  function deleteFeatureListItemValue (index) {
    const newList = points.filter((item, currentIndex) => currentIndex !== index)
    setPoints(newList)
  }

  return (
    <ItemContainer>
      <DeleteFeature onClick={() => deleteFeature(indexInList)}>Удалить пункт</DeleteFeature>
      <Box display='flex' alignItems='flex-end'>
        <AvatarBlock>
          <AvatarImage
            src={icon}
            alt=''
          />
          <input
            accept='image/*'
            id={`raised-button-file-${indexInList}`}
            name='files'
            multiple
            type='file'
            onChange={changeIcon}
            hidden
          />
          <label htmlFor={`raised-button-file-${indexInList}`}>
            <Button variant='contained' size='small' component='span'>
              Загрузить иконку
            </Button>
          </label>
        </AvatarBlock>
        <TextField
          required
          style={{ width: '100%', margin: 0 }}
          variant='outlined'
          margin='normal'
          name='name'
          label='Заголовок'
          value={name}
          onChange={changeTitle}
        />
      </Box>
      <Box display='flex' flexDirection='column'>
        {points.map((element, index) =>
          <Box display='flex' alignItems='center'>
            <TextField
              required
              id={element}
              label={`подпункт №${index + 1}`}
              variant='outlined'
              size='small'
              style={{ width: '100%', marginTop: 10 }}
              value={points[index]}
              onChange={e => changeFeatureListItemValue(index, e.target?.value)}
            />
            <IconButton
              role='delete-button'
              onClick={() => deleteFeatureListItemValue(index)}
            >
              <Cancel />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box display='flex' justifyContent='center'>
        <Button
          variant='outlined'
          style={{ marginTop: 10 }}
          startIcon={<Add />}
          onClick={() => setPoints(previous => [...previous, ''])}
        >
          добавить подпункт
        </Button>
      </Box>
    </ItemContainer>
  )
}
