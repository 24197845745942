import React from 'react'
import styled from 'styled-components'

const ListElement = styled.div`
  margin-bottom: 5px;
`

export const CountriesList = ({ list = [] }) => {
  return (
    <div>
      {list.map(country =>
        <ListElement key={country?.id}>&#8226; {country?.translatedName}</ListElement>)}
    </div>
  )
}
