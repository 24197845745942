import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import axios from '../../helpers/axios'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

// components
import Forecast from './Forecast'
import useMessage from '../../hooks/useMessage'

const propTypes = {
  name: PropTypes.string.isRequired,
  forecasts: PropTypes.object.isRequired,
  companyForecasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      publishedAt: PropTypes.string
    })
  ).isRequired,
  setForecasts: PropTypes.func.isRequired
}

const AccordionDetailsStyled = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 0;
`

const CompanyForecasts = ({
  name,
  forecasts,
  companyForecasts,
  setForecasts
}) => {
  const showMessage = useMessage()
  const history = useHistory()

  const onEdit = (id) => {
    const handleEdit = async () => {
      history.push(`/forecasts/${id}`)
    }

    return handleEdit
  }

  const onDeleteForecast = (id) => {
    const handleDeleteForecast = async () => {
      try {
        await axios.delete(`/api/forecasts/${id}`)

        const forecastsCopy = JSON.parse(JSON.stringify(forecasts))

        const companyForecasts = forecasts[name]

        const newCompanyForecasts = companyForecasts.filter(forecast => forecast.id !== id)

        if (newCompanyForecasts.length) {
          forecastsCopy[name] = newCompanyForecasts
        } else {
          delete forecastsCopy[name]
        }

        setForecasts(forecastsCopy)

        showMessage('Прогноз удален', 'success')
      } catch (e) {
        console.error(e)
        showMessage('Ошибка удаления прогноза')
      }
    }

    return handleDeleteForecast
  }

  return (
    <Accordion elevation={2}>
      <AccordionSummary expandIcon={<ExpandMore />} data-testid='companyForecast'>
        {name}
      </AccordionSummary>
      <Divider />
      <AccordionDetailsStyled>
        {
          companyForecasts.map((companyForecast, i) => {
            const { id } = companyForecast

            return (
              <Forecast
                key={id}
                date={companyForecast.publishedAt}
                last={forecasts.length - 1 === i}
                onEdit={onEdit(id)}
                onDelete={onDeleteForecast(id)}
              />
            )
          })
        }
      </AccordionDetailsStyled>
      <Divider />
    </Accordion>
  )
}

CompanyForecasts.propTypes = propTypes

export default CompanyForecasts
