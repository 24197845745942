import React from 'react'
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { DeleteForever, Edit } from '@material-ui/icons'

import { CountriesList } from './CountriesList'

const TableRow = styled.div`
  display: grid;
  margin-top: 20px;
  row-gap: 2px;
  grid-template-columns: repeat(4, minmax(70px, 1fr)) 60px;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
`
const EditButton = styled(IconButton)`
  margin-right: 10px;
`

export const CompanyListElement = ({ providerExchangeCode, exchangeCountry, ticker, countries, onDelete, onEdit }) => {
  return (
    <TableRow>
      <Typography>{ticker}</Typography>
      <Typography>{providerExchangeCode}</Typography>
      <Typography>{exchangeCountry?.translatedName}</Typography>
      <CountriesList list={countries} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title='Редактировать' placement='top'>
          <EditButton size='small' color='primary' onClick={onEdit}>
            <Edit />
          </EditButton>
        </Tooltip>
        <Tooltip title='Удалить' placement='top'>
          <IconButton size='small' color='secondary' onClick={onDelete}>
            <DeleteForever />
          </IconButton>
        </Tooltip>
      </Box>
    </TableRow>
  )
}



