import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { format } from 'date-fns'
import styled from 'styled-components'

const TableRowStyled = styled(TableRow)`
${props => props.hoverable &&
        'cursor: pointer; &:hover {box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);}'}
`

function formatDate (date) {
  return format(new Date(date), 'dd.MM.yyyy HH:mm')
}

export const SyncDataTableRow = ({ data, onHandleClick, hoverable }) => {
  const { id, status, duration, syncStartDate, syncEndDate } = data
  const syncStatus = status === 'success' ? 'Завершена' : 'Ошибка'

  return (
    <TableRowStyled hoverable={hoverable} onClick={onHandleClick}>
      <TableCell>{id}</TableCell>
      <TableCell style={{ color: status === 'success' ? 'green' : 'red' }}>
        {syncStatus}
      </TableCell>
      <TableCell>{data?.failReason || '-'}</TableCell>
      <TableCell>{duration} сек. </TableCell>
      <TableCell>{syncStartDate && formatDate(syncStartDate)}</TableCell>
      <TableCell>{syncEndDate && formatDate(syncEndDate)}</TableCell>
    </TableRowStyled>
  )
}
