import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from '../../helpers/axios'
import {
  Box,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Button
} from '@material-ui/core'
import { Pagination, Autocomplete, Alert } from '@material-ui/lab'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import useSWR from 'swr'

import PageTitle from '../../components/PageTitle'
import useMessage from '../../hooks/useMessage'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import CompaniesListItem from '../../components/Company/CompaniesListItem'
import Preloader from '../../components/Preloader'
import { ModalForCompanyException } from '../../components/CompanyException/ModalForCompanyException'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 40px;
`
const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`
const PreloaderSearch = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
`
const AlertStyled = styled(Alert)`
  width: 590px;
`

async function getSearchResults (query, setIsFetching, withoutDescription) {
  if (!query) return []
  setIsFetching(true)

  try {
    const { data: companies } = withoutDescription
      ? await axios.get(`/api/screener/companies/search?query=${query}&description=null`)
      : await axios.get(`/api/screener/companies/search?query=${query}`)
    setIsFetching(false)
    return companies.length > 0 ? companies : null
  } catch (e) {
    console.error(e)
    setIsFetching(false)
    return []
  }
}

const Companies = () => {
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch] = useDebounce(searchValue, 500)
  const [isFetching, setIsFetching] = useState(false)
  const [withoutDescription, setWithoutDescription] = useState(false)

  const [companies, setCompanies] = useState([])
  const [companiesCount, setCompaniesCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const paginationCount = Math.ceil(companiesCount / 10)

  const [modalDialog, setModalDialog] = useState(false)

  const showMessage = useMessage()
  const history = useHistory()

  const { data: suggestions } = useSWR(() =>
    debouncedSearch !== undefined && searchValue === debouncedSearch
      ? ['/companies/get', debouncedSearch]
      : null, () => getSearchResults(debouncedSearch, setIsFetching, withoutDescription))

  useEffect(() => {
    try {
      axios.get(`api/companies?${withoutDescription ? 'description=null' : ''}`).then(companies => setCompanies(companies.data))
      axios.get(`api/companies/count?${withoutDescription ? 'description=null' : ''}`).then(count => setCompaniesCount(count.data))
    } catch (error) {
      console.error(error)
      showMessage('Ошибка загрузки компаний')
    }
  }, [debouncedSearch.length === 0, withoutDescription])

  useEffect(() => {
    if (suggestions?.length) {
      setCompanies(suggestions)
      setCompaniesCount(suggestions.length)
    } else {
      setCompanies(companies)
    }
  }, [suggestions?.length])

  const onEdit = async id => {
    history.push(`/companies/${id}`)
  }

  const changePage = async (newPage) => {
    const resultPage = (page) => {
      if (page === 1) {
        return ''
      } else {
        return ((page + '0') - 10)
      }
    }
    try {
      const { data: newCompanyList } = withoutDescription
        ? await axios.get(`/api/companies?skip=${resultPage(newPage)}&description=null`)
        : await axios.get(`/api/companies?skip=${resultPage(newPage)}`)
      setCompanies(newCompanyList)
      setCurrentPage(newPage)
    } catch (e) {
      console.error(e)
      showMessage('Ошибка отправки данных')
    }
  }

  function changeWithoutDescriptionStatus (e) {
    setWithoutDescription(e.target.checked)
  }

  return (
    <>
      <BreadcrumbsComponent/>
      <PageHeader>
        <PageTitle>Компании</PageTitle>
        <Box position="relative" variant="contained">
          {isFetching ? <PreloaderSearch size={30}/> : null}
          <Box display="flex" alignItems="center">
            <Button
              style={{ marginRight: 30 }}
              color="primary"
              variant="contained"
              onClick={() => setModalDialog(status => !status)}
            >
              Исключения компаний
            </Button>
            <ModalForCompanyException isOpen={modalDialog} closeModal={() => setModalDialog(false)}/>
            <FormControlLabel
              control={
                <Checkbox
                  checked={withoutDescription}
                  onChange={changeWithoutDescriptionStatus}
                  name="checked"
                  color="primary"
                />
              }
              label="Без описания"
            />
            <Autocomplete
              id="combo-box"
              freeSolo
              inputValue={searchValue}
              onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
              options={suggestions || []}
              getOptionLabel={(option) => option?.name}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Поиск компании" variant="outlined"/>}
            />
          </Box>
        </Box>
      </PageHeader>
      {companies === undefined ? <Box position="relative" paddingTop="40px"><Preloader/></Box> : null}
      {suggestions === null
        ? <AlertStyled severity="warning">По заданным критериям ничего не найдено</AlertStyled>
        : (
          <>
            <Box>
              {companies.map(company => <CompaniesListItem key={company.id} {...company} onEdit={onEdit}/>)}
            </Box>
            {companiesCount > 10
              ? <PaginationWrap>
                <Pagination
                  count={paginationCount}
                  page={currentPage}
                  onChange={(e, value) => changePage(value)}
                />
              </PaginationWrap>
              : ''}
          </>)}
    </>
  )
}

export default Companies
