import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

// blocks
import AddFilter from '../AddFilter'

// components
import FilterPresentation from '../../components/FilterPresentation'

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  exchangeCodes: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.string
  }),
  country: PropTypes.shape({
    translatedName: PropTypes.string,
    id: PropTypes.string
  }),
  indexes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      id: PropTypes.string
    })
  ),
  roles: PropTypes.arrayOf(PropTypes.string),
  allRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string
    })
  ),
  onDeleteFilter: PropTypes.func,
  onChangeFilter: PropTypes.func,
  setConfirmDialog: PropTypes.func
}

const defaultProps = {
  exchangeCodes: [],
  disabled: false,
  index: {},
  country: {},
  indexes: null,
  roles: null,
  allRoles: null,
  onDeleteFilter: null,
  onChangeFilter: null
}

const AccordionDetailsStyled = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
`

const Filter = ({
  id,
  name,
  exchangeCodes,
  index,
  country,
  indexes,
  roles,
  allRoles,
  onDeleteFilter,
  onChangeFilter,
  setConfirmDialog
}) => {
  const [editing, enableEditing] = useState(false)

  const handleEnableEditing = () => {
    enableEditing(true)
  }

  const onDisableEditing = () => {
    enableEditing(false)
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} data-testid='filter'>
        <Typography> {name} </Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetailsStyled style={{ paddingTop: editing ? '16px' : '8px' }}>
        {
          editing
            ? (
              <AddFilter
                key={Math.random()}
                editing
                id={id}
                name={name}
                exchangeCodes={exchangeCodes}
                index={index}
                country={country}
                indexes={indexes}
                roles={roles}
                allRoles={allRoles}
                onDisableEditing={onDisableEditing}
                onChangeFilter={onChangeFilter}
              />
              )
            : (
              <FilterPresentation
                exchangeCodes={exchangeCodes}
                index={index}
                country={country}
                roles={roles}
                allRoles={allRoles}
              />
              )
        }
      </AccordionDetailsStyled>
      <Divider />
      {
        onDeleteFilter || onChangeFilter
          ? (
            <AccordionActions>
              {
                editing
                  ? <Button size='small' variant='outlined' onClick={onDisableEditing}>Отмена</Button>
                  : (
                    <>
                      {
                        onDeleteFilter
                          ? (
                            <Button
                              size='small'
                              color='secondary'
                              variant='contained'
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: 'Вы уверены что хотите удалить этот фильтр?',
                                  confirmButtonText: 'Да, удалить',
                                  cancelButtonText: 'Отменить',
                                  cancelButtonColor: '#f44336',
                                  confirmButtonColor: '#1976d2',
                                  onConfirm: () => onDeleteFilter()
                                })
                              }}
                            >
                              Удалить
                            </Button>
                            )
                          : null
                      }
                      {
                        onChangeFilter
                          ? <Button size='small' color='primary' variant='contained' onClick={handleEnableEditing}>Редактировать</Button>
                          : null
                      }
                    </>
                    )
              }
            </AccordionActions>
            )
          : null
      }
    </Accordion>
  )
}

Filter.propTypes = propTypes
Filter.defaultProps = defaultProps

export default Filter
