import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table, TableBody, TableHead, TableContainer } from '@material-ui/core'
import UsersTableHead from '../UsersTableHead'
import UserTableItem from '../UserTableItem'
import Preloader from '../../Preloader'

const propTypes = {
  users: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setConfirmDialog: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired
}

const PreloaderWrapper = styled.div`
  position: relative;
  margin: 150px auto;
  padding: 40px 0;
`

const UsersTable = ({
  users,
  onEdit,
  onDelete,
  onSort,
  setConfirmDialog
}) => {
  return (
    <>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <UsersTableHead onSort={onSort} />
          </TableHead>
          <TableBody>
            {users.map(user =>
              <UserTableItem
                user={user}
                key={user?.id}
                setConfirmDialog={setConfirmDialog}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {users.length === 0 ? <PreloaderWrapper> <Preloader /> </PreloaderWrapper> : null}
    </>
  )
}

UsersTable.propTypes = propTypes

export default UsersTable
