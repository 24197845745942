import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withStyles, Grid, Typography, Divider, Accordion, AccordionDetails, CircularProgress } from '@material-ui/core'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { ExpandMore } from '@material-ui/icons'
import ExplorerSyncDataTable from '../ExplorerSyncDataTable'
import ExplorerCompanyDataTable from '../ExplorerCompanyDataTable'
import ExplorerAccordionItem from '../ExplorerAccordionItem'
import { useAuthSWR } from '../../../../helpers/axios'

const propTypes = {
  company: PropTypes.object.isRequired,
  syncsData: PropTypes.array.isRequired
}

const StyledAccordion = styled(Accordion)`
  border: 1px solid lightgray;
  border-top: 5px solid #3F51B5;
  margin-bottom: 30px;
`
const CompanyWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Header = styled(Typography)`
  font-size: 17px;
`
const CompanyLogo = styled.img`
  margin-left: 10px;
  width: 30px;
`
const StyledDivider = styled(Divider)`
  background: #3F51B5;
  height: 2px;
  margin: 0 auto 30px;
  width: 98%;
`
const AccordionSummary = withStyles({
  content: {
    justifyContent: 'space-between'
  }
})(MuiAccordionSummary)

const ExplorerInfoCompaniesItem = ({ company, syncsData }) => {
  const { data: place } = useAuthSWR(company ? `api/companies/cms/rating-place?ticker=${company.ticker}` : null)

  return (
    <StyledAccordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Header> {company?.ticker} </Header>
        <CompanyWrapper>
          <Header>  {company?.name} </Header>
          <CompanyLogo src={company?.logoUrl} />
        </CompanyWrapper>
        <Header> {company?.rating} </Header>
        <Header>
          {place
            ? `Предполагаемое место: ${place}`
            : <CircularProgress size={25} />}
        </Header>
      </AccordionSummary>
      <StyledDivider />
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ExplorerAccordionItem headerText='Данные о компании'>
              <ExplorerCompanyDataTable company={company} key={company} />
            </ExplorerAccordionItem>
          </Grid>
          <Grid item xs={12}>
            <ExplorerAccordionItem headerText='Сырые данные о компании'>
              <ExplorerSyncDataTable syncsData={syncsData} key={syncsData} />
            </ExplorerAccordionItem>
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  )
}

ExplorerInfoCompaniesItem.propTypes = propTypes

export default ExplorerInfoCompaniesItem
