import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Button, FormControlLabel, Switch } from '@material-ui/core'
import { exportComponentAsJPEG, exportComponentAsPNG } from 'react-component-export-image'
import CaptionValue from './CaptionValue'
import { TangerineOrange, BloodyRed, EmeraldGreen, CloudBlue, Orange, GoldenYellow } from '../../constants/colors'

const Middle = styled.div`
  position: relative;
  height: 24px;
`

const Line = styled.div`
  position: absolute;
  height: 2px;
  width: 730px;
  top: 11px;
  background-color: ${TangerineOrange};
`

const LineSegmentation = styled.div`
  height: 24px;
  width: 2px;
  left: ${props => props.left};
  position: absolute;
  background-color: ${props => props.green ? EmeraldGreen : BloodyRed};
  z-index: 2;
`

const Point = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  top: 7px;
  left: 462px;
  border-radius: 50%;
  background-color: ${TangerineOrange};
`

const Triangle = styled.div`
  width: 25px;
  position: relative;
  top: -5px;
  border-bottom: 45px solid ${TangerineOrange};
  border-left: 12.5px solid transparent;
  border-right: 12.5px solid transparent;
`

const ButtonStyled = styled(Button)`
  margin-bottom: 10px;
`

const FormControlLabelStyled = styled(FormControlLabel)`
  margin-bottom: 5px;
`

const Potential = () => {
  const [isBackground, setIsBackground] = useState(true)
  const componentRef = React.createRef()

  const toggleBackground = () => {
    setIsBackground(!isBackground)
  }

  const onSave = (type = 'jpeg') => {
    const handleSave = () => type === 'jpeg'
      ? exportComponentAsJPEG(componentRef, {
          html2CanvasOptions: {
            backgroundColor: `${isBackground ? '#0C080E' : '#FFFFFF'}`
          }
        })
      : exportComponentAsPNG(componentRef, {
          html2CanvasOptions: {
            backgroundColor: `${isBackground ? '#0C080E' : '#FFFFFF'}`
          }
        })
    return handleSave
  }

  return (
    <Box
      display='flex'
    >
      <Box
        display='flex'
        flexDirection='column'
        width='730px'
        height='198px'
        bgcolor={isBackground ? '#0C080E' : 'unset'}
        ref={componentRef}
      >
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems='flex-end'
          height='64px'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='flex-end'
            flex='1 1 100%'
            marginLeft='207px'
          >
            <CaptionValue
              caption={{
                value: 'MIN',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '18px',
                color: BloodyRed
              }}
              text={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                color: CloudBlue
              }}
            />
            <CaptionValue
              caption={{
                value: 'Консенсус',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '18px',
                color: CloudBlue
              }}
              text={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                color: CloudBlue
              }}
            />
            <CaptionValue
              caption={{
                value: 'MAX',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '18px',
                color: EmeraldGreen
              }}
              text={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                color: CloudBlue
              }}
            />
          </Box>
        </Box>
        <Middle>
          <Line />
          <LineSegmentation left='269px' />
          <Point />
          <LineSegmentation left='669px' green />
        </Middle>
        <Box
          display='flex'
          flexDirection='row'
        >
          <Box
            flex='1 0 207px'
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              width='123px'
            >
              <Triangle />
              <CaptionValue
                caption={{
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '22px',
                  color: CloudBlue
                }}
                text={{
                  value: 'Текущая цена',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: CloudBlue
                }}
              />
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='flex-start'
            flex='1 1 100%'
            paddingTop='5px'
          >
            <CaptionValue
              caption={{
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
                color: CloudBlue
              }}
              text={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                color: Orange
              }}
              margin='21px'
            />
            <CaptionValue
              caption={{
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
                color: CloudBlue
              }}
              text={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                color: GoldenYellow
              }}
              margin='21px'
            />
            <CaptionValue
              caption={{
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
                color: CloudBlue
              }}
              text={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                color: EmeraldGreen
              }}
              margin='21px'
            />
          </Box>
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        paddingLeft='10px'
      >
        <FormControlLabelStyled
          control={
            <Switch
              color='primary'
              checked={isBackground}
              onChange={toggleBackground}
            />
          }
          label={isBackground ? 'Убрать фон' : 'Добавить фон'}
        />
        <ButtonStyled
          variant='contained'
          color='primary'
          onClick={onSave()}
        >
          Сохранить картинку в JPEG
        </ButtonStyled>
        <Button
          variant='contained'
          color='primary'
          onClick={onSave('PNG')}
        >
          Сохранить картинку в PNG
        </Button>
      </Box>
    </Box>
  )
}

export default Potential
