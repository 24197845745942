import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from '../../helpers/axios'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import AllCompaniesTable from '../../components/Explorer/screenerStatistic/AllCompaniesTable'
import TabsComponent from '../../components/Explorer/screenerStatistic/TabsComponent'
import CompaniesIndustryTable from '../../components/Explorer/screenerStatistic/CompaniesIndustryTable'
import CompaniesWithoutData from '../../components/Explorer/screenerStatistic/CompaniesWithoutData'
import CompaniesWithoutDataIndustry from '../../components/Explorer/screenerStatistic/CompaniesWithoutDataIndustry'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`

const ScreenerStatistic = () => {
  const [allCompanies, setAllCompanies] = useState()
  const [countries, setCountries] = useState()

  const PageTabsList = [
    { name: 'Отрасли', id: 0, component: <CompaniesIndustryTable /> },
    { name: 'Компании с отсутствующим показателем', id: 1, component: <CompaniesWithoutData /> },
    { name: 'Отрасли с отсутствующим показателем', id: 2, component: <CompaniesWithoutDataIndustry /> }
  ]

  useEffect(() => {
    async function getAllCompanies () {
      const { data } = await axios.get('/api/screener/stats/companies/all')
      setAllCompanies(data)
    }
    getAllCompanies()
  }, [])

  useEffect(() => {
    async function getCountries () {
      const { data } = await axios.get('/api/screener/stats/companies/countries')
      setCountries(data)
    }
    getCountries()
  }, [])

  return (
    <>
      <BreadcrumbsComponent />
      <PageHeader>
        <PageTitle> Cтатистика скринера </PageTitle>
      </PageHeader>
      <AllCompaniesTable allCompanies={allCompanies} countries={countries} />
      <TabsComponent PageTabsList={PageTabsList} />
    </>
  )
}

export default ScreenerStatistic
