import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Paper, Box } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import useSWR from 'swr'

import axios from '../../../../helpers/axios'
import Preloader from '../../../Preloader'
import HorizontalList from '../../../HorizontalList'
import SortButton from '../SortButton'

const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`
const SearchWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

function limitItems (data, sorter) {
  switch (sorter) {
    case 'highToLow':
      return data.sort((a, b) => a.companies - b.companies)
    case 'lowToHigh':
      return data.sort((a, b) => b.companies - a.companies)
    default:
      return data
  }
}

const CompaniesWithoutDataIndustry = () => {
  const [sorter, setSorter] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const resultPage = useMemo(() => (currentPage * 10) - 10, [currentPage])
  const { data: initialNoDataCompaniesIndustry = [] } = useSWR(`/industries?skip=${resultPage}`, () => axios.get(`/api/screener/stats/companies/industries/incomplete-companies?limit=10&skip=${resultPage}`).then(industries => industries.data))
  const { data: noDataCompaniesIndustryCount = 0 } = useSWR('/industries/count', () => axios.get('/api/screener/stats/companies/industries/incomplete-companies/count').then(count => count.data?.count))

  const noDataCompaniesIndustry = useMemo(() => limitItems(initialNoDataCompaniesIndustry, sorter), [initialNoDataCompaniesIndustry, sorter])

  function changePage (newPage) {
    setCurrentPage(newPage)
  }

  function sorterHandler () {
    sorter === '' || sorter === 'highToLow' ? setSorter('lowToHigh') : setSorter('highToLow')
  }
  if (!initialNoDataCompaniesIndustry.length) {
    return <Box position='relative' paddingTop='100px'><Preloader /></Box>
  }

  return (
    <>
      <Paper>
        <Box>
          <SearchWrap>
            <SortButton sorter={sorter} sorterHandler={sorterHandler} />
          </SearchWrap>
          {noDataCompaniesIndustry.map(item =>
            item !== 'companies' && item !== 'rating' && item !== 'overheatingTrendDirection' && item !== 'stockIndex'
              ? <HorizontalList
                  withAnimation
                  nameHeader='Название'
                  textHeader='Количество компаний'
                  subtextHeader='Перевод'
                  key={item.name}
                  name={item.name}
                  text={item.companies}
                  subtext={item.translate || 'Нет'}
                />
              : null
          )}
        </Box>
      </Paper>
      {noDataCompaniesIndustryCount > 10
        ? <PaginationWrap>
            <Pagination
              count={Math.ceil(noDataCompaniesIndustryCount / 10)}
              page={currentPage}
              onChange={(e, value) => changePage(value)}
            />
          </PaginationWrap>
        : ''}
    </>
  )
}

export default CompaniesWithoutDataIndustry
