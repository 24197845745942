import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  makeStyles,
  Box,
  Grid,
  FormControl,
  MenuItem,
  Typography,
  TextField,
  Select as MuiSelect,
  InputLabel,
  CircularProgress,
  Button,
  Link
} from '@material-ui/core'
import { Pagination, Autocomplete, Alert } from '@material-ui/lab'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ruLocale from 'date-fns/locale/ru'
import { format } from 'date-fns'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import { useFormik } from 'formik'
import useSWR from 'swr'
import { useDebounce } from 'use-debounce'

import axios from '../../helpers/axios'
import canAccess from '../../helpers/canAccess'
import useMessage from '../../hooks/useMessage'

import PageTitle from '../../components/PageTitle'
import Preloader from '../../components/Preloader'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import InvestIdeaTable from '../../components/InvestIdeas/InvestIdeasTable'
import Popup from '../../components/Popup'
import ConfirmDialog from '../../components/ConfirmDialog'
import CustomButton from '../../components/Button'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
`
const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`
const useStyles = makeStyles(theme => ({
  preloader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-20px 0 0 -20px'
  },
  dialog: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(12)
  },
  form: {
    '& .MuiFormControl-root': {
      width: '90%',
      margin: theme.spacing(1)
    }
  },
  text: {
    marginTop: theme.spacing(4)
  },
  button: {
    marginLeft: theme.spacing(2)
  },
  buttonWrapper: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  limitedWidth: {
    width: '400px'
  },
  limitedWidthLg: {
    width: '590px'
  },
  defaultPadding: {
    padding: '8px'
  },
  datePickerLabel: {
    marginLeft: theme.spacing(2),
    fontWeight: 600
  }
}))

async function getSearchResults (query, setIsFetching) {
  if (!query) return []
  setIsFetching(true)

  try {
    const { data: ideas } = await axios.get(`/api/invest-ideas/cms/search?query=${query}`)
    setIsFetching(false)
    return ideas.length === 1 ? ideas : null
  } catch (e) {
    setIsFetching(false)
    return []
  }
}

function resultPage (page) {
  if (page === 1) {
    return ''
  } else {
    return ((page + '0') - 10)
  }
}

const InvestIdeas = () => {
  const createIdea = canAccess('invest-idea', 'create')

  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch] = useDebounce(searchValue, 500)
  const [isFetching, setIsFetching] = useState(false)

  const [investIdeas, setInvestIdeas] = useState([])
  const [ideasCount, setIdeasCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [cellForSortAllCompanies, setCellForSortAllCompanies] = useState({ name: '', direction: 'lowToHigh' })
  const paginationCount = Math.ceil(ideasCount / 10)

  const [modalActive, setModalActive] = useState(false)
  const [currentIdea, setCurrentIdea] = useState([])
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false })
  const [historyPrice, setHistoryPrice] = useState('')

  const showMessage = useMessage()
  const history = useHistory()
  const classes = useStyles()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      closeDate: currentIdea.closeDate,
      currentPrice: currentIdea.currentPrice,
      closeMode: 'currentPrice'
    }
  })

  const { data: suggestions } = useSWR(() =>
    debouncedSearch !== undefined && searchValue === debouncedSearch
      ? ['/invest-ideas', debouncedSearch]
      : null, () => getSearchResults(debouncedSearch, setIsFetching))

  useEffect(async () => {
    try {
      axios.get('/api/invest-ideas?sort=-openDate&limit=10').then(ideas => setInvestIdeas(ideas.data))
      axios.get('/api/invest-ideas/count').then(count => setIdeasCount(count.data))
    } catch (error) {
      showMessage('Ошибка загрузки идей')
    }
  }, [debouncedSearch.length === 0])

  useEffect(() => {
    if (suggestions?.length) {
      if (suggestions.length === 1) {
        const id = suggestions[0].id
        axios.get(`api/invest-ideas?company=${id}`).then(count => setIdeasCount(count.data.length))
        axios.get(`api/invest-ideas?company=${id}&sort=-openDate&limit=10&skip=${resultPage(currentPage)}`).then(ideas => setInvestIdeas(ideas.data))
      } else {
        setInvestIdeas(suggestions)
        setIdeasCount(suggestions.length)
      }
    } else {
      setInvestIdeas(investIdeas)
    }
  }, [suggestions?.length])

  useEffect(() => {
    if (currentIdea !== undefined && formik.values.closeDate !== undefined) {
      const historyPriceElement = currentIdea.priceHistory.find(item => {
        const formattedFormik = format(new Date(formik.values.closeDate), 'yyyy-MM-dd HH:mm')
        const formattedItem = format(new Date(item.date), 'yyyy-MM-dd HH:mm')
        return formattedFormik === formattedItem
      })
      if (historyPriceElement !== undefined) {
        setHistoryPrice(historyPriceElement.price)
      } else {
        setHistoryPrice('')
      }
    }
  }, [formik.values.closeDate])

  async function onEdit (id) {
    history.push(`/invest-ideas/${id}`)
  }

  async function onDelete (id) {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })
    try {
      await axios.delete(`/api/invest-ideas/${id}`)
      const filteredIdeas = investIdeas.filter(idea => idea.id !== id)
      setInvestIdeas(filteredIdeas)
      showMessage('Идея успешно удалена', 'success')
    } catch (e) {
      console.error(e)
      showMessage('Ошибка удаления идеи')
    }
  }

  async function openIdea (id) {
    try {
      const { data: updatedIdea } = await axios.put(`/api/invest-ideas/${id}`, {
        ideaStatus: 'open'
      })
      if (updatedIdea) {
        const newState = [...investIdeas].map(item => (item.id === id ? { ...item, ideaStatus: 'open' } : item))
        setInvestIdeas(newState)
        showMessage('Идея успешно открыта', 'success')
      }
    } catch (error) {
      console.error(error)
      showMessage('Ошибка при открытии идеи')
    }
  }

  async function closeIdea () {
    try {
      const { data: updatedIdea } = await axios.put('/api/invest-ideas/close', {
        idea: currentIdea.id,
        closeDate: new Date(formik.values.closeDate) || new Date(),
        priceToCloseWith: formik.values.currentPrice
      })
      if (updatedIdea) {
        setModalActive(false)
        const newState = [...investIdeas].map(item => (item.id === currentIdea.id
          ? { ...item, ideaStatus: 'close', currentPrice: formik.values.currentPrice }
          : item
        ))
        setInvestIdeas(newState)
        showMessage('Идея успешно закрыта', 'success')
      }
    } catch (error) {
      console.error(error)
      showMessage('Ошибка при закрытии идеи')
    }
  }

  async function getIdeaForClosing (id) {
    setModalActive(true)
    try {
      const { data } = await axios.get(`api/invest-ideas/${id}`)
      setCurrentIdea(data)
    } catch (error) {
      console.error(error)
    }
  }

  async function onSortByCell (cellName, direction) {
    setInvestIdeas([])
    if (suggestions.length === 1) {
      const id = suggestions[0].id
      const { data: companyIdeas } = await axios.get(
        `api/invest-ideas?company=${id}&skip=${resultPage(currentPage)}&sort=${direction === 'lowToHigh' ? '' : '-'}${cellName === 'actions' ? '-openDate' : cellName}&limit=10`)
      setCellForSortAllCompanies({ name: cellName, direction: direction })
      setInvestIdeas(companyIdeas)
      const { data: count } = await axios.get(
        `api/invest-ideas?company=${id}&skip=${resultPage(currentPage)}&sort=${direction === 'lowToHigh' ? '' : '-'}${cellName === 'actions' ? '-openDate' : cellName}`)
      setIdeasCount(count.length)
    } else {
      const { data } = await axios.get(
        `/api/invest-ideas?skip=${resultPage(currentPage)}&sort=${direction === 'lowToHigh' ? '' : '-'}${cellName === 'actions' ? '-openDate' : cellName}&limit=10`)
      setCellForSortAllCompanies({ name: cellName, direction: direction })
      setInvestIdeas(data)
    }
  }

  async function changePage (page) {
    setInvestIdeas([])
    const cellNameIfSortActive = () => {
      if (cellForSortAllCompanies.name.length) {
        return `&sort=${cellForSortAllCompanies.direction === 'lowToHigh' ? '' : '-'}${cellForSortAllCompanies.name}`
      } else {
        return ''
      }
    }

    if (suggestions.length === 1) {
      const id = suggestions[0].id
      const { data: suggestionsList } = await axios.get(`api/invest-ideas?sort=-openDate&company=${id}&skip=${resultPage(page)}${cellNameIfSortActive()}`)
      setInvestIdeas(suggestionsList)
      setCurrentPage(page)
    } else {
      try {
        const { data: newIdeasList } = await axios.get(`/api/invest-ideas?sort=-openDate&${page !== 1 ? `skip=${resultPage(page)}` : ''}${cellNameIfSortActive()}&limit=10`)
        setInvestIdeas(newIdeasList)
        setCurrentPage(page)
      } catch (e) {
        console.error(e)
        showMessage('Ошибка отправки данных')
      }
    }
  }

  return (
    <>
      <BreadcrumbsComponent />
      <PageHeader>
        <PageTitle>Все идеи</PageTitle>
        <Box style={{ position: 'relative' }}>
          {isFetching ? <CircularProgress size={30} className={classes.preloader} /> : null}
          <Autocomplete
            id='combo-box'
            freeSolo
            inputValue={searchValue}
            onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
            options={suggestions || []}
            getOptionLabel={(option) => option?.name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label='Поиск идеи по названию/тикеру' variant='outlined' />}
          />
        </Box>
      </PageHeader>
      <Box>
        {investIdeas === undefined ? <Box position='relative' paddingTop='40px'><Preloader /></Box> : null}
        <Box marginTop='10px'>
          {suggestions === null
            ? <Alert severity='warning' className={classes.limitedWidthLg}>По заданным критериям ничего не найдено</Alert>
            : (
              <>
                {createIdea && (
                  <Box className={classes.buttonWrapper}>
                    <Link component={RouterLink} to='invest-ideas/add'>
                      <Button color='primary' variant='contained'>
                        Создать
                      </Button>
                    </Link>
                  </Box>
                )}
                <InvestIdeaTable
                  ideas={investIdeas}
                  onDelete={onDelete}
                  onClose={getIdeaForClosing}
                  onEdit={onEdit}
                  onOpen={openIdea}
                  setConfirmDialog={setConfirmDialog}
                  onSort={(cellName, direction) => onSortByCell(cellName, direction)}
                />
                {createIdea && (
                  <Box className={classes.buttonWrapper}>
                    <Link component={RouterLink} to='invest-ideas/add'>
                      <Button color='primary' variant='contained'>
                        Создать
                      </Button>
                    </Link>
                  </Box>
                )}
                {investIdeas.length > 0 && ideasCount > 10
                  ? <PaginationWrap>
                      <Pagination
                        count={paginationCount}
                        page={currentPage}
                        onChange={(e, value) => changePage(value)}
                      />
                    </PaginationWrap>
                  : ''}
              </>)}
          <Popup
            title='Закрытие идеи'
            openPopup={modalActive}
            setOpenPopup={setModalActive}
          >
            <Box className={classes.form}>
              <Grid container>
                <Grid item xs={6}>
                  <FormControl variatn='outlined'>
                    <InputLabel>Способ закрытия идеи</InputLabel>
                    <MuiSelect
                      name='closeMode'
                      label='Способ закрытия идеи'
                      value={formik.values.closeMode}
                      onChange={e => formik.setFieldValue('closeMode', e.target.value)}
                    >
                      <MenuItem value='currentPrice'>Текущая цена</MenuItem>
                      <MenuItem value='closeWithPrice'>Установленная цена</MenuItem>
                    </MuiSelect>
                  </FormControl>
                  {formik.values.closeMode === 'closeWithPrice' && (
                    <Box className={classes.defaultPadding}>
                      {historyPrice
                        ? <Alert className={classes.limitedWidth} severity='info'>Цена на выбранную дату и время: {historyPrice}</Alert>
                        : <Alert className={classes.limitedWidth} severity='warning'>Отсутствует цена на выбранную дату и время</Alert>}
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {formik.values.closeMode === 'currentPrice' && (
                    <Typography className={classes.text}>
                      {`Текущая цена: ${currentIdea.currentPrice || 'Получаем...'}`}
                    </Typography>
                  )}
                  {formik.values.closeMode === 'closeWithPrice' && (
                    <>
                      <Typography className={classes.datePickerLabel}>Дата закрытия идеи</Typography>
                      <FormControl>
                        <Box className={classes.defaultPadding}>
                          <DatePicker
                            inline
                            locale={ruLocale}
                            name='closeDate'
                            label='Дата закрытия идеи'
                            timeIntervals={1}
                            dropdownMode='select'
                            dateFormat='d MMMM yyyy'
                            showTimeSelect
                            timeFormat='HH:mm'
                            onChange={date => formik.setFieldValue('closeDate', date)}
                            selected={Date.parse(formik.values.closeDate)}
                            required
                            includeDates={
                              typeof currentIdea.priceHistory === 'object' &&
                              currentIdea.priceHistory
                                ? currentIdea.priceHistory.map(day => new Date(day.date))
                                : []
                            }
                            includeTimes={
                              typeof currentIdea.priceHistory === 'object' &&
                              currentIdea.priceHistory
                                ? currentIdea.priceHistory.map(time => new Date(time.date))
                                : []
                            }
                          />
                        </Box>
                      </FormControl>
                      <FormControl>
                        <TextField
                          variant='outlined'
                          placeholder='Цена закрытия'
                          label='Цена закрытия'
                          name='currentPrice'
                          inputProps={{ step: '0.01' }}
                          type='number'
                          required
                          onChange={formik.handleChange}
                          value={formik.values.currentPrice}
                        />
                      </FormControl>
                    </>
                  )}
                </Grid>
              </Grid>
              <CustomButton
                className={classes.button}
                type='button'
                text='Закрыть идею'
                onClick={() => closeIdea()}
              />
            </Box>
          </Popup>
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Box>
      </Box>
    </>
  )
}

export default InvestIdeas
