import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
`
const StyledListItemText = styled(ListItemText)`
  color: rgba(0, 0, 0, 0.54);
`

const propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClick: PropTypes.func
}

const defaultProps = {
  href: '',
  open: false,
  onClick: null
}

const NavigationLink = ({ name, href, icon, open, onClick }) => {
  const ListItemInner = (
    <>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <StyledListItemText>{name}</StyledListItemText>
    </>
  )

  return href ? (
    <StyledLink to={href}>
      <ListItem button onClick={onClick}>
        {ListItemInner}
      </ListItem>
    </StyledLink>
  ) : (
    <ListItem button onClick={onClick}>
      {ListItemInner}
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
  )
}

NavigationLink.propTypes = propTypes
NavigationLink.defaultProps = defaultProps

export default NavigationLink
