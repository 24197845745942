import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

const defaultProps = {
  value: null,
  disabled: false
}

const RadioGroupStyled = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
`

const Label = ({ text, color }) => <span style={{ color }}>{text}</span>

const TypeOfDeal = ({ value, onChange, disabled }) => {
  return (
    <RadioGroupStyled value={value} onChange={onChange}>
      <FormControlLabel
        value='buy'
        control={<Radio disabled={disabled} color='primary' />}
        label={<Label text='Покупка' color='#0EA80A' />}
      />
      <FormControlLabel
        value='sell'
        control={<Radio disabled={disabled} color='primary' />}
        label={<Label text='Продажа' color='#C91B1B' />}
      />
    </RadioGroupStyled>
  )
}

TypeOfDeal.propTypes = propTypes
TypeOfDeal.defaultProps = defaultProps

export default TypeOfDeal
