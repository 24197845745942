import React from 'react'
import styled from 'styled-components'

const InputsBlockWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 10px;
  width: 80%;
  border-bottom: 3px solid lightgray;
  margin-bottom: 20px;
  min-height: 50px;
`
const InputsSection = styled.div`
  width: 50%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const InputsSectionHeader = styled.h3`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  color: gray;
`

export const TranslateTableHead = () => {
  return (
    <InputsBlockWrapper>
      <InputsSection>
        <InputsSectionHeader>Название:</InputsSectionHeader>
      </InputsSection>
      <InputsSection>
        <InputsSectionHeader>Перевод:</InputsSectionHeader>
      </InputsSection>
    </InputsBlockWrapper>
  )
}
