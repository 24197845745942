import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography, Box } from '@material-ui/core'

const propTypes = {
  children: PropTypes.string.isRequired
}

const TypographyStyled = styled(Typography)`
  font-size: 30px;
`

const ParagraphTitle = ({ children }) => {
  return (
    <Box marginBottom='15px' padding='0 24px'>
      <TypographyStyled variant='h3'>
        {children}
      </TypographyStyled>
    </Box>
  )
}

ParagraphTitle.propTypes = propTypes

export default ParagraphTitle
