import React, { useEffect, useMemo, useState } from 'react'
import {
  Backdrop,
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core'
import styled from 'styled-components'
import ReactJson from 'searchable-react-json-view'
import { produce } from 'immer'
import { useDebounce } from 'use-debounce'
import flatten from 'flat'

import Preloader from '../../Preloader'
import { SyncDataTableRow } from './ExplorerSyncDataTable/SyncDataTableRow'
import { SyncDataTableContainer } from './ExplorerSyncDataTable/SyncDataTableContainer'
import { EmeraldGreen } from '../../../constants/colors'
import { Autocomplete } from '@material-ui/lab'

const PreloaderWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  margin-bottom: 50px;
`

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px 40px',
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 6,
  minWidth: 1000,
  height: 700,
  overflow: 'hidden',
  overflowY: 'scroll'
}

function dataTransform (data) {
  const rawCompanyData = data.rawCompanyData
  const originExtendedCompanyData = data.extendedCompanyData
  const extendedCompanyData = produce(originExtendedCompanyData, draft => {
    draft = Object.entries(draft).reduce((acc, [key, value]) =>
      key === 'ema20' || key === 'ema200' || key === 'rsi' || key === 'price'
        ? { ...acc, [key]: value }
        : acc, {})
    return draft
  })
  return { rawCompanyData, extendedCompanyData }
}

export const JSONViewModal = ({ isOpen, closeModal, content, headerData }) => {
  const [searchValue, setSearchValue] = useState('')
  const [chosenSearchValue, setChosenSearchValue] = useState('')
  const [searchMode, setSearchMode] = useState('key')
  const [debouncedSearchValue] = useDebounce(searchValue, 500)

  const searchInputLabel = searchMode === 'key' ? 'Введите название ключа' : 'Введите нужное значение'

  const additionalData = useMemo(() => content ? dataTransform(content) : null, [content])
  const flattedJSONData = useMemo(() => {
    const flattenContent = flatten(content || {})
    return {
      keys: Object.keys(flattenContent).map(key => key.toLowerCase()) || [],
      values: Object.values(flattenContent).map(value => (`${value}`).toLowerCase()) || []
    }
  }, [content])
  const suggestions = useMemo(() => debouncedSearchValue ? getListOfSuggestions(searchMode) : [], [debouncedSearchValue])

  useEffect(() => {
    setChosenSearchValue('')
    setSearchValue('')
  }, [searchMode])

  function suggestionsInKeys (string) {
    return [...flattedJSONData.keys].filter(key => key.includes(string) && key)
  }
  function findInValues (string) {
    const indexesList = flattedJSONData.values.map((value, index) => value.includes(string) ? index : '').filter(String)
    return indexesList.map(key => flattedJSONData.keys[key])
  }
  function getListOfSuggestions (type) {
    switch (type) {
      case 'key':
        return debouncedSearchValue ? suggestionsInKeys(debouncedSearchValue) : []
      case 'value':
        return debouncedSearchValue ? findInValues(debouncedSearchValue) : []
      default:
        return []
    }
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={isOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <Box sx={styles}>
          <SyncDataTableContainer>
            <SyncDataTableRow data={headerData} />
          </SyncDataTableContainer>
          <Box style={{ marginTop: '30px' }}>
            <Box display='flex' style={{ margin: '30px 0' }}>
              <div>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Поиск по:</FormLabel>
                  <RadioGroup
                    row
                    aria-label='Поиск по:'
                    name='controlled-radio-buttons-group'
                    value={searchMode}
                    onChange={(e, value) => setSearchMode(value)}
                  >
                    <FormControlLabel value='key' control={<Radio size='small' color='primary' />} label='ключу' />
                    <FormControlLabel value='value' control={<Radio size='small' color='primary' />} label='значению' />
                  </RadioGroup>
                </FormControl>
              </div>
              <Autocomplete
                disabled={!content}
                filterOptions={x => x}
                style={{ flexGrow: 1, marginBottom: '30px' }}
                inputValue={searchValue}
                onChange={(e, value) => setChosenSearchValue(value?.split('.').pop())}
                onInputChange={(event, newInputValue) => setSearchValue(newInputValue?.toLowerCase())}
                options={suggestions}
                getOptionLabel={option => option.replace(/\./g, ' -> ')}
                renderInput={(params) =>
                  <TextField {...params} label={searchInputLabel} variant='outlined' />}
              />
            </Box>
            {content
              ? <ReactJson
                  style={{ width: '100%' }}
                  highlightSearch={chosenSearchValue}
                  highlightSearchColor={EmeraldGreen}
                  quotesOnKeys={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  collapsed={1}
                  enableClipboard={false}
                  name='data'
                  iconStyle='circle'
                  indentWidth='4'
                  src={additionalData}
                />
              : <PreloaderWrapper> <Preloader /> </PreloaderWrapper>}
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}
