import React, { useRef, useEffect, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import axios from '../../helpers/axios'
import {
  Box,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  InputLabel,
  Grid,
  makeStyles
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { green, grey } from '@material-ui/core/colors'
import { Alert, Autocomplete } from '@material-ui/lab'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { useFormik } from 'formik'
import JoditEditor from 'jodit-react'
import ruLocale from 'date-fns/locale/ru'
import DateFnsUtils from '@date-io/date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

// components
import PageTitle from '../../components/PageTitle'
import SearchResult from '../../components/SearchResult'
import Potential from '../../components/Potential'

// other
import { latinSmallAlphabetHypenAndNumbers } from '../../helpers/regexp'
import useMessage from '../../hooks/useMessage'
import Preloader from '../../components/Preloader'
import BreadcrumbsComponent from '../../components/Breadcrumbs'

const { REACT_APP_API_ENDPOINT } = process.env

const useStyles = makeStyles(theme => ({
  inputHolder: {
    marginTop: theme.spacing(1.5)
  },
  textInput: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  textLabel: {
    color: '#000',
    fontWeight: '700'
  }
}))
const GreenSwitch = withStyles({
  switchBase: {
    color: grey[600],
    '&$checked': {
      color: green[500]
    },
    '& + $track': {
      backgroundColor: grey[500]
    },
    '&$checked + $track': {
      backgroundColor: green[200]
    }
  },
  checked: {},
  track: {}
})(Switch)

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  
  .jodit-toolbar-button_font {
    display: none;
  }
`
const AutocompleteStyled = styled(Autocomplete)`
  width: 590px;
  margin: 0;
`
const AlertStyled = styled(Alert)`
  width: 590px;
  margin-bottom: 20px;
`
const TextFieldStyled = styled(TextField)`
  width: 590px;
`
const TextFieldMarginStyled = styled(TextFieldStyled)`
  flex: 0 0 590px;
  margin-right: 20px;
`
const KeyboardDatePickerStyled = styled(KeyboardDatePicker)`
  width: 265px;
`

let showMessageFunc

const getConfig = (token) => {
  return {
    height: 800,
    link: {
      processVideoLink: false
    },
    disablePlugins: ['video', 'copy-format', 'file'],
    readonly: false,
    uploader: {
      url: `${REACT_APP_API_ENDPOINT}/api/files`,
      method: 'POST',
      insertImageAsBase64URI: false,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
      data: null,
      headers: {
        Authorization: `Bearer ${token || window.localStorage.getItem('token')}`
      },
      filesVariableName: () => 'files',
      format: 'json',
      prepareData: data => data,
      isSuccess: resp => resp,
      process: resp => resp,
      defaultHandlerSuccess: function (data) {
        const joditObj = this.jodit || this

        if (data && data.length) {
          for (let i = 0; i < data.length; i += 1) {
            joditObj.selection.insertImage(data[i].url, null, 300)
          }
        }
      },
      error: () => {
        showMessageFunc('Ошибка загрузки картинок')
      },
      contentType: function (requestData) {
        return this.j.ow.FormData !== undefined && typeof requestData !== 'string'
          ? false
          : 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }
  }
}

const initialValues = {
  company: '',
  content: '',
  status: false,
  publishedAt: utcToZonedTime(new Date(), 'Europe/Moscow'),
  searchData: [],
  isDatePickerOpen: false,
  isUpdatingError: false
}

async function sendCompanyData (id, data) {
  const { companyDesc: description, companyUrl: forecastsURL } = data
  await axios.put(`api/companies/${id}`, {
    description,
    forecastsURL
  })
}

async function getCompanyData (id) {
  const { data } = await axios.get(`api/companies/${id}`)
  return data
}

const Forecast = () => {
  const {
    params: { id }
  } = useRouteMatch({
    path: '/forecasts/:id'
  })
  const editor = useRef(null)
  const classes = useStyles()
  const showMessage = useMessage()
  const isAdding = id === 'add'

  const history = useHistory()

  const [companyDesc, setCompanyDesc] = useState('')
  const [companyUrl, setCompanyUrl] = useState('')
  const [config, setConfig] = useState(getConfig())

  useEffect(() => {
    const interval = setInterval(() => {
      const getNewOptions = async () => {
        try {
          const refreshToken = window.localStorage.getItem('refreshToken')
          const { data } = await axios.post(`${REACT_APP_API_ENDPOINT}/api/auth/refresh`, {
            refreshToken
          })

          const { token, refreshToken: receivedRefreshToken } = data

          window.localStorage.setItem('token', token)
          window.localStorage.setItem('refreshToken', receivedRefreshToken)
          setConfig(getConfig(token))
          return null
        } catch (e) {
          console.error(e)
          window.localStorage.removeItem('token')
          window.localStorage.removeItem('refreshToken')
          window.location.reload()
        }
      }
      getNewOptions()
    }, 270000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validate (values) {
      const { company } = values
      const errors = {}

      if (company.length === 0) {
        errors.company = true
      }

      return errors
    },
    async onSubmit (values) {
      const { company, status, publishedAt, content } = values
      const editedDate = zonedTimeToUtc(publishedAt, 'Europe/Moscow').toISOString()

      if (isAdding) {
        try {
          await axios.post('/api/forecasts', {
            company,
            status: status ? 'published' : 'unpublished',
            publishedAt: editedDate,
            content
          })
          showMessage('Прогноз добавлен', 'success')
          history.push('/forecasts')
        } catch (e) {
          console.error(e)
          showMessage('Ошибка сервера')
        }
      } else {
        try {
          await axios.put(`/api/forecasts/${id}`, {
            company,
            status: status ? 'published' : 'unpublished',
            publishedAt: editedDate,
            content
          })
          showMessage('Прогноз обновлён', 'success')
          history.push('/forecasts')
        } catch (e) {
          console.error(e)
          showMessage('Ошибка сервера')
        }
      }
    }
  })

  const {
    values,
    handleSubmit,
    setFieldValue,
    setValues,
    errors: {
      company: companyErr
    },
    touched: {
      company: companyTouched
    }
  } = formik
  const {
    company,
    status,
    content,
    publishedAt,
    searchData,
    isDatePickerOpen,
    isUpdatingError
  } = values

  const handleDatePick = (date) => {
    setValues({
      ...values,
      publishedAt: date,
      isDatePickerOpen: false
    })
  }

  const onDatePickerOpen = () => {
    setFieldValue('isDatePickerOpen', !isDatePickerOpen)
  }

  useEffect(async () => {
    if (isAdding && company) {
      setValues(initialValues)
    }

    if (!isAdding) {
      try {
        const { data } = await axios.get(`/api/forecasts/${id}`)

        setValues({
          ...data,
          status: data.status === 'published',
          publishedAt: utcToZonedTime(data.publishedAt, 'Europe/Moscow'),
          isDatePickerOpen: false
        })
      } catch (e) {
        console.error(e)
        setFieldValue('isUpdatingError', true)
        showMessage('Ошибка загрузки прогноза')
      }
    }
  }, [id])

  useEffect(async () => {
    showMessageFunc = showMessage
  }, [JSON.stringify(showMessage)])

  useEffect(() => {
    if (company) {
      getCompanyData(company)
        .then(companyObj => {
          setCompanyDesc(companyObj.description)
          setCompanyUrl(companyObj.forecastsURL)
        })
    }
  }, [company])

  return (
    <Wrap>
      <BreadcrumbsComponent
        forceMatched={[
          {
            name: 'Все прогнозы',
            href: '/forecasts'
          },
          {
            name: companyUrl || 'Новый прогноз'
          }
        ]}
      />
      <PageTitle>{isAdding ? 'Создание прогноза' : 'Обновление прогноза'}</PageTitle>
      {!isAdding && !company && !isUpdatingError ? <Box position='relative' padding='40px 0 28px 0'><Preloader /></Box> : null}
      {isUpdatingError ? <AlertStyled severity='error'>Ошибка загрузки прогноза</AlertStyled> : null}
      {
        (!isAdding && company) || isAdding
          ? (
            <form onSubmit={handleSubmit}>
              <Box>
                <Box
                  marginBottom='20px'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  {
                  isAdding
                    ? (
                      <AutocompleteStyled
                        data-testid='autocomplete'
                        freeSolo
                        disableClearable
                        options={searchData}
                        onChange={(event, value, reason) => {
                          if (reason === 'select-option') {
                            const { id: receivedId, forecastsURL } = value

                            setValues({
                              ...values,
                              company: receivedId,
                              url: forecastsURL
                            })
                          }
                        }}
                        onInputChange={
                        async (e) => {
                          const { type, target: { value } } = e

                          if (type === 'change' && value) {
                            try {
                              const { data } = await axios.get(`/api/screener/companies/search/?query=${value}`)

                              formik.setFieldValue('searchData', data)
                            } catch (e) {
                              console.error(e)
                              showMessage('Ошибка поиска', 'error', { preventDuplicate: true })
                              formik.setFieldValue('searchData', [])
                            }
                          }
                        }
                      }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => <SearchResult key={option.name} img={option.logoUrl} name={option.name} ticker={option.ticker} />}
                        filterOptions={(options) => options}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='company'
                            label='Поиск компании'
                            margin='normal'
                            variant='outlined'
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                              onBlur: formik.handleBlur
                            }}
                          />
                        )}
                      />
                      )
                    : null
                }
                  {
                  company
                    ? (
                      <TextFieldStyled
                        variant='outlined'
                        margin='dense'
                        disabled
                        value={company}
                        label='ID компании'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      )
                    : null
                }
                  {companyErr && companyTouched ? <AlertStyled severity='error'>Компания не выбрана</AlertStyled> : null}
                </Box>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  marginBottom='20px'
                >
                  <Box
                    display='flex'
                  >
                    <TextFieldMarginStyled
                      data-testid='companyUrl'
                      name='companyUrl'
                      margin='dense'
                      error={companyUrl?.length === 0 || !latinSmallAlphabetHypenAndNumbers.test(companyUrl)}
                      label={(companyUrl?.length === 0 || !latinSmallAlphabetHypenAndNumbers.test(companyUrl)) ? 'Только английские буквы, цифры и тире' : 'Название страницы компании'}
                      value={companyUrl}
                      onChange={e => setCompanyUrl(e.target.value)}
                    />
                    <Box
                      marginRight='20px'
                      flex='0 0 265px'
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                        <KeyboardDatePickerStyled
                          disableToolbar
                          variant='inline'
                          format='d MMMM yyyy в HH:mm'
                          margin='dense'
                          id='date-picker-inline'
                          name='publishedAt'
                          label='Дата публикации'
                          open={isDatePickerOpen}
                          value={publishedAt}
                          onBlur={formik.handleBlur}
                          onChange={handleDatePick}
                          onOpen={onDatePickerOpen}
                          onClose={onDatePickerOpen}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <FormControlLabel
                      control={
                        <GreenSwitch
                          checked={status}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name='status'
                        />
                    }
                      label={status ? 'Опубликован' : 'Не опубликован'}
                    />
                  </Box>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={!company}
                    onClick={() => sendCompanyData(company, { companyDesc, companyUrl })}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Box>
              <Grid item xs={12}>
                <Box className={classes.inputHolder}>
                  <InputLabel htmlFor='desc' className={classes.textLabel}>Описание компании</InputLabel>
                  <TextField
                    id='desc'
                    className={classes.textInput}
                    variant='outlined'
                    margin='normal'
                    name='desc'
                    label='Описание компании'
                    multiline
                    rows={6}
                    rowsMax={40}
                    value={companyDesc}
                    onChange={e => setCompanyDesc(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.inputHolder}>
                  <InputLabel className={classes.textLabel}>Текст прогноза</InputLabel>
                </Box>
                <Box className={classes.textInput}>
                  <JoditEditor
                    ref={editor}
                    tabIndex={1}
                    config={config}
                    value={content}
                    onChange={(content) => {
                      setFieldValue('content', content)
                    }}
                  />
                </Box>
              </Grid>
            </form>
            )
          : null
      }
      <Box
        marginBottom='20px'
        marginTop='50px'
      >
        <Potential />
      </Box>
    </Wrap>
  )
}

export default Forecast
