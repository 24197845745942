import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Box,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'
import { Edit, DeleteForever } from '@material-ui/icons'
import { format, utcToZonedTime } from 'date-fns-tz'
import ruLocale from 'date-fns/locale/ru'

const propTypes = {
  date: PropTypes.string.isRequired,
  last: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

const EditButton = styled(IconButton)`
  margin-right: 10px;
`

const Forecast = ({
  date,
  last,
  onEdit,
  onDelete
}) => {
  const formatedDate = format(utcToZonedTime(date, 'Europe/Moscow'), 'd MMMM yyyy в HH:mm', {
    locale: ruLocale
  })

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      fontSize='18px'
      lineHeight='22px'
      padding='5px 10px'
      height='auto'
      {... last ? {} : { boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}
    >
      <Typography> Прогноз за {formatedDate}</Typography>
      <Box>
        <Tooltip title='Редактировать' placement='top'>
          <EditButton size='small' color='primary' onClick={onEdit}>
            <Edit />
          </EditButton>
        </Tooltip>
        <Tooltip title='Удалить' placement='top'>
          <IconButton size='small' color='secondary' onClick={onDelete}>
            <DeleteForever />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}

Forecast.propTypes = propTypes

export default Forecast
