import React from 'react'
import styled from 'styled-components'
import {
  TableCell,
  TableRow
} from '@material-ui/core'

const ItemWrapper = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  margin-left: -1px;
 `

const tableHeaderArray = [
  { id: 0, title: 'Название' },
  { id: 2, title: 'Опубликован' },
  { id: 3, title: 'Доступ' },
  { id: 3, title: 'Дата создания' },
  { id: 4, title: 'Действия' }
]

export const EducationsTableHead = () => {
  return (
    <>
      <TableRow>
        {tableHeaderArray.map(item => (
          <TableCell
            align='left'
            key={item.id}
          >
            <ItemWrapper>
              <p> {item.title} </p>
            </ItemWrapper>
          </TableCell>
        ))}
      </TableRow>
    </>
  )
}
