import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import axios, { useAuthSWR } from '../../helpers/axios'
import { Box, TextField, Typography, Button, Chip, FormControlLabel, Checkbox } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useFormik } from 'formik'
import useMessage from '../../hooks/useMessage'
import Preloader from '../../components/Preloader'

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  exchangeCodes: PropTypes.arrayOf(PropTypes.string),
  editing: PropTypes.bool,
  index: PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.string
  }),
  country: PropTypes.shape({
    translatedName: PropTypes.string,
    id: PropTypes.string
  }),
  indexes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      id: PropTypes.string
    })
  ),
  roles: PropTypes.arrayOf(PropTypes.string),
  onAddFilter: PropTypes.func,
  onDisableEditing: PropTypes.func,
  allRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string
    })
  )
}

const defaultProps = {
  id: '',
  name: '',
  exchangeCodes: [],
  index: {},
  country: {},
  indexes: null,
  editing: false,
  allRoles: null,
  onAddFilter: null
}

const TypographyStyled = styled(Typography)`
  margin-bottom: 10px;
`
const TextFieldStyled = styled(TextField)`
  width: 500px;
  &.MuiFormControl-marginDense {
    margin: 0;
  }
`
const ChipStyled = styled(Chip)`
  line-height: normal;
  margin-bottom: 10px;
`
const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: -5px;
`
const CheckboxStyled = styled(Checkbox)`
  padding: 2px;
`

const AddFilter = ({
  id,
  name: receivedName,
  exchangeCodes: receivedExchangeCodes,
  index: receivedIndex,
  country: receivedCountry,
  indexes,
  onAddFilter,
  onDisableEditing,
  onChangeFilter,
  editing,
  roles,
  allRoles
}) => {
  const { data: countries } = useAuthSWR('/api/country')

  const [exchangeCodeValue, setExchangeCodeValue] = useState('')
  const [exchangeCodeFocus, setExchangeCodeFocus] = useState(false)
  const showMessage = useMessage()
  const textFieldErr = 'Название должно содержать не менее двух символов'
  const isRolesAndEditing = editing && allRoles && allRoles.length && roles
  const isRolesAndAdding = !editing && allRoles && allRoles.length

  const initialValues = {
    name: receivedName || '',
    exchangeCodes: receivedExchangeCodes || [],
    index: Object.keys(receivedIndex).length ? receivedIndex : {},
    country: receivedCountry
  }

  /* Если передан массив всех ролей и выбранных ролей,
  * в начальные данные добавляем свойства для чекбоксов,
  * выставляя true тем, которые выбраны. Если это добавление,
  * выставляем всем чекбоксам false */
  if (isRolesAndEditing || isRolesAndAdding) {
    allRoles.forEach((role) => {
      const { id, name } = role

      initialValues[name] = isRolesAndEditing ? roles.includes(id) : false
    })
  }

  const formik = useFormik({
    initialValues,
    validate (values) {
      const { name, exchangeCodes } = values
      const { touched: { exchangeCodes: exchangeCodesTouched } } = formik
      const errors = {}

      if (name.length < 2) {
        errors.name = textFieldErr
      }

      if (exchangeCodes.length === 0 && exchangeCodesTouched) {
        errors.exchangeCodes = true
      }

      return errors
    },
    async onSubmit (values) {
      const { name, exchangeCodes, index, country } = values
      const { id: indexId } = index

      try {
        const dataObj = {
          displayName: name, // Обязательный параметр
          exchangeCodes: exchangeCodes, // Массив кодов, обязательный параметр
          ...(indexId ? { stockIndex: indexId } : { stockIndex: null }), // id сущности stock-index. Необязательный параметр
          ...(country?.id || country?._id ? { country: country.id || country?.id } : { country: null }) // id страны. Необязательный параметр
        }

        /* Если у фильтра есть роли, добавляем выбранные в объект фильтра */
        if (allRoles) {
          const newRoles = []

          allRoles.forEach((role) => {
            const { id: roleId, name: roleName } = role

            if (values[roleName]) {
              newRoles.push(roleId)
            }
          })

          dataObj.roles = newRoles
        }

        if (editing) {
          const { data } = await axios.put(`/api/filters/${id}`, dataObj)

          if (data) {
            onChangeFilter(data)
            onDisableEditing()
            showMessage('Фильтр успешно изменен', 'success')
          }
        } else {
          const { data } = await axios.post('/api/filters', dataObj)
          if (data) {
            onAddFilter(data)
            showMessage('Фильтр успешно создан', 'success')
          }
        }
      } catch (e) {
        if (editing) {
          showMessage('Ошибка изменения фильтра')
        } else {
          showMessage('Ошибка добавления фильтра')
        }
      }
    }
  })

  const {
    setFieldTouched,
    setFieldValue,
    validateForm,
    values,
    errors: {
      name: nameErr,
      exchangeCodes: exchangeCodesError
    },
    touched: {
      name: nameTouched,
      exchangeCodes: exchangeCodesTouched
    }
  } = formik

  const {
    name,
    exchangeCodes,
    index,
    country
  } = values

  const { code } = index

  /* Добавить биржу */
  const addExchangeCode = () => {
    const newExchangeCodes = exchangeCodes.concat()

    /* Проверяем, добавлен ли уже такой код */
    if (!newExchangeCodes.includes(exchangeCodeValue)) {
      newExchangeCodes.push(exchangeCodeValue)

      setFieldValue('exchangeCodes', newExchangeCodes)
      setExchangeCodeValue('')
    } else {
      showMessage('Биржа уже добавлена')
    }
  }

  /* Функции изменения кода */
  const onExchangeCodesFocus = () => {
    setExchangeCodeFocus(true)
  }

  const onExchangeCodesBlur = () => {
    setFieldTouched('exchangeCodes', true)
    setExchangeCodeFocus(false)
  }

  const onExchangeCodesChange = (e) => {
    const { target: { value } } = e

    setExchangeCodeValue(value)
  }

  useEffect(() => {
    if (exchangeCodesTouched) {
      validateForm()
    }
  }, [exchangeCodesTouched])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        width='100%'
        {
          ...(editing
            ? {
                border: 'unset'
              }
            : {
                border: '1px solid #000000',
                borderColor: 'primary.main',
                padding: '15px',
                borderRadius: '4px'
              })
        }
      >
        <TextFieldStyled
          name='name'
          variant='outlined'
          margin='dense'
          value={name}
          error={nameErr && nameTouched}
          label={nameErr && nameTouched ? nameErr : 'Название фильтра'}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          inputProps={{ 'aria-label': 'Название фильтра' }}
        />
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          paddingTop='11px'
        >
          <TypographyStyled variant='h6'>Список бирж:</TypographyStyled>
          <Box marginBottom={exchangeCodes.length ? '10px' : '0'}>
            {
              exchangeCodesError
                ? null
                : (
                    exchangeCodes.map((exchangeCode, i) => {
                      const deleteExchangeCode = () => {
                        const newExchangeCodes = exchangeCodes.filter((ExistingExchangeCode) => ExistingExchangeCode !== exchangeCode)

                        setFieldValue('exchangeCodes', newExchangeCodes)
                      }

                      return (
                        <ChipStyled
                          key={`${i}${exchangeCode}`}
                          label={exchangeCode}
                          color='primary'
                          style={{ marginRight: exchangeCodes.length - 1 === i ? '0' : '10px' }}
                          onDelete={deleteExchangeCode}
                        />
                      )
                    })
                  )
            }
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            marginBottom='6px'
          >
            <Box marginRight='10px'>
              <TextFieldStyled
                variant='outlined'
                margin='dense'
                value={exchangeCodeValue}
                error={exchangeCodeValue.length < 2 && exchangeCodeFocus}
                label={exchangeCodeValue.length < 2 && exchangeCodeFocus ? textFieldErr : 'Название биржи'}
                onChange={onExchangeCodesChange}
                onBlur={onExchangeCodesBlur}
                onFocus={onExchangeCodesFocus}
                inputProps={{ 'aria-label': 'Название биржи' }}
              />
            </Box>
            <Button
              variant='contained'
              color='primary'
              size='small'
              disabled={exchangeCodeValue.length < 2}
              onClick={addExchangeCode}
            >
              Добавить биржу
            </Button>
          </Box>
        </Box>
        {
          exchangeCodesError ? <Typography variant='body1' color='error'>Список бирж пуст</Typography> : null
        }
        {
          !allRoles
            ? (
              <Box
                position='relative'
                height='94px'
                width='500px'
                display='flex'
                flexDirection='row'
              >
                <Preloader />
              </Box>
              )
            : null
        }
        {
          (editing && ((allRoles && allRoles.length === 0) || !roles)) || (!editing && (allRoles && allRoles.length === 0))
            ? (
              <Box color='warning.main'>
                <Typography variant='h6'>Назначение фильтра по ролям недоступно</Typography>
              </Box>
              )
            : null
        }
        {
          isRolesAndEditing || isRolesAndAdding
            ? (
              <Box
                display='flex'
                flexDirection='column'
              >
                <TypographyStyled variant='h6'>Роли, для которых доступен фильтр:</TypographyStyled>
                {
                allRoles.map((role) => {
                  const { id, name, description } = role

                  return (
                    <FormControlLabelStyled
                      key={id}
                      control={
                        <CheckboxStyled
                          name={name}
                          color='primary'
                          checked={values[name]}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      }
                      label={description}
                    />
                  )
                })
              }
              </Box>
              )
            : null
        }
        <Box margin='0 0 15px 0'>
          {
            !indexes && (
              <Box
                position='relative'
                height='94px'
                width='500px'
                display='flex'
                flexDirection='row'
              >
                <Preloader />
              </Box>
            )
          }
          {indexes && indexes.length === 0
            ? (
              <Box color='warning.main'>
                <Typography variant='h6'>Поиск индекса недоступен</Typography>
              </Box>
              )
            : null}
          {indexes && indexes.length
            ? (
              <Box paddingTop='6px'>
                <Box marginBottom='10px'>
                  <Typography variant='h6'>Поиск индекса:</Typography>
                </Box>
                <Autocomplete
                  value={Object.keys(index).length ? { code } : null}
                  options={indexes}
                  onChange={(event, value, reason) => {
                    if (reason === 'select-option') {
                      const { id, code } = value

                      setFieldValue('index', { id, code })
                    }

                    if (reason === 'clear') {
                      setFieldValue('index', {})
                    }
                  }}
                  getOptionLabel={(option) => option.code}
                  getOptionSelected={(option) => option.code}
                  renderOption={(option) => <div>{option.code}</div>}
                  renderInput={(params) => (
                    <Box paddingTop='6px'>
                      <TextFieldStyled
                        {...params}
                        label='Поиск индекса'
                        margin='dense'
                        variant='outlined'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search'
                        }}
                      />
                    </Box>
                  )}
                />
              </Box>
              )
            : null}
        </Box>
        <Box margin='0 0 15px 0'>
          {
            !countries && (
              <Box
                position='relative'
                height='94px'
                width='500px'
                display='flex'
                flexDirection='row'
              >
                <Preloader />
              </Box>
            )
          }
          {countries && countries.length === 0
            ? (
              <Box color='warning.main'>
                <Typography variant='h6'>Поиск страны недоступен</Typography>
              </Box>
              )
            : null}
          {countries && countries.length
            ? (
              <Box paddingTop='6px'>
                <Box marginBottom='10px'>
                  <Typography variant='h6'>Страна:</Typography>
                </Box>
                <Autocomplete
                  value={country && Object.keys(country).length ? { translatedName: country.translatedName || country.name } : null}
                  options={
                    countries
                      .filter((option) => option.translatedName || option.name)
                      .sort((a, b) => (a.translatedName || a.name) < (b.translatedName || b.name) ? -1 : 1)
                  }
                  onChange={(event, value, reason) => {
                    if (reason === 'select-option') {
                      const { id, translatedName } = value

                      setFieldValue('country', { id, translatedName })
                    }

                    if (reason === 'clear') {
                      setFieldValue('country', {})
                    }
                  }}
                  getOptionLabel={(option) => option.translatedName || option.name}
                  getOptionSelected={(option) => option.translatedName || option.name}
                  renderOption={(option) => <div>{option.translatedName || option.name}</div>}
                  renderInput={(params) => (
                    <Box paddingTop='6px'>
                      <TextFieldStyled
                        {...params}
                        label='Поиск страны'
                        margin='dense'
                        variant='outlined'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search'
                        }}
                      />
                    </Box>
                  )}
                />
              </Box>
              )
            : null}
        </Box>
        <Button
          variant='contained'
          color='primary'
          type='submit'
        >
          {editing ? 'Сохранить изменения' : 'Добавить фильтр'}
        </Button>
      </Box>
    </form>
  )
}

AddFilter.propTypes = propTypes
AddFilter.defaultProps = defaultProps

export default AddFilter
