import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, TextField } from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import { useDebounce } from 'use-debounce'
import useSWR from 'swr'

import useMessage from '../../hooks/useMessage'
import axios from '../../helpers/axios'
import { IndustriesListItem } from '../../components/TranslatePages/IndustriesListItem'
import BreadcrumbsComponent from '../../components/Breadcrumbs'
import { TranslateTableHead } from '../../components/TranslatePages/TableHead'

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  margin-bottom: 40px;
`
const PageTitle = styled.h1`
  font-weight: 600;
  font-size: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: grey;
  padding: 20px 0;
`
const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`

async function getSearchResults (query) {
  if (!query) return []

  try {
    const { data: industries } = await axios.get(`/api/industries?name=/${query}/mi`)

    return industries
  } catch (e) {
    return []
  }
}

export const TranslateIndustries = () => {
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch] = useDebounce(searchValue, 500)

  const [industries, setIndustries] = useState([])
  const [industriesCount, setIndustriesCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const showMessage = useMessage()
  const paginationCount = Math.ceil(industriesCount / 10)

  const { data: suggestions } = useSWR(() =>
    debouncedSearch !== undefined && searchValue === debouncedSearch ? ['/industries/get', debouncedSearch] : null, () => getSearchResults(debouncedSearch))

  useEffect(() => {
    try {
      axios.get('/api/industries').then(industriesList => setIndustries(industriesList.data))
      axios.get('/api/industries/count').then(count => setIndustriesCount(count.data))
    } catch (e) {
      console.error(e)
      showMessage('Ошибка загрузки отраслей')
    }
  }, [])

  useEffect(() => {
    try {
      axios.get('/api/industries').then(industriesList => setIndustries(industriesList.data))
    } catch (error) {
      showMessage('Ошибка загрузки отраслей')
    }
  }, [debouncedSearch.length === 0])

  useEffect(() => {
    if (suggestions?.length) {
      setIndustries(suggestions)
    } else {
      setIndustries(industries)
    }
  }, [suggestions?.length])

  const changePage = async (newPage) => {
    const resultPage = (page) => {
      if (page === 1) {
        return ''
      } else {
        return ((page + '0') - 10)
      }
    }
    try {
      const { data: newIndustriesList } = await axios.get(`/api/industries?skip=${resultPage(newPage)}`)
      setIndustries(newIndustriesList)
      setCurrentPage(newPage)
    } catch (e) {
      console.error(e)
      showMessage('Ошибка отправки данных')
    }
  }

  return (
    <>
      <Box>
        <BreadcrumbsComponent />
        <PageHeader>
          <PageTitle>Перевод поля "Отрасль"</PageTitle>
          <Autocomplete
            id='combo-box-demo'
            freeSolo
            inputValue={searchValue}
            onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
            options={suggestions || []}
            getOptionLabel={(option) => option?.name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label='Поиск отрасли' variant='outlined' />}
          />
        </PageHeader>
        <TranslateTableHead />
        {industries.map(industry =>
          <IndustriesListItem key={industry.id} {...industry} />)}
      </Box>
      {!debouncedSearch?.length && industries.length && industriesCount > 10 ?
        <PaginationWrap>
          <Pagination count={paginationCount} page={currentPage} onChange={(e, value) => changePage(value)} />
        </PaginationWrap>
      : ''}
    </>
  )
}
